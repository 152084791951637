<script lang="ts" setup>
import { ref, computed, onMounted } from "vue";
import { onClickOutside } from "@vueuse/core";

import BaseIcon from "@/components/BaseIcon.vue";
import BaseForm from "@/components/BaseForm.vue";
import BookingRentalModal from "./Services/components/BookingRentalModal.vue";

import type { GON } from "@/types";

declare global {
  interface Window {
    gon: GON;
  }
}

type TActionQuote = {
  label: string;
  name: string;
  url: string;
};

interface TCreditNote {
  booking_id: number;
  comment: string;
  created_at: string;
  external_id: string;
  external_reference: string;
  id: number;
  total_amount_refunded: number;
  updated_at: string;
}

type Props = {
  booking: string;
  currentQuote: string;
  currentQuoteInvoice: string;
  currentQuoteInvoiceExternalId: string;
  clientInvoice: string;
  creditNotes: string;
};

const props = withDefaults(defineProps<Props>(), {});

const actions = ref<TActionQuote[]>([]);

const csrfToken = ref<string | undefined | null>(
  document?.querySelector("meta[name='csrf-token']")?.getAttribute("content"),
);

const showRentalModal = ref<boolean>(false);

const bookingParsed = computed(() => JSON.parse(props.booking));

const currentQuoteParsed = computed(() => JSON.parse(props.currentQuote));

const currentQuoteInvoiceParsed = computed(() =>
  JSON.parse(props.currentQuoteInvoice),
);

const currentQuoteInvoiceExternalIdParsed = computed(() =>
  JSON.parse(props?.currentQuoteInvoiceExternalId),
);

const clientInvoiceParsed = computed(() => JSON.parse(props.clientInvoice));
const creditNotesParsed = computed(() => {
  const formattedString = props.creditNotes
    .replace(/=>/g, ":")
    .replace(/\bnil\b/g, "null");
  return JSON.parse(formattedString);
});

const iconName = (action: TActionQuote) =>
  action?.name === "refund_client" ? "refundClient" : "download";

const handleListActions = () => {
  const bookingId = bookingParsed.value.id;
  const currentQuoteId = currentQuoteParsed?.value?.id;
  const currentQuoteInvoiceId = currentQuoteInvoiceParsed?.value?.id;

  const urls = {
    donwloadQuote: `/bookings/${bookingId}/quotes/${currentQuoteId}/pdf_files`,
    generateQuote: `/bookings/${bookingId}/quotes/new`,
    downloadInvoice: `/bookings/${bookingId}/invoices/${currentQuoteInvoiceId}/pdf_files`,
    createInvoice: `/bookings/${bookingId}/invoices`,
  };

  if (currentQuoteParsed.value) {
    actions.value.push({
      label: "Download quote",
      name: "download_quote",
      url: urls.donwloadQuote,
    });

    if (!currentQuoteInvoiceExternalIdParsed.value) {
      actions.value.push({
        label: "Regenerate quote",
        name: "regenerate_quote",
        url: urls.generateQuote,
      });
    }
    if (currentQuoteInvoiceParsed.value) {
      actions.value.push({
        label: "Download invoice",
        name: "download_invoice",
        url: urls.downloadInvoice,
      });
    } else {
      actions.value.push({
        label: "Create invoice",
        name: "create_invoice",
        url: urls.createInvoice,
      });
    }
  } else {
    actions.value.push({
      label: "Generate quote",
      name: "generate_quote",
      url: urls.generateQuote,
    });
  }

  if (creditNotesParsed.value.length > 0) {
    creditNotesParsed.value.forEach((credit: TCreditNote) => {
      const priceFormatted = formatPrice(credit.total_amount_refunded);
      actions.value.push({
        label: `Download credit note (${priceFormatted} €)`,
        name: "credit_notes",
        url: `/bookings/${credit.booking_id}/credit_notes/${credit.id}/pdf_files`,
      });
    });
  }

  if (clientInvoiceParsed?.value?.status === "paid") {
    actions.value.push({
      label: "Refund client",
      name: "refund_client",
      url: "",
    });
  }
};

const formatPrice = (price: number) => price.toFixed(2);

const createInvoice = async (action: TActionQuote) => {
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };

  if (csrfToken.value) {
    headers["X-CSRF-Token"] = csrfToken.value;
  }

  try {
    await fetch(action.url, {
      method: "POST",
      headers,
    });

    const formElement = document.getElementById(
      "postRedirectForm",
    ) as HTMLFormElement | null;

    if (formElement !== null) {
      formElement.action = action.url;
      formElement.submit();
    }

    return formElement;
  } catch (error) {
    console.log("Erreur:", error);
  }
};

const handleActionQuote = async (action: TActionQuote) => {
  if (action.name === "create_invoice") {
    await createInvoice(action);
  } else if (action.name === "refund_client") {
    showRentalModal.value = true;
  } else {
    window.location.href = action.url;
  }

  showMenu.value = false;
};

const menu = ref(null);
const showMenu = ref<boolean>(false);
const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};
onClickOutside(menu, (e) => {
  showMenu.value = false;
});

onMounted(() => {
  handleListActions();
});
</script>

<template>
  <div class="relative">
    <button
      ref="menu"
      type="button"
      class="flex items-center bg-primary-500 text-white p-3 hover:bg-primary-600 duration-100 rounded-md"
      @click="toggleMenu"
    >
      <BaseIcon name="list" />
    </button>

    <div
      v-if="showMenu"
      class="absolute top-full right-0 z-10 mt-2 bg-white shadow-lg rounded-lg border border-gray-200 whitespace-nowrap"
    >
      <div class="flex flex-col">
        <button
          v-for="action in actions"
          :key="action.name"
          type="button"
          class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 rounded shadow w-[100%]"
          @click="() => handleActionQuote(action)"
        >
          <div class="flex items-center">
            <BaseIcon :name="iconName(action)" class-name="w-6 h-4 mr-2" />
            <span>{{ action?.label }}</span>
          </div>

          <BaseForm
            v-if="action?.name === 'create_invoice'"
            id="postRedirectForm"
            :action="action?.url"
            :value="csrfToken"
            method="post"
          />
        </button>
      </div>
    </div>

    <BookingRentalModal v-model="showRentalModal" />
  </div>
</template>
