<script setup>
import Multiselect from "@vueform/multiselect";

defineOptions({
  inheritAttrs: false,
});
defineProps({
  options: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: [Array, Number, String, null],
    required: true,
  },
  model: {
    type: String,
    default: "",
  },
  attribute: {
    type: String,
    default: "",
  },
  index: {
    type: Number,
    default: 0,
  },
  wrapperClass: {
    type: String,
    default: "",
  },
  nestedAttributes: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["update:modelValue", "close", "change"]);

const onChange = (value) => {
  emits("update:modelValue", value);
  emits("change", value);
};

const onClose = () => {
  emits("close");
};
</script>

<template>
  <div :class="wrapperClass">
    <template v-if="Array.isArray(modelValue) && !nestedAttributes">
      <input
        v-for="val in modelValue"
        :key="val"
        class="hidden"
        :value="val"
        :name="`${model}[${attribute}][]`"
        :id="`${model}_${attribute}_${val}`"
      />
    </template>
    <input
      v-else-if="nestedAttributes"
      class="hidden"
      :value="modelValue"
      :name="`${model}[${index}][${attribute}]`"
      :id="`${model}_${attribute}_${index}_${modelValue}`"
    />
    <input
      v-else
      class="hidden"
      :value="modelValue"
      :name="`${model}[${attribute}]`"
      :id="`${model}_${attribute}_${modelValue}`"
    />
    <Multiselect
      :value="modelValue"
      :options="options"
      v-bind="$attrs"
      @change="onChange"
      @clear="onClose"
    >
      <!-- singlelabel -->
      <template #singlelabel="{ value }">
        <div class="multiselect-single-label">
          <span class="multiselect-single-label-text">
            <slot name="multiselect-singlelabel" :value="value">
              {{ value.label }}
            </slot>
          </span>
        </div>
      </template>
      <template #option="{ option }">
        <slot name="multiselect-option" :option="option">
          {{ option.label }}
        </slot>
      </template>
    </Multiselect>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
