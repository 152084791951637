<script setup>
defineOptions({
  inheritAttrs: false,
});
defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  hasFooter: {
    type: Boolean,
    default: false,
  },
  noForm: {
    type: Boolean,
    default: false,
  },
  hasHeader: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
  formAction: {
    type: String,
    default: "",
  },
});
const emits = defineEmits(["close-modal", "update:model-value"]);

const clickOnShadow = () => {
  handleClose();
};

const handleClose = () => {
  emits("close-modal");
  emits("update:model-value", false);
};
</script>

<template>
  <div
    v-if="modelValue"
    class="fixed top-0 left-0 flex items-center justify-center p-6 w-full h-full z-40"
  >
    <div
      class="bg-slate-800 bg-opacity-40 z-10 fixed top-0 left-0 w-full h-full"
      @click="clickOnShadow"
    />

    <div
      :class="[
        'relative border border-gray-200 z-20 bg-white p-8 overflow-auto rounded-lg md:w-[500px] w-full',
        $attrs.class,
      ]"
    >
      <button
        type="button"
        class="w-10 h-10 absolute right-0 top-1"
        @click="handleClose"
      >
        <BaseIcon name="close" />
      </button>
      <div class="modal-content">
        <slot v-if="title || hasHeader" name="header">
          <h2 class="text-xl font-bold mb-4">{{ title }}</h2>
        </slot>
        <slot name="content"></slot>
      </div>

      <footer
        v-if="hasFooter || $slots.footer"
        class="flex justify-end mt-3"
        data-testid="lc-modal__footer"
      >
        <slot name="footer" />
      </footer>
    </div>
  </div>
</template>
