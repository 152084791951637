<script setup lang="ts">
import { ref } from "vue";
import ClientItineraryService from "@/views/Contracts/ClientItinerary/components/ClientItineraryService.vue";
import Catalog from "@/views/Contracts/ClientItinerary/components/Catalog.vue";
import ClientItineraryArrivalDeparture from "@/views/Contracts/ClientItinerary/components/ClientItineraryArrivalDeparture.vue";
import ClientItineraryIncludedService from "@/views/Contracts/ClientItinerary/components/ClientItineraryIncludedService.vue";
import ClientItineraryClientRessources from "@/views/Contracts/ClientItinerary/components/ClientItineraryClientRessources.vue";
import ClientItineraryGuestContacts from "@/views/Contracts/ClientItinerary/components/ClientItineraryGuestContacts.vue";
import NotificationModal from "@/views/Contracts/ClientItinerary/components/NotificationModal.vue";

const contract = ref(window?.gon?.contract);
const clientItinerary = ref(window?.gon?.client_itinerary);
const showModal = ref(false);

const openModal = () => {
  showModal.value = true;
};

const handleCloseModal = () => {
  showModal.value = false;
};

const updateModal = () => {
  clientItinerary.value =  { ...window.gon?.clientItinerary };
   showModal.value = false;
}
</script>

<template>
  <div class="flex justify-end">
    <a v-if="contract?.api_contract_id"
      class="pl-8 pr-4 py-2 cursor-pointer border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 mr-4 relative"
      @click="openModal"
    >
      <BaseIcon class="absolute top-[11px] left-[8px]" name="notification" />
      Notify clients of updates
    </a>
    <a
      class="pl-8 pr-4 py-2 cursor-pointer border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 relative"
      :href="`/contracts/${contract.id}/client_itineraries.pdf`"
      target="_blank"
    >
      <BaseIcon class="absolute top-[11px] left-[8px]" name="download" />
      Export itinerary PDF
    </a>
  </div>
  <ClientItineraryArrivalDeparture />
  <ClientItineraryService />
  <Catalog />
  <ClientItineraryIncludedService />
  <ClientItineraryClientRessources />
  <template v-if="contract?.api_contract_id">
    <ClientItineraryGuestContacts />
  </template>
  <NotificationModal
    v-if="showModal"
    v-model="showModal"
    @close-modal="handleCloseModal"
    @update-modal="updateModal"
  />
</template>
