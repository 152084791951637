<script setup lang="ts">
import { onMounted } from "vue";

import BaseMultiselect from "@/components/BaseMultiselect.vue";
import ClientItineraryAddServiceModalDetails from "./ClientItineraryAddServiceModalDetails.vue";

import { useDebounceFn } from "@vueuse/core";

import { useAlgolia, transformHits } from "@/helpers/algolia";
import { computed, ref } from "vue";

import { formatUrl } from "../composables/useFormData";

import type { PlaceAlgolia } from "@/types";
import type { ClientItineraryData } from "../composables/useFormData";
import type { PropType } from "vue";

const props = defineProps({
  apiPlaceId: {
    type: null as unknown as PropType<number | null>,
    default: null,
  },
  apiPlaceName: {
    type: String,
    default: "",
  },
  formData: {
    type: Object as PropType<ClientItineraryData>,
    required: true,
  },
});
const emits = defineEmits(["update-place"]);
const selectedPlace = ref<ClientItineraryData | null>(null);
const adminToolUrl = import.meta.env.VITE_ADMIN_TOOL_URL;
const placeId = ref<number | null>(props.apiPlaceId);
const places = ref<PlaceAlgolia[]>([]);

const formatSelectPlaces = computed(() => {
  return places.value.map((place) => {
    return {
      label: place.name["fr"],
      value: place.id,
      address: place.address,
    };
  });
});

const formatPlace = (place: PlaceAlgolia) => {
  const regex = /^(.*?),\s?(\d{3,5}(?:[-\s]\d{2,3})?)?,?\s?(.+?)?$/;
  const address = place.address.fr;
  const match = address.match(regex);

  const photoUrl = place.photoPath
    ? formatUrl(place.photoPath)
    : place.photoUrl
      ? formatUrl(place.photoUrl)
      : null;

  let data: ClientItineraryData = {
    address: place.address.fr,
    phone: place.phone,
    at_the_propery: true,
    zip_code: "",
    city: "",
    description: place.description.en,
    category_name: place.type,
    website: place.websiteUrl,
    gpslongitude: place.geoloc.lng,
    gpslatitude: place.geoloc.lat,
    photoUrl: photoUrl,
  };

  if (match) {
    let [, addressPart, zipCode, city] = match;
    data.address = addressPart.trim();
    data.zip_code = zipCode?.trim() ?? "";
    data.city = city?.trim() ?? "";
  } else {
    console.error("Address not formatted correctly");
  }

  return data;
};

const fetchAlgolia: {
  (query: string): Promise<PlaceAlgolia[]>;
} = async (query: string, pId: number | null) => {
  try {
    let q = query;
    const params: {
      filters?: string;
      attributesToRetrieve: string[];
    } = {
      attributesToRetrieve: ["*"],
    };
    if (pId) {
      q = "";
      params.filters = `id=${pId}`;
    }

    const { hits } = await useAlgolia("admin_places").search(q, {
      ...params,
    });

    const h = transformHits(hits) as unknown as PlaceAlgolia[];

    return h;
  } catch (err) {
    console.error(err.message);
  }
};
const searchResult = useDebounceFn(async (query) => {
  try {
    const h = await fetchAlgolia(query);
    places.value = h;

    if (places.value.find((p) => p.id === placeId.value)) {
      const p = places.value.find(
        (p) => p.id === placeId.value,
      ) as PlaceAlgolia;

      selectedPlace.value = formatPlace(p) as ClientItineraryData;

      emits("update-place", selectedPlace.value);
    }
  } catch (e) {
    console.error(e.message);
  }
}, 100);

const handleSelect = (placeId: number) => {
  const p = places.value.find((p) => p.id === placeId) as PlaceAlgolia;
  selectedPlace.value = formatPlace(p) as ClientItineraryData;
  emits("update-place", selectedPlace.value);
};

const handleClose = () => {
  selectedPlace.value = null;
  emits("update-place", selectedPlace.value);
};

onMounted(async () => {
  if (props.apiPlaceId) {
    const h = await fetchAlgolia(props.apiPlaceName, props.apiPlaceId);
    places.value = h;
  }
});
</script>

<template>
  <div>
    <p class="mb-2 text-sm">
      Enter the name of a place provided in the
      <a
        class="font-bold text-blue-600 underline"
        :href="`${adminToolUrl}fr/places`"
        target="_blank"
      >
        Address Book
      </a>
    </p>

    <BaseMultiselect
      attribute="api_place_id"
      id="appointment_name"
      v-model="placeId"
      :options="formatSelectPlaces"
      :searchable="true"
      :min-chars="1"
      :resolve-on-load="false"
      @search-change="searchResult"
      @select="handleSelect"
      @close="handleClose"
      model="appointment"
    />
    <ClientItineraryAddServiceModalDetails
      v-if="(apiPlaceId && formData) || selectedPlace"
      :selected-place="formData"
    />
  </div>
</template>
