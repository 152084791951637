<template>
  <svg
    width="196px"
    height="25px"
    viewBox="0 0 196 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Group">
        <path
          d="M66.6757991,14.6428571 C66.6757991,18.3928571 63.3643836,21.1607143 59.6949772,21.1607143 C56.0255708,21.1607143 52.7141553,18.3928571 52.7141553,14.6428571 C52.7141553,10.8928571 56.0255708,8.125 59.6949772,8.125 C63.4538813,8.125 66.6757991,10.8928571 66.6757991,14.6428571 Z M55.6675799,14.6428571 C55.6675799,18.4821429 57.1890411,20.625 59.7844749,20.625 C62.3799087,20.625 63.9013699,18.4821429 63.9013699,14.6428571 C63.9013699,10.8035714 62.3799087,8.66071429 59.7844749,8.66071429 C57.1890411,8.66071429 55.6675799,10.8035714 55.6675799,14.6428571 Z"
          id="Shape"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
        <path
          d="M75.4465753,20.3571429 C75.4465753,20.3571429 75.5360731,20.3571429 75.5360731,20.3571429 L75.5360731,20.8035714 L68.6447489,20.8035714 L68.6447489,20.3571429 C68.7342466,20.3571429 68.7342466,20.3571429 68.7342466,20.3571429 C70.8821918,20.3571429 70.8821918,19.6428571 70.8821918,16.4285714 L70.8821918,4.01785714 C70.8821918,2.14285714 70.4347032,1.60714286 68.7342466,1.42857143 L68.7342466,1.07142857 C70.3452055,0.892857143 71.8666667,0.714285714 73.2986301,0.535714286 L73.2986301,16.3392857 C73.2986301,19.7321429 73.2986301,20.3571429 75.4465753,20.3571429 Z"
          id="Shape"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
        <path
          d="M83.9488584,20.3571429 C83.9488584,20.3571429 84.0383562,20.3571429 84.0383562,20.3571429 L84.0383562,20.8035714 L77.147032,20.8035714 L77.147032,20.3571429 C77.2365297,20.3571429 77.2365297,20.3571429 77.2365297,20.3571429 C79.3844749,20.3571429 79.3844749,19.6428571 79.3844749,16.4285714 L79.3844749,4.01785714 C79.3844749,2.14285714 78.9369863,1.60714286 77.2365297,1.42857143 L77.2365297,1.07142857 C78.8474886,0.892857143 80.3689498,0.714285714 81.8009132,0.535714286 L81.8009132,16.3392857 C81.8009132,19.7321429 81.890411,20.3571429 83.9488584,20.3571429 Z"
          id="Shape"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
        <path
          d="M88.8712329,14.1071429 C88.8712329,18.2142857 90.8401826,20.5357143 93.6146119,20.5357143 C95.5835616,20.5357143 97.1050228,19.5535714 97.8210046,17.7678571 L98.4474886,17.7678571 C97.7315068,20 95.6730594,21.25 92.8091324,21.25 C88.6922374,21.25 86.0073059,18.8392857 86.0073059,15 C86.0073059,10.8928571 89.0502283,8.21428571 92.9881279,8.21428571 C96.0310502,8.21428571 98.0894977,10 98.0894977,12.5892857 C98.0894977,12.5892857 98.0894977,12.6785714 98.0894977,12.6785714 L89.0502283,12.6785714 C88.8712329,13.3035714 88.8712329,13.75 88.8712329,14.1071429 Z M95.8520548,12.0535714 C95.6730594,9.82142857 94.509589,8.66071429 92.7196347,8.66071429 C90.7506849,8.66071429 89.4977169,10.1785714 89.139726,12.0535714 L95.8520548,12.0535714 Z"
          id="Shape"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
        <path
          d="M6.8913242,20.3571429 C6.8913242,20.3571429 6.98082192,20.3571429 6.98082192,20.3571429 L6.98082192,20.8035714 L0.0894977169,20.8035714 L0.0894977169,20.3571429 C0.178995434,20.3571429 0.178995434,20.3571429 0.178995434,20.3571429 C2.32694064,20.3571429 2.32694064,19.6428571 2.32694064,16.4285714 L2.32694064,4.01785714 C2.32694064,2.14285714 1.87945205,1.60714286 0.178995434,1.42857143 L0.178995434,1.07142857 C1.78995434,0.892857143 3.31141553,0.714285714 4.743379,0.535714286 L4.743379,16.3392857 C4.743379,19.7321429 4.743379,20.3571429 6.8913242,20.3571429 Z"
          id="Shape"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
        <path
          d="M11.7242009,14.1071429 C11.7242009,18.2142857 13.6931507,20.5357143 16.4675799,20.5357143 C18.4365297,20.5357143 19.9579909,19.5535714 20.6739726,17.7678571 L21.3004566,17.7678571 C20.5844749,20 18.5260274,21.25 15.6621005,21.25 C11.5452055,21.25 8.86027397,18.8392857 8.86027397,15 C8.86027397,10.8928571 11.9031963,8.21428571 15.8410959,8.21428571 C18.8840183,8.21428571 20.9424658,10 20.9424658,12.5892857 C20.9424658,12.5892857 20.9424658,12.6785714 20.9424658,12.6785714 L11.9031963,12.6785714 C11.8136986,13.3035714 11.7242009,13.75 11.7242009,14.1071429 Z M18.7050228,12.0535714 C18.5260274,9.82142857 17.3625571,8.66071429 15.5726027,8.66071429 C13.603653,8.66071429 12.3506849,10.1785714 11.9926941,12.0535714 L18.7050228,12.0535714 Z"
          id="Shape"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
        <path
          d="M106.860274,21.25 C103.011872,21.25 100.326941,18.8392857 100.326941,15.0892857 C100.326941,10.9821429 103.190868,8.21428571 106.949772,8.21428571 C109.634703,8.21428571 111.245662,9.375 111.245662,10.5357143 C111.245662,11.25 110.798174,11.6964286 110.082192,11.6964286 C109.276712,11.6964286 108.918721,11.25 108.739726,10.3571429 C108.560731,9.375 108.292237,8.75 106.949772,8.75 C104.622831,8.75 103.190868,10.9821429 103.190868,14.9107143 C103.190868,18.5714286 104.891324,20.5357143 107.665753,20.5357143 C109.545205,20.5357143 110.798174,19.5535714 111.156164,17.8571429 L111.693151,17.8571429 C111.33516,19.9107143 109.545205,21.25 106.860274,21.25 Z"
          id="Shape"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
        <path
          d="M113.572603,9.10714286 L113.572603,8.57142857 C115.452055,8.30357143 116.43653,7.23214286 117.152511,5.17857143 L117.689498,5.17857143 L117.689498,8.57142857 L121.358904,8.57142857 L121.358904,9.10714286 L117.689498,9.10714286 L117.6,16.6071429 C117.6,16.6964286 117.6,16.875 117.6,16.9642857 C117.6,19.2857143 117.957991,20.4464286 119.747945,20.4464286 C120.73242,20.4464286 121.358904,20 121.985388,19.1071429 L122.343379,19.2857143 C121.716895,20.5357143 120.463927,21.1607143 118.852968,21.1607143 C116.257534,21.1607143 115.273059,20.2678571 115.273059,18.3035714 C115.273059,18.2142857 115.273059,18.2142857 115.273059,18.125 L115.541553,9.01785714 L113.572603,9.01785714 L113.572603,9.10714286 Z"
          id="Shape"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
        <path
          d="M129.861187,20.3571429 C129.861187,20.3571429 129.950685,20.3571429 129.950685,20.3571429 L129.950685,20.8035714 L123.059361,20.8035714 L123.059361,20.3571429 C123.148858,20.3571429 123.148858,20.3571429 123.148858,20.3571429 C125.296804,20.3571429 125.296804,19.6428571 125.296804,16.4285714 L125.296804,11.7857143 C125.296804,9.91071429 124.849315,9.28571429 123.148858,9.19642857 L123.148858,8.92857143 C124.67032,8.66071429 126.191781,8.39285714 127.713242,8.03571429 L127.713242,16.5178571 C127.713242,19.7321429 127.80274,20.3571429 129.861187,20.3571429 Z M126.460274,0.803571429 C127.265753,0.803571429 127.892237,1.42857143 127.892237,2.14285714 C127.892237,2.94642857 127.265753,3.48214286 126.460274,3.48214286 C125.654795,3.48214286 125.028311,2.94642857 125.028311,2.14285714 C125.028311,1.42857143 125.744292,0.803571429 126.460274,0.803571429 Z"
          id="Shape"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
        <path
          d="M145.791781,14.6428571 C145.791781,18.3928571 142.480365,21.1607143 138.810959,21.1607143 C135.141553,21.1607143 131.830137,18.3928571 131.830137,14.6428571 C131.830137,10.8928571 135.141553,8.125 138.810959,8.125 C142.569863,8.125 145.791781,10.8928571 145.791781,14.6428571 Z M134.783562,14.6428571 C134.783562,18.4821429 136.305023,20.625 138.900457,20.625 C141.49589,20.625 143.017352,18.4821429 143.017352,14.6428571 C143.017352,10.8035714 141.49589,8.66071429 138.900457,8.66071429 C136.305023,8.66071429 134.783562,10.8035714 134.783562,14.6428571 Z"
          id="Shape"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
        <path
          d="M154.741553,20.3571429 C154.741553,20.3571429 154.83105,20.3571429 154.83105,20.3571429 L154.83105,20.8035714 L147.939726,20.8035714 L147.939726,20.3571429 C148.029224,20.3571429 148.029224,20.3571429 148.029224,20.3571429 C150.177169,20.3571429 150.177169,19.6428571 150.177169,16.4285714 L150.177169,11.7857143 C150.177169,9.91071429 149.72968,9.28571429 148.029224,9.19642857 L148.029224,8.92857143 C149.461187,8.75 150.893151,8.39285714 152.235616,8.03571429 L152.414612,8.125 L152.593607,9.82142857 C153.936073,8.66071429 155.278539,8.21428571 157.157991,8.21428571 C160.558904,8.21428571 161.185388,9.55357143 161.185388,12.3214286 L161.185388,16.5178571 C161.185388,19.8214286 161.274886,20.4464286 163.333333,20.4464286 C163.333333,20.4464286 163.422831,20.4464286 163.422831,20.4464286 L163.422831,20.8928571 L156.531507,20.8928571 L156.531507,20.4464286 C156.531507,20.4464286 156.621005,20.4464286 156.621005,20.4464286 C158.679452,20.4464286 158.76895,19.7321429 158.76895,16.5178571 L158.76895,12.3214286 C158.76895,10.0892857 157.873973,8.83928571 155.994521,8.83928571 C155.010046,8.83928571 153.936073,9.28571429 153.309589,10 C152.683105,10.7142857 152.593607,11.5178571 152.593607,12.5 C152.593607,12.7678571 152.593607,12.9464286 152.593607,13.2142857 L152.593607,16.4285714 C152.593607,19.7321429 152.593607,20.3571429 154.741553,20.3571429 Z"
          id="Shape"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
        <path
          d="M172.10411,20.3571429 C172.10411,20.3571429 172.193607,20.3571429 172.193607,20.3571429 L172.193607,20.8035714 L165.302283,20.8035714 L165.302283,20.3571429 C165.391781,20.3571429 165.391781,20.3571429 165.391781,20.3571429 C167.539726,20.3571429 167.539726,19.6428571 167.539726,16.4285714 L167.539726,11.7857143 C167.539726,9.91071429 167.092237,9.28571429 165.391781,9.19642857 L165.391781,8.92857143 C166.913242,8.66071429 168.434703,8.39285714 169.956164,8.03571429 L169.956164,16.5178571 C169.956164,19.7321429 169.956164,20.3571429 172.10411,20.3571429 Z M168.703196,0.803571429 C169.508676,0.803571429 170.13516,1.42857143 170.13516,2.14285714 C170.13516,2.94642857 169.508676,3.48214286 168.703196,3.48214286 C167.897717,3.48214286 167.271233,2.94642857 167.271233,2.14285714 C167.271233,1.42857143 167.897717,0.803571429 168.703196,0.803571429 Z"
          id="Shape"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
        <path
          d="M174.699543,16.7857143 L174.968037,16.7857143 C175.773516,19.4642857 176.847489,20.7142857 178.995434,20.7142857 C180.427397,20.7142857 181.322374,19.9107143 181.322374,18.6607143 C181.322374,17.4107143 180.516895,16.6964286 178.995434,15.8928571 C176.847489,14.7321429 175.23653,13.9285714 175.23653,11.6964286 C175.23653,9.73214286 176.847489,8.21428571 178.905936,8.21428571 C180.3379,8.21428571 181.232877,8.92857143 181.680365,8.92857143 C181.948858,8.92857143 182.217352,8.75 182.306849,8.39285714 L182.575342,8.39285714 L182.575342,12.0535714 L182.306849,12.0535714 C181.769863,9.91071429 180.69589,8.75 178.995434,8.75 C177.831963,8.75 177.026484,9.46428571 177.026484,10.625 C177.026484,11.9642857 177.921461,12.5 179.53242,13.3035714 C181.859361,14.4642857 183.47032,15.3571429 183.47032,17.5892857 C183.47032,19.7321429 181.769863,21.25 179.174429,21.25 C177.294977,21.25 176.310502,20.4464286 175.863014,20.4464286 C175.505023,20.4464286 175.23653,20.625 174.968037,21.0714286 L174.699543,21.0714286 L174.699543,16.7857143 L174.699543,16.7857143 Z"
          id="Shape"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
        <path
          d="M186.513242,9.10714286 L186.513242,8.57142857 C188.392694,8.30357143 189.377169,7.23214286 190.093151,5.17857143 L190.630137,5.17857143 L190.630137,8.57142857 L194.299543,8.57142857 L194.299543,9.10714286 L190.630137,9.10714286 L190.540639,16.6071429 C190.540639,16.6964286 190.540639,16.875 190.540639,16.9642857 C190.540639,19.2857143 190.89863,20.4464286 192.688584,20.4464286 C193.673059,20.4464286 194.299543,20 194.926027,19.1071429 L195.284018,19.2857143 C194.657534,20.5357143 193.404566,21.1607143 191.793607,21.1607143 C189.198174,21.1607143 188.213699,20.2678571 188.213699,18.3035714 C188.213699,18.2142857 188.213699,18.2142857 188.213699,18.125 L188.482192,9.01785714 L186.513242,9.01785714 L186.513242,9.10714286 Z"
          id="Shape"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
        <path
          d="M41.4374429,21.25 C37.5890411,21.25 34.9041096,18.75 34.9041096,15 C34.9041096,10.8928571 37.7680365,8.125 41.5269406,8.125 C44.2118721,8.125 45.8228311,9.28571429 45.8228311,10.4464286 C45.8228311,11.1607143 45.3753425,11.6071429 44.6593607,11.6071429 C43.8538813,11.6071429 43.4958904,11.1607143 43.316895,10.2678571 C43.1378995,9.28571429 42.8694064,8.66071429 41.5269406,8.66071429 C39.2,8.66071429 37.7680365,10.8928571 37.7680365,14.8214286 C37.7680365,18.4821429 39.4684932,20.4464286 42.2429224,20.4464286 C44.1223744,20.4464286 45.3753425,19.4642857 45.7333333,17.7678571 L46.2703196,17.7678571 C45.9123288,19.9107143 44.1223744,21.25 41.4374429,21.25 Z"
          id="Shape"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
        <ellipse
          id="Oval"
          stroke="#000000"
          stroke-width="0.5"
          cx="41.1689498"
          cy="14.6428571"
          rx="9.57625571"
          ry="9.55357143"
        ></ellipse>
      </g>
    </g>
  </svg>
</template>
