import { API, ApiResponse } from "@/api/config";
import type { GuestsContract, CreateGuests } from "./types";

export default {
  show(id: number): ApiResponse<GuestsContract, undefined> {
    return API.get(`v3/tailor/admin/contracts/${id}/guest_contacts`);
  },
  create(
    id: number,
    params: CreateGuests,
  ): ApiResponse<GuestsContract, undefined> {
    return API.post(`v3/tailor/admin/contracts/${id}/guest_contacts`, params);
  },
  delete(id: number, guestContactId : string): ApiResponse<GuestsContract, undefined> {
    return API.delete(`v3/tailor/admin/contracts/${id}/guest_contacts/${guestContactId}`);
  },
};
