import { computed, ref } from "vue";

import { formatWithUTC } from "@/helpers/dates";

import type { Appointments } from "@/types";

export type InitialData = {
  startAt: string;
  endAt: string;
  startAtTime: string;
  endAtTime: string;
};
export type ClientItineraryData = InitialData & {
  address: string;
  api_place_id: number | null;
  at_the_propery: boolean;
  category_name: string;
  city: string;
  description: string;
  gpslatitude: string;
  gpslongitude: string;
  image_path: string;
  name: string;
  phone: string;
  photoUrl: string | null;
  place_image_path: string;
  website: string;
  zip_code: string;
};

export const formatUrl = (url: string | null) => {
  const CDN_URL = import.meta.env.VITE_CDN_URL;
  if (url) {
    return `${CDN_URL}${url}`;
  }

  return null;
};

export const useFormData = (selectedClientItinerary: Appointments | null) => {
  const isPublished = selectedClientItinerary?.published ? 1 : 0;
  const displayToggleData = ref({
    value: isPublished,
    status: isPublished === 1 ? "Published" : "Not published",
    text: isPublished === 1 ? "Not published" : "Published",
  });

  const formData = ref<ClientItineraryData>({
    address: "",
    api_place_id: null,
    at_the_propery: false,
    category_name: "",
    city: "",
    description: "",
    endAt: formatWithUTC(new Date(), "YYYY-MM-DD"),
    endAtTime: formatWithUTC(new Date(), "HH:mm"),
    gpslatitude: "",
    gpslongitude: "",
    image_path: "",
    name: "",
    phone: "",
    photoUrl: "",
    place_image_path: "",
    startAt: formatWithUTC(new Date(), "YYYY-MM-DD"),
    startAtTime: formatWithUTC(new Date(), "HH:mm"),
    website: "",
    zip_code: "",
  });

  const setFormData = (clientItinerary: ClientItineraryData) => {
    formData.value = {
      ...formData.value,
      ...clientItinerary,
    };
  };

  const photoUrl = computed(() => {
    return selectedClientItinerary?.image_path
      ? formatUrl(selectedClientItinerary?.image_path)
      : "";
  });

  if (selectedClientItinerary) {
    formData.value.startAt = formatWithUTC(
      selectedClientItinerary.start_at,
      "YYYY-MM-DD",
    );
    formData.value.endAt = formatWithUTC(
      selectedClientItinerary.end_at,
      "YYYY-MM-DD",
    );
    formData.value.startAtTime = formatWithUTC(
      selectedClientItinerary.start_at_time,
      "HH:mm",
    );
    formData.value.endAtTime = formatWithUTC(
      selectedClientItinerary.end_at_time,
      "HH:mm",
    );
    formData.value.address = selectedClientItinerary.address || "";
    formData.value.category_name = selectedClientItinerary.category_name || "";
    formData.value.city = selectedClientItinerary.city || "";
    formData.value.description = selectedClientItinerary.description || "";
    formData.value.gpslatitude = selectedClientItinerary.gpslatitude || "";
    formData.value.gpslongitude = selectedClientItinerary.gpslongitude || "";
    formData.value.name = selectedClientItinerary.name || "";
    formData.value.phone = selectedClientItinerary.phone || "";
    formData.value.website = selectedClientItinerary.website || "";
    formData.value.zip_code = selectedClientItinerary.zip_code || "";
    formData.value.photoUrl = photoUrl.value;
    formData.value.api_place_id = selectedClientItinerary.api_place_id || null;
    formData.value.at_the_propery =
      selectedClientItinerary.at_the_property || false;
  }

  return {
    formData,
    setFormData,
    displayToggleData,
  };
};
