<script lang="ts" setup>
import { computed, reactive, ref } from "vue";

import BaseMultiselect from "@/components/BaseMultiselect.vue";
import { Calculator } from "@/components/Services";

import type { BookingService, Contract, Service, ThirdParty } from "@/types";

const { gon } = window;
const bookingService = ref<BookingService>(gon.bookingService);
const service = ref<Service>(gon.service);
const contract = ref<Contract>(gon.contract);
const thirdParty = ref<ThirdParty>(gon.thirdParty);
const isOnSitePayment = service.value?.on_site_payment;
const isFullCommissionThirdParty = thirdParty.value?.full_commission;
const disabledCalculatorFields = {
  supplierPublicPrice: true,
  targetCommission: true,
  supplierDiscount: true,
};
const invoicingType = ref(thirdParty.value.invoicing_type);
const languages = ref([
  {
    label: "Français",
    value: "fr",
  },
  {
    label: "English",
    value: "en",
  },
]);
const pricingType = computed(() => {
  if (bookingService.value.pricing_type === "duration") {
    return bookingService.value.duration;
  }

  return bookingService.value.pricing_type;
});
const pricingTypeText = computed(() => {
  if (pricingType.value === "number_of_days") {
    return "days";
  } else if (pricingType.value === "number_of_weeks") {
    return "weeks";
  }

  return "units";
});

const calculator = ref(null);
const data = reactive({
  bookingServiceId: service.value.id,
  dateRange: bookingService.value.date_range || false,
  language: bookingService.value.language || "en",
  quantity: bookingService.value.quantity || 1,
  serviceContractId: contract.value.id,
  serviceName: bookingService.value.name,
  serviceDescription: bookingService.value.description,
  weekDuration: bookingService.value.quantity || 1,
});
const dataForCalculator = reactive({
  clientPriceIncl: bookingService.value?.client_price_with_vat || 0,
  clientPriceExcl: bookingService.value?.client_price_without_vat || 0,
  supplierPriceIncVat: bookingService.value?.supplier_price_with_vat || 0,
  supplierPriceExlVat: bookingService.value?.supplier_price_without_vat || 0,
  supplierVatRate: isFullCommissionThirdParty
    ? 0
    : bookingService.value?.supplier_vat_rate || 0,
  lcTargetCommissionRate: isFullCommissionThirdParty
    ? 0
    : bookingService.value?.lc_target_commission_rate || 0,
  supplierDiscountRate: isFullCommissionThirdParty
    ? 0
    : bookingService.value.supplier_discount_rate || 0,
  supplierPublicPriceWithoutVat:
    bookingService.value?.supplier_public_price_without_vat,
  supplierPublicPriceIncVat:
    bookingService.value?.supplier_public_price_with_vat || 0,
  lcCommissionWithVat: bookingService.value?.lc_commission_with_vat || 0,
  lcCommissionWithoutVat: bookingService.value?.lc_commission_without_vat || 0,
  retroComIncVat: bookingService.value?.retro_commission_with_vat || 0,
  retroComExlVat: bookingService.value?.retro_commission_without_vat || 0,
});
</script>

<template>
  <form class="my-8" accept-charset="UTF-8" method="post">
    <div class="mb-4 integer required booking_service_quantity">
      <p class="text-gray-500 font-medium mt-2">
        Dates: {{ contract.check_in_date }} - {{ contract.check_out_date }}
      </p>
      <label
        class="block integer required text-sm font-medium text-gray-600"
        for="booking_service_quantity"
      >
        <span class="mr-2">Number of {{ pricingTypeText }}:</span>
        <span class="text-base font-medium">
          {{ data.quantity }}
        </span>
      </label>
    </div>
    <h1 class="text-2xl font-bold text-gray-900 sm:truncate mt-6">
      Total Pricing
    </h1>
    <Calculator
      v-model:data="dataForCalculator"
      :disabled="true"
      :disabledFields="disabledCalculatorFields"
      :errors="{}"
      :is-full-commission-third-party="isFullCommissionThirdParty"
      :is-on-site-payment="isOnSitePayment"
      :is-update="false"
      :invoicing-type="invoicingType"
      class="mt-6 w-[800px]"
      form-id="booking_service"
      ref="calculator"
    >
    </Calculator>
    <h1 class="text-2xl font-bold text-gray-900 sm:truncate my-6">
      Service description
    </h1>
    <div class="string required booking_service_name">
      <label
        class="block string required text-sm font-medium text-gray-600"
        for="booking_service_name"
      >
        Name <abbr title="required">*</abbr>
      </label>
      <input
        v-model="data.serviceName"
        disabled="true"
        class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string required"
        type="text"
        name="booking_service[name]"
        id="booking_service_name"
      />
    </div>
    <label for="Language_description" class="mb-4 block">
      <p
        class="mt-4 block text optional text-sm font-medium text-gray-600 mb-2"
      >
        Language description
      </p>
      <BaseMultiselect
        v-model="data.language"
        :options="languages"
        :disabled="true"
      />
      <input
        class="hidden"
        id="language_description"
        name="language_description"
        :value="data.language"
      />
    </label>
    <div>
      <label
        class="block text optional text-sm font-medium text-gray-600"
        for="booking_service_description"
      >
        Description
      </label>
      <textarea
        disabled="true"
        v-model="data.serviceDescription"
        class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out text optional"
        name="booking_service[description]"
        rows="6"
        id="booking_service_description"
      />
    </div>
  </form>
</template>
