<script lang="ts" setup>
import { toRefs } from "@vueuse/core";
import { computed, onMounted } from "vue";
import { transformVAT } from "@/helpers/price";

type Errors = {
  lc_target_commission_rate: string[];
};

const props = defineProps<{
  disabled: boolean;
  errors: Errors;
  formId: string;
  isFullCommissionThirdParty?: boolean;
  isOnSitePayment: boolean;
  isUpdate?: boolean;
  invoicingType: string;
  disabledFields?: {
    supplierPublicPrice: false;
    targetCommission: false;
    supplierDiscount: false;
  };
  data: {
    clientPriceExcl: string | number;
    clientPriceIncl: string | number;
    lcCommissionWithoutVat: string | number;
    lcCommissionWithVat: string | number;
    lcTargetCommissionRate: string | number;
    supplierDiscountRate: string | number;
    supplierPriceExlVat: string | number;
    supplierPriceIncVat: string | number;
    supplierPublicPriceIncVat: string | number;
    supplierPublicPriceWithoutVat: string | number;
    supplierVatRate: string | number;
    vatRate: string | number;
    invoicingType: string | number;
    retroComIncVat: string | number;
    retroComExlVat: string | number;
  };
}>();

const thirdPartyRetroCom = computed(() => {
  return (
    props.invoicingType === "retro_com_end_of_season" ||
    props.invoicingType === "retro_com_end_of_service"
  );
});

let {
  clientPriceExcl,
  clientPriceIncl,
  lcCommissionWithoutVat,
  lcCommissionWithVat,
  lcTargetCommissionRate,
  supplierDiscountRate,
  supplierPriceExlVat,
  supplierPriceIncVat,
  supplierPublicPriceIncVat,
  supplierPublicPriceWithoutVat,
  supplierVatRate,
  retroComIncVat,
  retroComExlVat,
} = toRefs(props.data);

const isAuthorize = (
  event: Event & { which: number; keyCode: number; target: HTMLInputElement },
) => {
  const ASCIICode = event.which ? event.which : event.keyCode;
  // Authorizes only numbers, comma and points
  const authorizeCodes = [44, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];

  if (authorizeCodes.includes(ASCIICode)) {
    if (ASCIICode === 44) {
      event.target.value = event.target.value + ".";

      return false;
    }

    return true;
  }

  return false;
};

// Listen simple VAT or supplier VAT
const calculSupplierVatRate = () => {
  calculAllDisabledFields();
};

// Listen Supplier public price EXCL VAT
const calculSupplierPublicPriceWithoutVat = () => {
  calculSupplierPriceIncVat();
  calculSupplierPriceExclAndIncl();
  if (supplierDiscountRate.value) {
    calculSupplierPriceIncVatWithLCCommission();
  }
  calculClientPrice();
  calculLCCommission();

  // Fill Retro Com
  calculRetroCom();
};
// Listen Supplier public price INCL VAT
const calculSupplierPublicPriceIncVat = () => {
  calculSupplierPrinceExclVat();
  // Fill Supplier price
  calculSupplierPriceExclAndIncl();
  if (supplierDiscountRate.value) {
    calculSupplierPriceIncVatWithLCCommission();
  }
  calculClientPrice();
  calculLCCommission();

  // Fill Retro Com
  calculRetroCom();
};
const checkCommissionRateAndDiscountRate = computed(() => {
  return (
    Number(lcTargetCommissionRate.value) < Number(supplierDiscountRate.value)
  );
});
// Listen input Target LC% commission
const calculLcTargetCommissionRate = () => {
  calculSupplierPriceExclAndIncl();
  if (supplierDiscountRate.value) {
    calculSupplierPriceIncVatWithLCCommission();
  }

  // Fill Supplier price
  calculClientPrice();
  calculLCCommission();

  // Fill Retro Com
  calculRetroCom();
};
// Listen % Supplier discount
const calculSupplierDiscount = () => {
  calculSupplierPriceIncVatWithLCCommission();

  // Fill Supplier price
  calculClientPrice();
  calculLCCommission();

  // Fill Retro Com
  calculRetroCom();
};

const VAT = computed(() => {
  const VAT = supplierVatRate.value as number;

  return transformVAT(VAT);
});

// Calculate method
const calculSupplierPriceIncVat = () => {
  if (supplierPublicPriceWithoutVat.value && supplierPublicPriceIncVat.value) {
    const newSupplierPublicPriceIncVat =
      Number(supplierPublicPriceWithoutVat.value) * VAT.value;

    supplierPublicPriceIncVat.value = newSupplierPublicPriceIncVat.toFixed(2);
    supplierPriceIncVat.value = newSupplierPublicPriceIncVat.toFixed(2);
  } else if (supplierPublicPriceWithoutVat.value) {
    const newSupplierPublicPriceIncVat =
      Number(supplierPublicPriceWithoutVat.value) * VAT.value;

    supplierPublicPriceIncVat.value = newSupplierPublicPriceIncVat.toFixed(2);
  }
};
const calculSupplierPrinceExclVat = () => {
  const newSupplierPublicPriceExclVAT =
    Number(supplierPublicPriceIncVat.value) / VAT.value;

  supplierPublicPriceWithoutVat.value =
    newSupplierPublicPriceExclVAT.toFixed(4);
};
const calculClientPrice = () => {
  const newClientPriceExcl =
    Number(supplierPriceExlVat.value) /
    (1 - Number(lcTargetCommissionRate.value) / 100);
  clientPriceExcl.value = newClientPriceExcl.toFixed(2);

  const newClientPriceIncl =
    Number(supplierPriceIncVat.value) /
    (1 - Number(lcTargetCommissionRate.value) / 100);
  clientPriceIncl.value = newClientPriceIncl.toFixed(2);
};
const calculLCCommission = () => {
  if (props.isFullCommissionThirdParty) {
    lcCommissionWithVat.value = clientPriceIncl.value;
    lcCommissionWithoutVat.value = clientPriceExcl.value;
  } else {
    const newLCCommission =
      Number(clientPriceIncl.value) - Number(supplierPriceIncVat.value);

    lcCommissionWithVat.value = newLCCommission.toFixed(2);

    const newLcCommissionWithoutVat =
      Number(clientPriceExcl.value) - Number(supplierPriceExlVat.value);

    lcCommissionWithoutVat.value = newLcCommissionWithoutVat.toFixed(2);
  }
};
const calculSupplierPriceExclAndIncl = () => {
  const newSupplierPublicPriceIncVat =
    Number(supplierPublicPriceWithoutVat.value) * VAT.value;

  supplierPriceIncVat.value = newSupplierPublicPriceIncVat.toFixed(4);
  supplierPriceExlVat.value = supplierPublicPriceWithoutVat.value;
};
const calculSupplierPriceIncVatWithLCCommission = () => {
  const newClientPriceIncl =
    Number(supplierPublicPriceIncVat.value) *
    (1 - Number(supplierDiscountRate.value) / 100);

  supplierPriceIncVat.value = newClientPriceIncl.toFixed(2);
  supplierPriceExlVat.value = (newClientPriceIncl / VAT.value).toFixed(2);
};

const calculRetroCom = () => {
  const newRetroComIncVat =
    Number(supplierPublicPriceIncVat.value) *
    (Number(supplierDiscountRate.value) / 100);

  const newRetroComExlVat =
    Number(supplierPublicPriceWithoutVat.value) *
    (Number(supplierDiscountRate.value) / 100);

  retroComIncVat.value = newRetroComIncVat.toFixed(2);
  retroComExlVat.value = newRetroComExlVat.toFixed(2);
};

const calculClientPriceInclVAT = () => {
  if (props.isOnSitePayment) {
    clientPriceIncl.value = (
      Number(clientPriceExcl.value) * Number(VAT.value)
    ).toFixed(2);
  }
};
const calculClientPriceExclVAT = () => {
  if (props.isOnSitePayment) {
    clientPriceExcl.value = (
      Number(clientPriceIncl.value) / Number(VAT.value)
    ).toFixed(2);
  }
};
const calculRetroComInclVAT = () => {
  if (props.isOnSitePayment) {
    retroComIncVat.value = (
      Number(retroComExlVat.value) * Number(VAT.value)
    ).toFixed(2);
  }
};
const calculRetroComExclVAT = () => {
  if (props.isOnSitePayment) {
    retroComExlVat.value = (
      Number(retroComIncVat.value) / Number(VAT.value)
    ).toFixed(2);
  }
};

const calculAllDisabledFields = () => {
  calculSupplierPriceIncVat();
  calculSupplierPrinceExclVat();
  calculSupplierPriceExclAndIncl();
  if (supplierDiscountRate.value) {
    calculSupplierPriceIncVatWithLCCommission();
  }
  calculClientPrice();
  calculLCCommission();
  calculRetroCom();
};

onMounted(() => {
  if (!props.isUpdate) {
    calculAllDisabledFields();
  }
});

defineExpose({ calculate: calculAllDisabledFields });
</script>

<template>
  <div class="wrap_calculator bg-gray-50 mb-4 p-8">
    <slot name="header" />

    <div :class="[{ 'opacity-25 pointer-events-none': !disabled }]">
      <div class="grid grid-cols-3 gap-4">
        <div class="col-span-1"></div>
        <div class="col-span-1 text-center pb-4 font-medium text-gray-500">
          Incl. VAT
        </div>
        <div class="col-span-1 text-center pb-4 font-medium text-gray-500">
          Excl. VAT
        </div>
      </div>
      <div>
        <div class="grid grid-cols-3 gap-4 mb-4 items-center">
          <div class="text-right">% Supplier VAT</div>
          <div class="relative">
            <input
              v-model.number="supplierVatRate"
              class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out numeric bg-gray-200 pointer-events-none opacity-60"
              step="any"
              :name="`${formId}[supplier_vat_rate]`"
              :id="`${formId}_supplier_vat_rate`"
              :onkeypress="isAuthorize"
              @input="calculSupplierVatRate"
              @change="calculSupplierVatRate"
            />
            <span
              class="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400"
            >
              %
            </span>
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 mb-4 items-center">
          <div class="text-right">Supplier public price</div>
          <div class="relative">
            <input
              v-model.number="supplierPublicPriceIncVat"
              :class="[
                'shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out numeric',
                {
                  'bg-gray-200 pointer-events-none opacity-60':
                    disabledFields?.supplierPublicPrice,
                },
              ]"
              :disabled="disabledFields?.supplierPublicPrice"
              :id="`${formId}_supplier_public_price_with_vat`"
              :name="`${formId}[supplier_public_price_with_vat]`"
              :onkeypress="isAuthorize"
              @change="calculSupplierPublicPriceIncVat"
              @input="calculSupplierPublicPriceIncVat"
            />
            <span
              class="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400"
            >
              €
            </span>
          </div>
          <div class="relative">
            <input
              v-model.number="supplierPublicPriceWithoutVat"
              :class="[
                'shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out numeric',
                {
                  'bg-gray-200 pointer-events-none opacity-60':
                    disabledFields?.supplierPublicPrice,
                },
              ]"
              :disabled="disabledFields?.supplierPublicPrice"
              :id="`${formId}_supplier_public_price_without_vat`"
              :name="`${formId}[supplier_public_price_without_vat]`"
              :onkeypress="isAuthorize"
              @change="calculSupplierPublicPriceWithoutVat"
              @input="calculSupplierPublicPriceWithoutVat"
            />
            <span
              class="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400"
            >
              €
            </span>
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 mb-4 items-center">
          <div class="text-right">Target LC % commission</div>
          <div>
            <div class="relative">
              <input
                v-model.number="lcTargetCommissionRate"
                :class="[
                  'shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out numeric',
                  {
                    'bg-gray-200 pointer-events-none opacity-60':
                      disabledFields?.targetCommission,
                  },
                ]"
                :disabled="disabledFields?.targetCommission"
                :id="`${formId}_lc_target_commission_rate`"
                :name="`${formId}[lc_target_commission_rate]`"
                :onkeypress="isAuthorize"
                @change="calculLcTargetCommissionRate"
                @input="calculLcTargetCommissionRate"
                step="any"
              />
              <input
                v-if="
                  isFullCommissionThirdParty && disabledFields?.targetCommission
                "
                v-model.number="lcTargetCommissionRate"
                type="hidden"
                :name="`${formId}[lc_target_commission_rate]`"
              />
              <span
                class="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400"
              >
                %
              </span>
              <p
                v-if="
                  errors &&
                  errors.lc_target_commission_rate &&
                  errors.lc_target_commission_rate[0]
                "
                class="text-red-400 text-sm mt-2"
              >
                {{ errors.lc_target_commission_rate[0] }}
              </p>
            </div>
            <p
              v-if="checkCommissionRateAndDiscountRate"
              class="text-red-400 text-sm mt-2"
            >
              Lc target commission rate must be greater than or equal to
              supplier discount
            </p>
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 mb-4 items-center">
          <div class="text-right">% Supplier discount</div>

          <div>
            <div class="relative">
              <input
                v-model.number="supplierDiscountRate"
                :class="[
                  'shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out numeric',
                  {
                    'bg-gray-200 pointer-events-none opacity-60':
                      disabledFields?.supplierDiscount,
                  },
                ]"
                :disabled="disabledFields?.supplierDiscount"
                :id="`${formId}_supplier_discount_rate`"
                :name="`${formId}[supplier_discount_rate]`"
                :onkeypress="isAuthorize"
                @change="calculSupplierDiscount"
                @input="calculSupplierDiscount"
                step="any"
              />
              <input
                v-if="
                  isFullCommissionThirdParty && disabledFields?.supplierDiscount
                "
                v-model.number="supplierDiscountRate"
                type="hidden"
                :name="`${formId}[supplier_discount_rate]`"
              />
              <span
                class="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400"
              >
                %
              </span>
            </div>
            <p
              v-if="checkCommissionRateAndDiscountRate"
              class="text-red-400 text-sm mt-2"
            >
              Supplier discount must be lower than or equal to lc target
              commission rate
            </p>
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 mb-4 items-center">
          <div class="text-right">Supplier price</div>
          <div class="relative">
            <input
              v-model.number="supplierPriceIncVat"
              class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-gray-200 text-gray-400 leading-6 transition-colors duration-200 ease-in-out numeric pointer-events-none opacity-60"
              :name="`${formId}[supplier_price_with_vat]`"
              :id="`${formId}supplier_price_with_vat`"
            />
            <span
              class="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400"
            >
              €
            </span>
          </div>
          <div class="relative">
            <input
              v-model.number="supplierPriceExlVat"
              :id="`${formId}_supplier_price_without_vat`"
              :name="`${formId}[supplier_price_without_vat]`"
              class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-gray-200 text-gray-400 leading-6 transition-colors duration-200 ease-in-out numeric pointer-events-none opacity-60"
            />
            <span
              class="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400"
            >
              €
            </span>
          </div>
        </div>
        <div
          class="grid grid-cols-3 gap-4 mb-4 items-center"
          v-if="thirdPartyRetroCom"
        >
          <div class="text-right">Retro commission</div>
          <div class="relative">
            <input
              v-model.number="retroComIncVat"
              @change="calculRetroComExclVAT"
              @input="calculRetroComExclVAT"
              :class="[
                'shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 leading-6 transition-colors duration-200 ease-in-out numeric',
                {
                  'bg-gray-200 text-gray-400 pointer-events-none opacity-60':
                    !isOnSitePayment,
                },
              ]"
              :name="`${formId}[retro_commission_with_vat]`"
              :id="`${formId}retro_commission_with_vat`"
            />
            <span
              class="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400"
            >
              €
            </span>
          </div>
          <div class="relative">
            <input
              v-model.number="retroComExlVat"
              :id="`${formId}_retro_commission_without_vat`"
              :name="`${formId}[retro_commission_without_vat]`"
              @change="calculRetroComInclVAT"
              @input="calculRetroComInclVAT"
              :class="[
                'shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 leading-6 transition-colors duration-200 ease-in-out numeric',
                {
                  'bg-gray-200 text-gray-400 pointer-events-none opacity-60':
                    !isOnSitePayment,
                },
              ]"
            />
            <span
              class="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400"
            >
              €
            </span>
          </div>
        </div>

        <div class="grid grid-cols-3 gap-4 mb-4 items-center">
          <div class="text-right font-bold">Client price</div>
          <div class="relative">
            <input
              v-model.number="clientPriceIncl"
              :id="`${formId}_client_price_with_vat`"
              :name="`${formId}[client_price_with_vat]`"
              :onkeypress="isAuthorize"
              @change="calculClientPriceExclVAT"
              @input="calculClientPriceExclVAT"
              :class="[
                'shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 leading-6 transition-colors duration-200 ease-in-out numeric',
                {
                  'bg-gray-200 text-gray-400 pointer-events-none opacity-60':
                    !isOnSitePayment,
                },
              ]"
            />
            <span
              class="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400"
            >
              €
            </span>
          </div>
          <div class="relative">
            <input
              v-model.number="clientPriceExcl"
              :id="`${formId}_client_price_without_vat`"
              :name="`${formId}[client_price_without_vat]`"
              :onkeypress="isAuthorize"
              @change="calculClientPriceInclVAT"
              @input="calculClientPriceInclVAT"
              :class="[
                'shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 leading-6 transition-colors duration-200 ease-in-out numeric',
                {
                  'bg-gray-200 text-gray-400 pointer-events-none opacity-60':
                    !isOnSitePayment,
                },
              ]"
            />
            <span
              class="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400"
            >
              €
            </span>
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 items-center">
          <div class="text-right">LC Commission</div>
          <div class="relative">
            <input
              v-model.number="lcCommissionWithVat"
              class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-gray-200 text-gray-400 leading-6 transition-colors duration-200 ease-in-out numeric pointer-events-none opacity-60"
              :id="`${formId}_lc_commission_with_vat`"
              :name="`${formId}[lc_commission_with_vat]`"
            />
            <span
              class="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400"
            >
              €
            </span>
          </div>
          <div class="relative">
            <input
              v-model.number="lcCommissionWithoutVat"
              class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-gray-200 text-gray-400 leading-6 transition-colors duration-200 ease-in-out numeric pointer-events-none opacity-60"
              :id="`${formId}_lc_commission_without_vat`"
              :name="`${formId}[lc_commission_without_vat]`"
            />
            <span
              class="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400"
            >
              €
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.wrap_calculator input {
  @apply text-slate-800;
}
</style>
