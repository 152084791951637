<script setup lang="ts">
import { ref } from "vue";

const props = defineProps<{
  action: string;
  buttonText: string;
  text: string;
  disabled?: boolean;
  class?: string;
  description?: string;
}>();

const showModal = ref(false);
const toggleModal = () => {
  showModal.value = !showModal.value;
};
const executeAction = () => {
  const button = document.querySelector(`[action="${props.action}"]`);

  if (button) {
    button.submit();
  } else {
    console.error(`No button found with action ${props.action}`);
  }
};
</script>

<template>
  <button
    @click="toggleModal"
    :class="`${props.class} ${props.disabled ? 'font-medium text-gray-600' : 'font-medium text-indigo-600'}`"
    :disabled="props.disabled"
  >
    {{ props.buttonText }}
  </button>

  <template v-if="showModal">
    <div
      class="bg-slate-800 bg-opacity-40 z-10 fixed top-0 left-0 w-full h-full"
    />
    <div
      class="fixed transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-gray-200 z-20 bg-white"
    >
      <div class="p-4">
        <p class="text-lg font-bold text-slate-800 text-center mb-2">
          Be careful
        </p>
        <p class="text-md font-medium text-gray-900 mb-4 text-center">
          {{ props.text }}
        </p>
        <p
          v-if="props.description"
          class="text-md font-medium text-gray-900 mb-4"
        >
          {{ props.description }}
        </p>

        <div class="flex items-center justify-center">
          <button
            @click="toggleModal"
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-md font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            No
          </button>
          <button
            @click="executeAction"
            type="button"
            class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500 cursor-pointer"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </template>
</template>
