<script setup lang="ts">
import { computed } from "vue";

type Props = {
  isSelected: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  isSelected: false,
});

const colors = computed(() => ({
  rect: props.isSelected ? "#033535" : "white",
  path: props.isSelected ? "white" : "#757575",
}));
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <rect width="40" height="40" rx="4" :fill="colors.rect" />
    <path
      d="M11 25.4V14.6C11 14.2686 11.2686 14 11.6 14H24.6789C24.8795 14 25.0668 14.1003 25.1781 14.2672L28.7781 19.6672C28.9125 19.8687 28.9125 20.1313 28.7781 20.3328L25.1781 25.7328C25.0668 25.8997 24.8795 26 24.6789 26H11.6C11.2686 26 11 25.7314 11 25.4Z"
      :stroke="colors.path"
    />
  </svg>
</template>
