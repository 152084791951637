<script setup lang="ts">
import { computed, ref } from "vue";

import BaseModal from "@/components/BaseModal.vue";
import BaseForm from "@/components/BaseForm.vue";
import BaseWysiwyg from "@/components/BaseWysiwyg.vue";

import type { ClientItineraryType } from "./ClientItineraryArrivalDeparture.vue";

type SelectedItinerary = {
  type: ClientItineraryType | "";
  information: string;
};

type Props = {
  clientLanguage: string | null;
  contractId: number | null;
  clientItineraryId: number | null;
  modelValue: boolean;
  selectedItinerary: SelectedItinerary;
};

const emits = defineEmits(["update-modal", "update:modelValue"]);
const props = withDefaults(defineProps<Props>(), {
  clientLanguage: null,
  clientItineraryId: null,
  contractId: null,
  modelValue: false,
  selectedItinerary: () => ({ type: "", information: "" }),
});

const wysiwygAttr = computed(
  () => `client_itinerary[${props.selectedItinerary.type}_information]`,
);

const errors = ref();
const successRequest = () => {
  emits("update-modal");
  errors.value = null;
};
const errorRequest = (err: { [key: string]: string }) => {
  errors.value = err;
};

</script>

<template>
  <div>
    <BaseModal
      :model-value="props.modelValue"
      :title="`Edit ${selectedItinerary.type} information (${clientLanguage})`"
      has-footer
      hasHeader
      @close-modal="$emit('update:modelValue', false)"
    >
      <template #content>
        <p class="text-sm text-slate-600">
          This is the default information displayed, you can rewrite it once the
          information is ready.
        </p>
        <BaseForm
          id="client_itinerary_departure_arrival"
          :action="`/contracts/${contractId}/client_itineraries`"
          patch
          :on-success="successRequest"
          :on-error="errorRequest"
        >
          <BaseWysiwyg
            :name="wysiwygAttr"
            :modelValue="selectedItinerary.information"
          />
          <div class="flex justify-end mt-3">
            <button
              type="button"
              class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-primary-700 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
              @click="$emit('update:modelValue', false)"
            >
              Cancel
            </button>

            <button
              type="submit"
              class="inline-flex items-center px-3 py-2 ml-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
            >
              Save
            </button>
          </div>
        </BaseForm>
      </template>
    </BaseModal>
  </div>
</template>
