<script setup lang="ts">
import { ref, computed } from "vue";
import axios from "axios";
import BaseButton from "@/components/BaseButton.vue";
import BaseModal from "@/components/BaseModal.vue";

interface HistoryEntry {
  user_full_name: string;
  created_at: string;
}

const props = withDefaults(
  defineProps<{
    history: HistoryEntry[];
  }>(),
  {
    history: () => [],
  },
);

const showModal = ref(false);
const fileInputRef = ref<HTMLInputElement | null>(null);

const getBankExportId = () => {
  const pathname = window.location.pathname;
  const matches = pathname.match(/\/bank_exports\/(\d+)/);
  return matches ? matches[1] : "";
};

const toggleModal = () => {
  showModal.value = !showModal.value;
};

const uploadFile = async (file: File) => {
  if (!file) {
    alert("No file selected");
    return;
  }

  const token = document
    .getElementsByName("csrf-token")[0]
    .getAttribute("content");
  const formData = new FormData();
  formData.append("bank_export[proof_of_payment]", file);

  try {
    await axios.put(
      `/bank_exports/${getBankExportId()}/proof_of_payments`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": token,
        },
      },
    );
    alert("File uploaded successfully");
    window.location.reload();
  } catch (error) {
    console.error("Error during upload:", error);
    alert("Error uploading file");
  }
};

const handleFileUpload = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target?.files && target.files.length > 0) {
    const file = target.files[0];
    uploadFile(file);
  }
};

const triggerFileInput = () => {
  fileInputRef?.value?.click();
};

const downloadProofOfPayment = async () => {
  try {
    const response = await axios.get(
      `/bank_exports/${getBankExportId()}/proof_of_payments`,
      {
        responseType: "blob",
      },
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `proof_of_payment-${getBankExportId()}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error("Error during download:", error);
    alert("Error downloading file");
  }
};

const latestHistoryEntry = computed(() => props.history[0] ?? null);
const historyChangesWithoutLatest = computed(() => props.history.slice(1));
</script>

<template>
  <div>
    <BaseButton
      type="button"
      text="Proof of Payment"
      color="green"
      @click="toggleModal"
    />

    <BaseModal v-model="showModal" title="Proof of Payment">
      <template #content>
        <p class="mb-4 text-sm text-gray-600">
          Edit or download the proof of payment
        </p>
        <input
          type="file"
          ref="fileInputRef"
          @change="handleFileUpload"
          accept=".pdf"
          style="display: none"
        />

        <div class="mb-6 border-b pb-4">
          <div class="flex justify-between items-center">
            <div v-if="latestHistoryEntry">
              <p class="font-medium">{{ latestHistoryEntry.user_full_name }}</p>
              <p class="text-sm text-gray-500">
                {{ latestHistoryEntry.created_at }}
              </p>
            </div>
            <div>
              <BaseButton
                type="button"
                text="Edit"
                color="primary"
                class="mr-4"
                @click="triggerFileInput"
              />
              <BaseButton
                type="button"
                text="Download"
                color="primary"
                @click="downloadProofOfPayment"
              />
            </div>
          </div>
        </div>

        <div v-if="history.length > 1">
          <h3 class="text-sm font-medium mb-2">Modification History</h3>
          <table class="w-full text-sm text-gray-500">
            <tbody>
              <tr
                v-for="(entry, index) in historyChangesWithoutLatest"
                :key="index"
                class="border-b border-gray-200"
              >
                <td class="py-2">{{ entry.user_full_name }}</td>
                <td class="py-2 text-right">{{ entry.created_at }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </BaseModal>
  </div>
</template>
