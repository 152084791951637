export const iconList = {
  add: "M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z",
  arrowDown: "M4 6L8 10L12 6",
  bolt: "m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z",
  close: "M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
  checkCircle: [
    "M7.33325 12.5L10.3333 15.5L17.3333 8.5",
    "M12.3333 22C17.8561 22 22.3333 17.5228 22.3333 12C22.3333 6.47715 17.8561 2 12.3333 2C6.8104 2 2.33325 6.47715 2.33325 12C2.33325 17.5228 6.8104 22 12.3333 22Z",
  ],
  closeCircle: [
    "M9.04772 8.53553L12.5833 12.0711M16.1188 15.6066L12.5833 12.0711M12.5833 12.0711L16.1188 8.53553M12.5833 12.0711L9.04772 15.6066",
    "M12.3333 22C17.8561 22 22.3333 17.5228 22.3333 12C22.3333 6.47715 17.8561 2 12.3333 2C6.8104 2 2.33325 6.47715 2.33325 12C2.33325 17.5228 6.8104 22 12.3333 22Z",
  ],
  download: [
    "M4.5 13.334L12.5 13.334",
    "M8.49984 2.66602V10.666M8.49984 10.666L10.8332 8.33268M8.49984 10.666L6.1665 8.33268",
  ],
  duplicate:
    "M16.5 8.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v8.25A2.25 2.25 0 006 16.5h2.25m8.25-8.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-7.5A2.25 2.25 0 018.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 00-2.25 2.25v6",
  edit: "M14.3632 6.15156L15.8431 4.67157C16.6242 3.89052 17.8905 3.89052 18.6716 4.67157L20.0858 6.08579C20.8668 6.86683 20.8668 8.13316 20.0858 8.91421L18.6058 10.3942M14.3632 6.15156L4.74749 15.7672C4.41542 16.0993 4.21079 16.5376 4.16947 17.0054L3.92738 19.7459C3.87261 20.3659 4.39148 20.8848 5.0115 20.83L7.75191 20.5879C8.21972 20.5466 8.65806 20.3419 8.99013 20.0099L18.6058 10.3942M14.3632 6.15156L18.6058 10.3942",
  favorite:
    "M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z",
  infoCircle: [
    "M12 11.5V16.5",
    "M12 7.51L12.01 7.49889",
    "M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z",
  ],
  list: "M12 7a2 2 0 110-4 2 2 0 010 4zm0 2a2 2 0 110 4 2 2 0 010-4zm0 6a2 2 0 110 4 2 2 0 010-4z",
  numberedListLeft: [
    "M9 5L21 5",
    "M5 7L5 3L3.5 4.5",
    "M5.5 14L3.5 14L5.40471 11.0371C5.46692 10.9403 5.50215 10.8268 5.47709 10.7145C5.41935 10.4557 5.216 10 4.5 10C3.50001 10 3.5 10.8889 3.5 10.8889C3.5 10.8889 3.5 10.8889 3.5 10.8889L3.5 11.1111",
    "M4 19L4.5 19C5.05228 19 5.5 19.4477 5.5 20V20C5.5 20.5523 5.05228 21 4.5 21L3.5 21",
    "M3.5 17L5.5 17L4 19",
    "M9 12L21 12",
    "M9 19L21 19",
  ],
  underline: [
    "M16 5V11C16 13.2091 14.2091 15 12 15V15C9.79086 15 8 13.2091 8 11V5",
    "M6 19L18 19",
  ],
  refundClient: [
    "M6.66667 5.48783C6.1339 4.99604 5.19564 4.65397 4.33333 4.63255M2 10.1545C2.50126 10.7714 3.43326 11.1233 4.33333 11.1532M4.33333 4.63255C3.30735 4.60707 2.38889 5.03551 2.38889 6.20579C2.38889 8.35963 6.66667 7.28271 6.66667 9.43655C6.66667 10.665 5.52813 11.1928 4.33333 11.1532M4.33333 4.63255V3.33398M4.33333 11.1532V12.6673",
    "M8.66667 8.00065H14M14 8.00065L11.44 5.33398M14 8.00065L11.44 10.6673",
  ],
  smallLogoLc:
    "M20 37.1429C29.4678 37.1429 37.1429 29.4678 37.1429 20C37.1429 10.5323 29.4678 2.85717 20 2.85717C10.5323 2.85717 2.85717 10.5323 2.85717 20C2.85717 29.4678 10.5323 37.1429 20 37.1429ZM20 38.0953C29.9937 38.0953 38.0953 29.9937 38.0953 20C38.0953 10.0063 29.9937 1.90479 20 1.90479C10.0063 1.90479 1.90479 10.0063 1.90479 20C1.90479 29.9937 10.0063 38.0953 20 38.0953Z",
  substract: "M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z",
  trash:
    "M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0",
  warning:
    "M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z",
  link: [
    "M14 11.9976C14 9.5059 11.683 7 8.85714 7C8.52241 7 7.41904 7.00001 7.14286 7.00001C4.30254 7.00001 2 9.23752 2 11.9976C2 14.376 3.70973 16.3664 6 16.8714C6.36756 16.9525 6.75006 16.9952 7.14286 16.9952",
    "M10 11.9976C10 14.4893 12.317 16.9952 15.1429 16.9952C15.4776 16.9952 16.581 16.9952 16.8571 16.9952C19.6975 16.9952 22 14.7577 22 11.9976C22 9.6192 20.2903 7.62884 18 7.12383C17.6324 7.04278 17.2499 6.99999 16.8571 6.99999",
  ],
  notification: [
    "M12 5.59998C12 4.46839 11.5786 3.38315 10.8284 2.58299C10.0783 1.78284 9.06087 1.33331 8 1.33331C6.93913 1.33331 5.92172 1.78284 5.17157 2.58299C4.42143 3.38315 4 4.46839 4 5.59998C4 10.5778 2 12 2 12H14C14 12 12 10.5778 12 5.59998Z",
    "M9.15335 14C9.03614 14.2021 8.86791 14.3698 8.6655 14.4864C8.46309 14.6029 8.2336 14.6643 8.00001 14.6643C7.76643 14.6643 7.53694 14.6029 7.33453 14.4864C7.13212 14.3698 6.96389 14.2021 6.84668 14"
  ]
};
