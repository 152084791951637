<script setup lang="ts">
import { computed } from "vue";

const emits = defineEmits(["update:date"]);

type Props = {
  asSchedules: boolean;
  attribute: string;
  date: string;
  disabled: boolean;
  index: number;
  label: string;
  min: string;
  model: string;
  customClassInput: string | null;
};
const props = withDefaults(defineProps<Props>(), {
  asSchedules: true,
  attribute: "",
  date: "",
  disabled: false,
  index: 0,
  label: "",
  min: "",
  model: "",
  customClassInput: null,
});
const changeDate = (e) => {
  const { value } = e.target;

  emits("update:date", value);
};

const id = computed(() => {
  if (props.asSchedules)
    return `${props.model}_schedules_attributes_${props.index}_${props.attribute}`;

  return `${props.model}_${props.attribute}`;
});
const name = computed(() => {
  if (props.asSchedules)
    return `${props.model}[schedules_attributes][${props.index}][${props.attribute}`;

  return `${props.model}[${props.attribute}`;
});
const splitDate = computed(() => {
  const dateCopy = JSON.parse(JSON.stringify(props.date));
  const [year, month, day] = dateCopy.split("-");

  return {
    year,
    month,
    day,
  };
});
</script>

<template>
  <div>
    <legend class="text-sm font-medium text-gray-600 mb-2">
      {{ label }} <abbr title="required">*</abbr>
    </legend>

    <div class="flex items-center h-10">
      <input
        type="date"
        :class="[
          'flex shadow appearance-none border border-gray-300 rounded py-2.5 px-2 focus:outline-none focus:border-blue-500 text-slate-950 leading-4 transition-colors duration-200 ease-in-out date',
          {
            'bg-gray-200 pointer-events-none opacity-40': disabled,
          },
          customClassInput,
        ]"
        :value="date"
        @change="changeDate"
        :min="min"
      />

      <input
        type="hidden"
        :id="`${id}_1i`"
        :name="`${name}(1i)]`"
        autocomplete="off"
        :value="splitDate.year"
      />
      <input
        type="hidden"
        :id="`${id}_2i`"
        :name="`${name}(2i)]`"
        autocomplete="off"
        :value="splitDate.month"
      />
      <input
        type="hidden"
        :id="`${id}_3i`"
        :name="`${name}(3i)]`"
        autocomplete="off"
        :value="splitDate.day"
      />
    </div>
  </div>
</template>
