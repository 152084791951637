<script setup lang="ts">
import { computed, reactive, ref, onMounted } from "vue";
import axios from "axios";

import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseModal from "@/components/BaseModal.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import BaseMultiselect from "@/components/BaseMultiselect.vue";
import BaseNoticeModal from "@/components/BaseNoticeModal.vue";

import type { Booking, Subsidiary } from "@/types";
type BookingT = Booking & {
  subsidiary_id: number;
};

const BASE_URL = import.meta.env.VITE_BASE_URL_DT;
const SHOW_STRIPE_FEATURE = import.meta.env.VITE_SHOW_STRIPE_FEATURE;
const userEmail = ref(window.gon.user?.email || "");
const bookings = ref<BookingT[]>(window.gon.bookings || []);
const subsidiaries = ref<Subsidiary[]>(window.gon.subsidiaries || []);

const showStripeModal = ref(false);
let data = reactive({
  clientEmail: "",
  bookingReference: "",
  serviceDescription: "",
  amountIncludingCreditCardFee: "",
  currency: "",
});
const devises = [
  { value: "EUR", label: "EUR" },
  { value: "USD", label: "USD" },
  { value: "GBP", label: "GBP" },
  { value: "CHF", label: "CHF" },
];

const formatedBookings = computed(() => {
  return bookings.value.map((booking) => {
    return {
      value: booking.reference,
      label: booking.reference,
    };
  });
});
const loading = ref(false);
const noticeVisible = ref(false);
const noticeMessage = ref("");
const allowedSubsidiaries = [1];
const subsidiaryId = ref<number>(1);

const disabledButton = computed(() => {
  return (
    !data.clientEmail ||
    !data.bookingReference ||
    !data.serviceDescription ||
    !data.amountIncludingCreditCardFee ||
    !data.currency ||
    bookingError.value
  );
});

onMounted(() => {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has("subsidiary_id")) {
    subsidiaryId.value = searchParams.get("subsidiary_id")
      ? Number(searchParams.get("subsidiary_id"))
      : 1;
  } else {
    subsidiaryId.value = 1;
  }
});

const stripeLink = ref("");
const generateStripeLink = async () => {
  loading.value = true;

  try {
    const res = await axios.post(`${BASE_URL}/api/stripe-link`, {
      currency: data.currency,
      productName: data.bookingReference,
      description: data.serviceDescription,
      photo:
        "https://cdn.lecollectionist.com/__collectionist__/production/assets/images/lecollectionist-luxury-villas-rentals-holidays.svg",
      amount: data.amountIncludingCreditCardFee,
      email: data.clientEmail,
      salesEmail: userEmail.value,
      destination: "conciergerie",
    });

    stripeLink.value = res.data.url;
    navigator.clipboard.writeText(stripeLink.value);
    noticeVisible.value = true;
    noticeMessage.value = "Stripe link copied to clipboard";
  } catch (error) {
    console.error("Erreur lors de la création du lien de paiement:", error);
    noticeVisible.value = true;
    noticeMessage.value = `Error: ${error}`;
  } finally {
    loading.value = false;
    showStripeModal.value = false;
    data = {
      clientEmail: "",
      bookingReference: "",
      serviceDescription: "",
      amountIncludingCreditCardFee: "",
      currency: "",
    };
  }
};

const selectedBooking = computed(() => {
  return bookings.value.find(
    (booking: BookingT) => booking.reference === data.bookingReference,
  );
});
const selectedSubsidiary = computed(() => {
  return subsidiaries.value.find(
    (s) => s.id === selectedBooking.value?.subsidiary_id,
  );
});

const bookingError = ref(false);

const selectBooking = () => {
  const authorizedSubsidiaries = ["france"];
  if (
    selectedSubsidiary.value &&
    !authorizedSubsidiaries.includes(selectedSubsidiary.value.identifier)
  ) {
    bookingError.value = true;
  } else {
    bookingError.value = false;
  }
};
</script>

<template>
  <div v-if="SHOW_STRIPE_FEATURE">
    <BaseButton
      v-if="allowedSubsidiaries.includes(subsidiaryId)"
      @click="showStripeModal = true"
      text="Create a stripe link"
    >
      <template #icon>
        <BaseIcon name="bolt" class="mr-1" />
      </template>
    </BaseButton>

    <BaseModal
      v-model="showStripeModal"
      title="Stripe link creation"
      hasFooter
      hasHeader
    >
      <template #content>
        <div
          class="bg-gray-200 px-4 p-3 text-md grid grid-cols-24 text-gray-700"
        >
          <BaseIcon
            size="xs"
            aria-hidden="true"
            name="infoCircle"
            class="col-span-2"
          />
          <div class="col-span-22">
            <p class="text-xs">
              This link should only be used
              <strong>for Immo France subsidiary</strong>.<br />
              For AGV subsidiary, stripe links are automatically added to
              invoices.
            </p>
          </div>
        </div>

        <div>
          <BaseInput required label="Client email" v-model="data.clientEmail" />

          <div class="pt-4 relative">
            <label class="mb-2 block label">
              Booking reference
              <span>*</span>
            </label>
            <BaseMultiselect
              v-model="data.bookingReference"
              placeholder="Select a booking reference"
              searchable
              :options="formatedBookings"
              @change="selectBooking"
            />
            <p v-if="bookingError" class="pt-1 text-xs text-red-400">
              For
              <span class="font-bold"
                >{{ selectedSubsidiary?.name }} subsidiary</span
              >, stripe links are automatically added to invoices.
            </p>
          </div>

          <BaseInput
            label="Service description"
            required
            v-model="data.serviceDescription"
          />

          <div class="grid grid-cols-12 gap-4 pt-4">
            <BaseInput
              type="number"
              decimal
              required
              class-wrapper="col-span-8"
              label="Amount including credit card fee"
              v-model="data.amountIncludingCreditCardFee"
            />
            <div class="col-span-4">
              <label class="mb-2 block label">
                Currency
                <span>*</span>
              </label>
              <BaseMultiselect
                v-model="data.currency"
                placeholder="Select"
                :options="devises"
              />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="mt-4">
          <BaseButton
            color="secondary"
            text="Cancel"
            size="md"
            @click="showStripeModal = false"
            class="mr-4"
          />
          <BaseButton
            text="Generate"
            size="md"
            :disabled="disabledButton"
            :loader="loading"
            @click="generateStripeLink"
          />
        </div>
      </template>
    </BaseModal>

    <BaseNoticeModal
      :visible="noticeVisible"
      :message="noticeMessage"
      :delay="5000"
      @update:visible="(val) => (noticeVisible = val)"
    />
  </div>
</template>
