<script setup lang="ts">
import { computed } from "vue";

type Props = {
  isSelected: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  isSelected: false,
});

const colors = computed(() => ({
  rect: props.isSelected ? "#033535" : "white",
  path: props.isSelected ? "white" : "#757575",
}));
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <rect width="40" height="40" rx="4" :fill="colors.rect" />
    <path
      d="M11 17.5L20 12L29 17.5"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13 28H27"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18 17H22"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14 25L14 20"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18 25L18 20"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22 25L22 20"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26 25L26 20"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
