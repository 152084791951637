<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  paidBookingBalance: {
    type: String,
    required: true
  },
  totalBookingsClientPrice: {
    type: String,
    required: true
  },
  totalBookingsCommission: {
    type: String,
    required: true
  },
  totalPayInsAmount: {
    type: String,
    required: true
  }
});

const floatBalance = computed(() => {
  return parseFloat(props.paidBookingBalance);
});

</script>

<template>
  <div class="space-y-20 mt-4">
    <div class="grid grid-cols-3 gap-4">
      <div :class="['rounded-lg p-4 h-full flex justify-between items-center', { 'bg-[#CF364C] bg-opacity-5': floatBalance < 0, 'bg-[#15803D] bg-opacity-5': floatBalance >= 0 }]">
        <div class="text-sm text-gray-600">
          <dt class="font-medium text-base text-primary-800">Client invoice status:</dt>
          <dd class="mt-2 text-md">Balance between Conciergerie Bookings and Pay-in</dd>
        </div>
        <dd :class="['text-lg font-semibold', { 'text-[#CF364C]': floatBalance < 0, 'text-[#15803D]': floatBalance >= 0 }]">
          <span class="devise">{{ floatBalance > 0 ? '+' : '' }}{{ paidBookingBalance }}</span>
        </dd>
      </div>

      <div class="bg-slate-50 rounded-lg p-4 h-full">
        <dl class="text-sm text-gray-600">
          <dt class="font-medium text-primary-800 text-base">Conciergerie Bookings</dt>
          <div class="flex items-center mt-2">
            <dd class="mr-4 text-md">
              Total client price:
              <span class="devise font-medium text-primary-800 text-base">{{ totalBookingsClientPrice }}</span>
            </dd>
            <dd class="text-md">
              Total Commission:
              <span class="devise font-medium text-primary-800 text-base">{{ totalBookingsCommission }}</span>
            </dd>
          </div>
        </dl>
      </div>

      <div class="bg-slate-50 rounded-lg p-4 h-full">
        <dl class="text-sm text-gray-600">
          <dt class="font-medium text-primary-800 text-base">Pay-ins</dt>
          <dd class="mt-2 text-gray-600 text-md">
            Total incoming transactions without rejection:
            <span class="devise font-medium text-primary-800 text-base">{{ totalPayInsAmount }}</span>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

