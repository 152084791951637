<script setup lang="ts">
import { computed, ref } from "vue";
import BaseMultiselect from "@/components/BaseMultiselect.vue";
import queryParams from "@/helpers/queryParams";

type Destinations = {
  id: string;
  name: string;
};

type Categories = {
  id: string;
  name: string;
};

type SubCategories = {
  id: string;
  name: string;
};

const destinations = window.gon?.destinations
  ? ref(window.gon?.destinations)
  : ref([]);
const categories = window.gon?.categories
  ? ref(window.gon?.categories)
  : ref([]);
const subCategories = window.gon?.subCategories
  ? ref(window.gon?.subCategories)
  : ref([]);
const params = queryParams();
const selectedDestinationId = ref<string | null>(params.get("destination_id"));
const selectedCategoryId = ref<string | null>(params.get("category_id"));
const selectedSubCategoryId = ref<string | null>(params.get("subcategory_id"));

const formatDestinations = computed(() => {
  return destinations.value.map((desti: Destinations) => {
    return {
      value: desti[0],
      label: desti[1].en,
    };
  });
});

const formatCategories = computed(() => {
  return categories.value.map((cat: Categories) => {
    return {
      value: cat[0],
      label: cat[1],
    };
  });
});
const formatSubCategories = computed(() => {
  return subCategories.value.map((subCat: SubCategories) => {
    return {
      value: subCat.id,
      label: subCat.name,
    };
  });
});

const changeParams = (type, e) => {
  if (e !== null && e !== undefined) {
    if (type === "category_id") {
      params.remove("subcategory_id");
    }
    params.set(type, e);
  }
};
const deleteParams = (type) => {
  params.remove(type);
};
</script>

<template>
  <BaseMultiselect
    v-model="selectedDestinationId"
    :searchable="true"
    :placeholder="'Destination'"
    :options="formatDestinations"
    attribute="destination_id"
    model="destinations"
    @change="changeParams('destination_id', $event)"
    @close="deleteParams('destination_id')"
  />
  <BaseMultiselect
    v-model="selectedCategoryId"
    :searchable="true"
    :placeholder="'Categories'"
    :options="formatCategories"
    attribute="category_id"
    model="categories"
    @change="changeParams('category_id', $event)"
    @close="deleteParams('category_id')"
  />
  <BaseMultiselect
    v-model="selectedSubCategoryId"
    :searchable="true"
    :placeholder="'Sub-categories'"
    :options="formatSubCategories"
    attribute="subCategory_id"
    model="subCategories"
    @change="changeParams('subcategory_id', $event)"
    @close="deleteParams('subcategory_id')"
  />
</template>

<style>
.serviceFilter > div {
  @apply w-full px-1;
}
</style>
