<script setup lang="ts">
import { ref, computed } from "vue";
import { formatPrice } from "@/helpers/price";

import BaseIcon from "@/components/BaseIcon.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseForm from "@/components/BaseForm.vue";

import type { GON } from "@/types";

declare global {
  interface Window {
    gon: GON;
  }
}

interface ServicesAttributes {
  clientPriceWithVat: number;
  id: number;
  lcCommissionWithVat: number;
  name: string;
  newTotalClientPrice: number;
  newTotalCommission: number;
  selectedServicesId: Array<number>;
}

const emits = defineEmits(["update:model-value"]);

defineProps<{
  modelValue: boolean;
}>();

const token = ref(
  document.getElementsByName("csrf-token")[0].getAttribute("content"),
);

const bookings = ref<GON | undefined>(window.gon);
const allBookingServicesAttributes = window.gon
  ? ref(window.gon.allBookingServicesAttributes)
  : ref([]);
const additionalServices = ref<ServicesAttributes[]>([
  ...allBookingServicesAttributes.value.map((x) => ({
    id: x.id,
    name: x.name,
    selectedServicesId: [x.id],
    clientPriceWithVat: x.client_price_with_vat,
    lcCommissionWithVat: x.lc_commission_with_vat,
    newTotalClientPrice: 0,
    newTotalCommission: x.lc_commission_with_vat,
  })),
]);

const filterAdditionalServices = computed(() => {
  return additionalServices.value.filter(
    (service) =>
      service.clientPriceWithVat > 0 || service.lcCommissionWithVat > 0,
  );
});
const calculateCommissionDifference = (service: ServicesAttributes) => {
  return (service.lcCommissionWithVat - service.newTotalCommission).toFixed(2);
};

const handleClose = () => {
  emits("update:model-value", false);
};

const onProcessRefund = () => {
  const form = document.getElementById(
    "credit_notes",
  ) as HTMLFormElement | null;
  if (form !== null) {
    form.submit();
  }
  emits("update:model-value", false);
};

const totalRefund = computed(() => {
  const arr = filterAdditionalServices.value.map((x) => x.newTotalClientPrice);

  return arr.reduce((acc, val) => acc + Number(val), 0);
});

const disabledButton = computed(() => {
  return filterAdditionalServices.value.every(
    ({
      newTotalClientPrice,
      clientPriceWithVat,
      lcCommissionWithVat,
      newTotalCommission,
    }) => {
      return (
        Number(newTotalClientPrice) > Number(clientPriceWithVat) ||
        Number(newTotalCommission) > Number(lcCommissionWithVat) ||
        (Number(newTotalClientPrice) === 0 &&
          Number(newTotalCommission) === Number(lcCommissionWithVat))
      );
    },
  );
});
</script>

<template>
  <div v-if="modelValue">
    <div
      class="bg-slate-800 bg-opacity-40 z-10 fixed top-0 left-0 w-full h-full"
      @click="handleClose"
    />
    <div
      class="border border-gray-200 z-20 bg-white fixed py-8 rounded-lg transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[645px] min-h-[555px] max-h-full overflow-hidden"
    >
      <button
        type="button"
        class="w-10 h-10 absolute right-0 top-1"
        @click="handleClose"
      >
        <BaseIcon name="close" />
      </button>
      <BaseForm
        :id="`credit_notes`"
        :action="`/bookings/${bookings?.booking?.id}/credit_notes`"
        :value="token"
        method="post"
      >
        <div class="max-h-[calc(100vh-100px)] overflow-auto">
          <div class="mb-5 px-6">
            <h2
              class="text-xl font-bold leading-5 text-gray-900 sm:text-xl sm:truncate"
            >
              Refund Client
            </h2>
            <p class="text-base">
              Select the service(s) to refund, the amount and the updated
              commission.
            </p>
          </div>

          <div
            v-for="(service, index) in filterAdditionalServices"
            :key="service.id"
            class="border-b border-gray-300 pb-4 mb-4 px-6"
          >
            <h2 class="font-bold">
              {{ service.name }}
            </h2>

            <input
              :id="`credit_note[credit_note_booking_services_attributes]_booking_service_id_${index}_${service.id}`"
              class="hidden"
              :name="`credit_note[credit_note_booking_services_attributes][${index}][booking_service_id]`"
              :value="service.id"
            />

            <div class="grid grid-cols-2 gap-4">
              <div>
                <BaseInput
                  :id="`booking_amount_${index}_refunded_with_vat`"
                  v-model="service.newTotalClientPrice"
                  decimal
                  label="Amount to refund"
                  type="number"
                  min="0"
                  :name="`credit_note[credit_note_booking_services_attributes][${index}][amount_refunded_with_vat]`"
                />
                <p
                  v-show="
                    Number(service.newTotalClientPrice) >
                    Number(service.clientPriceWithVat)
                  "
                  class="mt-1 text-xs text-red-400"
                >
                  The amount to refund cannot be greater than the client price
                </p>
              </div>
              <div>
                <BaseInput
                  :id="`booking_commission_${index}_refunded_with_vat`"
                  v-model="service.newTotalCommission"
                  decimal
                  label="Updated commission"
                  type="number"
                  min="0"
                />
                <p
                  v-show="
                    service.newTotalCommission > 0 &&
                    service.newTotalCommission > service.lcCommissionWithVat
                  "
                  class="mt-1 text-xs text-red-400"
                >
                  The updated commission cannot be greater than the commission
                </p>
              </div>
              <input
                type="hidden"
                :value="calculateCommissionDifference(service)"
                :name="`credit_note[credit_note_booking_services_attributes][${index}][commission_refunded_with_vat]`"
              />
            </div>
            <div class="relative overflow-x-auto mt-2">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500"
              >
                <thead class="text-xs text-gray-700 uppercase">
                  <tr>
                    <th scope="col" class="px-2 py-3"></th>
                    <th scope="col" class="px-1 py-3 text-left">
                      Client Price
                    </th>
                    <th scope="col" class="px-1 py-3 text-left">Commission</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white border-b">
                    <th
                      scope="row"
                      class="px-2 py-2 font-medium text-gray-900 whitespace-nowrap"
                    >
                      Before refund
                    </th>
                    <td class="px-1 line-through">
                      {{
                        formatPrice({
                          price: service.clientPriceWithVat,
                          currency: "EUR",
                        })
                      }}
                    </td>
                    <td class="px-1 line-through">
                      {{
                        formatPrice({
                          price: service.lcCommissionWithVat,
                          currency: "EUR",
                        })
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      class="px-2 py-2 font-medium text-gray-900 whitespace-nowrap"
                    >
                      After refund
                    </th>
                    <td
                      :class="[
                        'px-1',
                        {
                          'text-red-500':
                            service.clientPriceWithVat -
                              service.newTotalClientPrice <
                            0,
                        },
                      ]"
                    >
                      {{
                        formatPrice({
                          price:
                            service.clientPriceWithVat -
                            service.newTotalClientPrice,
                          currency: "EUR",
                        })
                      }}
                    </td>
                    <td
                      :class="[
                        'px-1',
                        {
                          'text-red-500':
                            service.newTotalCommission > 0 &&
                            service.newTotalCommission >
                              service.lcCommissionWithVat,
                        },
                      ]"
                    >
                      {{
                        formatPrice({
                          price: service.newTotalCommission,
                          currency: "EUR",
                        })
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="w-full px-4 pb-4">
            <label class="required text-sm font-medium text-gray-600">
              Comment
            </label>
            <textarea
              class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out text optional"
              name="credit_note[comment]"
            />
          </div>
        </div>

        <div
          class="bg-white border-t border-gray-300 flex justify-end pt-3 px-4"
        >
          <button
            class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-primary-700 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
            type="button"
            @click="handleClose"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="inline-flex items-center px-3 py-2 ml-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
            :disabled="disabledButton"
            @click="onProcessRefund"
          >
            <span>Process to refund</span>
            <span v-if="totalRefund > 0" class="ml-1">{{
              formatPrice({
                price: totalRefund,
                currency: "EUR",
              })
            }}</span>
          </button>
        </div>
      </BaseForm>
    </div>
  </div>
</template>
