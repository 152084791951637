<script lang="ts" setup>
import { computed, reactive, ref, onMounted } from 'vue'

import { addDate, format, getDateDiff } from '@/helpers/dates'
import { formatPrice } from '@/helpers/price'

import { useInputValidation } from '@/composable/useInputValidation'

import BaseCounter from '@/components/BaseCounter.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import BaseMultiselect from '@/components/BaseMultiselect.vue'
import BookingServiceDate from '@/views/Bookings/Services/components/BookingServiceDate.vue'
import { Calculator } from '@/components/Services'

import type {
  Booking,
  BookingService,
  Contract,
  GON,
  Price,
  Schedule,
  Service,
  ThirdParty,
} from '@/types'
declare global {
  interface Window {
    gon: GON
  }
}

const token = ref(
  document.getElementsByName('csrf-token')[0].getAttribute('content'),
)

const pricePerUnitId = ref(null)
const pricePerUnit = ref(0)
const asSchedules = computed(() => {
  return Boolean(gon.schedules?.length && gon.schedules[0].id)
})
const { gon } = window

const booking = ref<Booking>(gon.booking)
const bookingService = ref<BookingService>(gon.bookingService)
const service = ref<Service>(gon.service)
const contract = ref<Contract>(gon.contract)
const prices = ref<Price[]>(gon.prices)
const thirdParty = ref<ThirdParty>(gon.thirdParty)

const validations = {
  serviceName: useInputValidation('Service Name'),
  description: useInputValidation('Description'),
}

const validateInput = (field, value) => {
  if (field === 'serviceName') {
    validations.serviceName.validate(value)
  } else if (field === 'description') {
    validations.description.validate(value)
  }
}
const dateModel: Schedule = reactive({
  number_of_hours: 1,
  number_of_minutes: 0,
  end_date: contract.value.check_out_date,
  start_date: contract.value.check_in_date,
  start_time: new Date(),
  time: format(new Date(), 'HH:mm'),
})
const schedules = ref<Schedule[]>(
  asSchedules.value ? gon.schedules : [dateModel],
)
const schedulesToDestroy = ref([])
const isOnSitePayment = service.value?.on_site_payment
const isFullCommissionThirdParty = thirdParty.value?.full_commission
const invoicingType = ref(thirdParty.value.invoicing_type)

const disabledCalculatorFields = {
  targetCommission: isFullCommissionThirdParty,
  supplierDiscount: isFullCommissionThirdParty,
}

const languages = ref([
  {
    label: 'Français',
    value: 'fr',
  },
  {
    label: 'English',
    value: 'en',
  },
])
const pricingType = computed(() => {
  if (currentService.value.pricing_type === 'duration') {
    return currentService.value.duration
  }

  return currentService.value.pricing_type
})
const pricingTypeText = computed(() => {
  if (pricingType.value === 'number_of_days') {
    return 'days'
  } else if (pricingType.value === 'number_of_weeks') {
    return 'weeks'
  }

  return 'units'
})
const formatPrices = computed(() => {
  return prices.value.map((price) => {
    const priceWithCurrency = formatPrice({
      price: price.supplier_public_price_without_vat,
      currency: 'EUR',
    })
    const p = `${priceWithCurrency} (${price.start_date} to ${price.end_date})`

    return {
      label: p,
      value: price.id,
      price: priceWithCurrency,
    }
  })
})
const isUpdate = computed(() => {
  return Boolean(bookingService.value?.id)
})
const currentService = computed(() => {
  return bookingService?.value.id ? bookingService.value : service?.value
})
const action = computed(() => {
  if (isUpdate.value) {
    return `/bookings/${booking.value.id}/booking_services/${bookingService.value.id}`
  }

  return `/bookings/${booking.value.id}/booking_services`
})

const currentQuantity = computed(() => {
  if (pricingType.value === 'number_of_weeks') {
    return data.weekDuration || 1
  } else if (pricingType.value === 'number_of_hours') {
    const totalOfHours = schedules.value.reduce((acc, schedule) => {
      return acc + schedule.number_of_hours + schedule.number_of_minutes / 60
    }, 0)

    if (data.dateRange) {
      const day = getDateDiff(dateModel.start_date, dateModel.end_date, 'days')

      return day * totalOfHours
    }

    return totalOfHours || 1
  } else if (pricingType.value === 'per_unit' && data.dateRange) {
    return 1
  }

  return data.quantity
})

const transformSchedules = () => {
  schedules.value = schedules.value.map((schedule) => {
    const hourMinute = format(schedule.start_time, 'HH:mm')

    return {
      ...schedule,
      time: hourMinute,
    }
  })
}
transformSchedules()

const calculator = ref(null)
const data = reactive({
  bookingServiceId: service.value.id,
  dateRange: currentService.value.date_range || false,
  language: 'fr',
  quantity: currentService.value.quantity || 1,
  serviceContractId: contract.value.id,
  serviceName: currentService.value.name,
  serviceDescription: currentService.value.description,
  status: isOnSitePayment ? 'to_reserve' : 'pending_client',
  weekDuration: currentService.value.quantity || 1,
})
let dataForCalculator = reactive({
  clientPriceIncl: bookingService.value?.client_price_with_vat || 0,
  clientPriceExcl: bookingService.value?.client_price_without_vat || 0,
  supplierPriceIncVat: bookingService.value?.supplier_price_with_vat || 0,
  supplierPriceExlVat: bookingService.value?.supplier_price_without_vat || 0,
  supplierVatRate: isFullCommissionThirdParty
    ? 0
    : bookingService.value?.supplier_vat_rate || 0,
  lcTargetCommissionRate: isFullCommissionThirdParty
    ? 0
    : bookingService.value?.lc_target_commission_rate || 0,
  supplierDiscountRate: isFullCommissionThirdParty
    ? 0
    : bookingService.value.supplier_discount_rate || 0,
  supplierPublicPriceWithoutVat:
    bookingService.value?.supplier_public_price_without_vat,
  supplierPublicPriceIncVat:
    bookingService.value?.supplier_public_price_with_vat || 0,
  lcCommissionWithVat: bookingService.value?.lc_commission_with_vat || 0,
  lcCommissionWithoutVat: bookingService.value?.lc_commission_without_vat || 0,
  retroComIncVat: bookingService.value?.retro_commission_with_vat || 0,
  retroComExlVat: bookingService.value?.retro_commission_without_vat || 0,
})

const requiredFields = [
  'clientPriceExcl',
  'clientPriceIncl',
  'supplierPriceExlVat',
  'supplierPriceIncVat',
  'supplierPublicPriceIncVat',
  'supplierPublicPriceWithoutVat',
  'supplierVatRate',
  'lcTargetCommissionRate',
  'supplierDiscountRate',
]

const checkCommissionRateAndDiscountRate = computed(() => {
  return (
    dataForCalculator.lcTargetCommissionRate <
    dataForCalculator.supplierDiscountRate
  )
})

const disabledButton = computed(() => {
  return !requiredFields.every((field) => {
    const value = dataForCalculator[field]
    return !isNaN(value) && Number.isFinite(parseFloat(value))
  })
})

const disabledCalculator = computed(() => {
  return (
    formatPrices.value.length === 0 || !thirdParty.value.commission_vat_regime
  )
})

// Calcul
const calculPublicPriceWithoutVat = () => {
  if (pricePerUnit.value) {
    dataForCalculator.supplierPublicPriceWithoutVat =
      pricePerUnit.value * currentQuantity.value

    calculator.value.calculate()
  }
}
const toggleDateRange = () => {
  if (pricingType.value !== 'number_of_weeks') {
    data.dateRange = !data.dateRange

    if (
      data.dateRange &&
      (pricingType.value === 'number_of_days' ||
        pricingType.value === 'number_of_hours')
    ) {
      schedules.value = [dateModel]
      data.quantity = getDateDiff(
        dateModel.start_date,
        dateModel.end_date,
        'days',
      )
    } else if (data.dateRange) {
      schedules.value = [dateModel]
      data.quantity = 1
    } else {
      schedules.value = [dateModel]
      data.quantity = 1
    }
  }

  calculPublicPriceWithoutVat()
}
const updateStartDate = (date: string) => {
  if (
    data.dateRange &&
    (pricingType.value === 'number_of_days' ||
      pricingType.value === 'number_of_hours')
  ) {
    const diff = getDateDiff(date, dateModel.end_date, 'days')

    data.quantity = diff
    calculPublicPriceWithoutVat()
  } else if (pricingType.value === 'number_of_weeks') {
    changeWeekDuration(data.weekDuration)
  } else if (pricingType.value === 'number_of_hours' && data.dateRange) {
    calculPublicPriceWithoutVat()
  }
}
const updateEndDate = (date: string) => {
  if (
    data.dateRange &&
    (pricingType.value === 'number_of_days' ||
      pricingType.value === 'number_of_hours')
  ) {
    const diff = getDateDiff(dateModel.start_date, date, 'days')

    data.quantity = diff
    calculPublicPriceWithoutVat()
  }
}
const deleteSchedule = (scheduleId: number) => {
  if (data.quantity > 0) {
    if (scheduleId) {
      schedulesToDestroy.value.push(scheduleId)
    } else {
      schedules.value.pop()
    }

    data.quantity -= 1
    schedules.value.pop()
  }
}
const incrementQuantity = () => {
  const { start_time, start_date, time } = schedules.value[data.quantity - 1]
  const newDateModel = {
    ...dateModel,
    start_date,
    start_time,
    time,
  }

  data.quantity += 1
  schedules.value.push({ ...newDateModel })
  calculPublicPriceWithoutVat()
}
const changePricePerUnit = (priceId: number) => {
  if (priceId) {
    const price = prices.value.find((x) => x.id === priceId)

    pricePerUnit.value = price.supplier_public_price_without_vat
    dataForCalculator.supplierVatRate = isFullCommissionThirdParty
      ? 0
      : price?.supplier_vat_rate || 0
    if (bookingService.value.id == null) {
      dataForCalculator.lcTargetCommissionRate = isFullCommissionThirdParty
        ? 0
        : price?.lc_target_commission_rate || 0
      dataForCalculator.supplierDiscountRate = isFullCommissionThirdParty
        ? 0
        : price?.supplier_discount_rate || 0
    }

    calculPublicPriceWithoutVat()
  }
}
const changeWeekDuration = (count: number) => {
  data.weekDuration = count
  data.quantity = count

  const newEndDate = addDate(
    schedules.value[0].start_date,
    count * 7 + 1,
    'day',
  )
  schedules.value[0].end_date = format(newEndDate, 'YYYY-MM-DD')
  calculPublicPriceWithoutVat()
}
const updateTotalHourDuration = () => {
  calculPublicPriceWithoutVat()
}

onMounted(() => {
  // Manage number of weeks case
  if (pricingType.value === 'number_of_weeks') {
    if (isUpdate.value) {
      changeWeekDuration(data.weekDuration)
    } else {
      data.dateRange = true
      changeWeekDuration(1)
    }
  }

  // If number of hours + date range
  if (
    asSchedules.value &&
    pricingType.value === 'number_of_hours' &&
    data.dateRange
  ) {
    dateModel.start_date = schedules.value[0].start_date
    dateModel.end_date = schedules.value[0].end_date
  }

  if (isUpdate.value) {
    const supplierPublicPriceWithoutVat =
      bookingService.value.supplier_public_price_without_vat
    const unitSupplierPublicPriceWithoutVat =
      supplierPublicPriceWithoutVat / currentQuantity.value
    const unitSupplierPublicPriceWithoutVatFixed = Number(
      unitSupplierPublicPriceWithoutVat.toFixed(1),
    )

    if (
      prices.value.some(
        (x) =>
          x.supplier_public_price_without_vat ===
          unitSupplierPublicPriceWithoutVatFixed,
      )
    ) {
      pricePerUnitId.value = prices.value.find(
        (x) =>
          x.supplier_public_price_without_vat ===
          unitSupplierPublicPriceWithoutVatFixed,
      ).id
    }
  } else if (prices.value.length === 1) {
    pricePerUnitId.value = prices.value[0].id
    changePricePerUnit(pricePerUnitId.value)
  }
})
</script>

<template>
  <p class="text-gray-500 font-medium mt-2">
    Dates: {{ contract.check_in_date }} - {{ contract.check_out_date }}
  </p>
  <form class="my-8" :action="action" accept-charset="UTF-8" method="post">
    <input
      v-if="isUpdate"
      type="hidden"
      name="_method"
      value="patch"
      autocomplete="off"
    />
    <input
      type="hidden"
      name="authenticity_token"
      autocomplete="off"
      :value="token"
    />
    <input
      type="hidden"
      name="booking_service[status]"
      id="booking_service_status"
      :value="data.status"
    />

    <div class="flex mb-4">
      <div class="w-[40%]">
        <h1 class="text-xl font-bold">Information</h1>
        <span class="text-gray-600">
          Complete all information about the duration of the service and number
          of units.
        </span>
      </div>
      <div class="w-[60%]">
        <div
          class="mb-4 flex items-start boolean optional booking_service_date_range"
          @click="toggleDateRange"
        >
          <div class="flex items-center h-5">
            <input
              class="hidden"
              name="booking_service[date_range]"
              id="booking_service_date_range"
              :value="data.dateRange"
            />

            <input
              :class="[
                'focus:ring-2 focus:ring-primary-500:focus ring-offset-2 h-4 w-4 text-primary-600 border-gray-300 rounded',
                {
                  'pointer-events-none bg-gray-200':
                    pricingType === 'number_of_weeks',
                },
              ]"
              type="checkbox"
              :value="data.dateRange"
              :checked="data.dateRange"
            />
          </div>

          <div class="ml-3 text-sm">
            <label
              @click="toggleDateRange"
              class="block boolean optional text-sm font-medium text-gray-600"
              for="booking_service_date_range"
            >
              Date range
            </label>
          </div>
        </div>
        <div class="mb-4 hidden booking_service_contract_id">
          <input
            v-model="data.serviceContractId"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out hidden"
            autocomplete="off"
            type="hidden"
            name="booking_service[contract_id]"
            id="booking_service_contract_id"
          />
        </div>
        <div class="mb-4 hidden booking_service_service_id">
          <input
            v-model="data.bookingServiceId"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out hidden"
            autocomplete="off"
            type="hidden"
            name="booking_service[service_id]"
            id="booking_service_service_id"
          />
        </div>

        <input
          :value="currentService.pricing_type"
          autocomplete="off"
          class="hidden"
          type="hidden"
          name="booking_service[pricing_type]"
          id="booking_service_pricing_type"
        />
        <input
          v-if="pricingType !== 'per_unit'"
          autocomplete="off"
          class="hidden"
          type="hidden"
          name="booking_service[duration]"
          id="booking_service_duration"
          :value="pricingType"
        />

        <!-- Base counter for number of weeks -->
        <template v-if="pricingType === 'number_of_weeks'">
          <BaseCounter
            :count="data.weekDuration"
            class="mb-4"
            label="Number of week"
            @update:count="changeWeekDuration"
          >
            <template #after>w</template>
          </BaseCounter>
          <input
            :value="data.weekDuration"
            class="hidden"
            type="number"
            step="1"
            name="booking_service[quantity]"
            id="booking_service_quantity"
          />
        </template>

        <div class="mb-4 integer required booking_service_quantity" v-else>
          <label
            class="block integer required text-sm font-medium text-gray-600"
            for="booking_service_quantity"
          >
            <span class="mr-2">Number of {{ pricingTypeText }}:</span>

            <span class="text-base font-medium">
              {{ data.quantity }}
            </span>
          </label>

          <div
            :class="[
              'flex items-center mt-2',
              {
                'opacity-40': data.dateRange,
              },
            ]"
          >
            <input
              :value="data.quantity"
              class="hidden"
              type="number"
              step="1"
              name="booking_service[quantity]"
              id="booking_service_quantity"
            />
          </div>
        </div>

        <div id="fieldsetContainer">
          <fieldset
            v-for="(date, index) in schedules"
            :id="index.toString()"
            :key="date.id"
            class="relative"
          >
            <BookingServiceDate
              model="booking_service"
              :date-id="date.id"
              :date-range="data.dateRange"
              :index="index"
              :pricing-type="pricingType"
              v-model:end-date="date.end_date"
              v-model:number-of-hours="date.number_of_hours"
              v-model:number-of-minutes="date.number_of_minutes"
              v-model:start-date="date.start_date"
              v-model:time="date.time"
              @delete-schedule="deleteSchedule"
              @update:end-date="updateEndDate"
              @update:total-duration="updateTotalHourDuration"
              @update:start-date="updateStartDate"
            />

            <input
              v-if="date.id"
              autocomplete="off"
              type="hidden"
              :name="`booking_service[schedules_attributes][${index}][id]`"
              :id="`booking_service_schedules_attributes_${index}_id`"
              :value="date.id"
            />
          </fieldset>

          <div
            class="hidden"
            v-for="(scheduleToDestroy, scheduleIndex) in schedulesToDestroy"
            :key="scheduleToDestroy"
          >
            <div>
              <div>
                <input
                  :name="`booking_service[schedules_attributes][${scheduleIndex}][_destroy]`"
                  type="hidden"
                  autocomplete="off"
                  value="0"
                />
                <input
                  type="checkbox"
                  :name="`booking_service[schedules_attributes][${scheduleIndex}][_destroy]`"
                  :id="`booking_service_schedules_attributes_${scheduleIndex}__destroy`"
                  value="1"
                  :checked="true"
                />
              </div>
              <div>
                <label
                  class="block boolean optional text-sm font-medium text-gray-600"
                  :for="`booking_service_schedules_attributes_${scheduleIndex}__destroy`"
                >
                  Remove
                </label>
              </div>
            </div>
          </div>

          <button
            v-if="pricingType !== 'number_of_weeks'"
            type="button"
            :disabled="data.dateRange"
            @click="incrementQuantity"
            class="border flex items-center justify-center bg-primary-100 text-primary-700 hover:bg-primary-500 hover:text-white duration-300 rounded-md px-3 py-2 disabled:bg-gray-200 disabled:text-white"
          >
            <span class="mr-2">Add</span>
            <BaseIcon name="add" />
          </button>
        </div>
      </div>
    </div>

    <div class="flex mb-4">
      <div class="w-[40%]">
        <h1 class="text-xl font-bold">Pricing</h1>
        <span class="text-gray-600">
          All pricing information (VAT, model commission, invoicing type etc...)
        </span>
      </div>
      <div class="w-[60%]">
        <Calculator
          v-model:data="dataForCalculator"
          :disabled="!!pricePerUnitId || isUpdate"
          :errors="{}"
          :disabledFields="disabledCalculatorFields"
          :is-full-commission-third-party="isFullCommissionThirdParty"
          :is-on-site-payment="isOnSitePayment"
          :is-update="isUpdate"
          :invoicing-type="invoicingType"
          class="mt-6 w-[800px]"
          form-id="booking_service"
          ref="calculator"
        >
          <template #header>
            <div class="mb-6 h-28">
              <p class="text-sm font-medium text-gray-600 mb-2">
                Price per unit (Excl. VAT)
              </p>
              <BaseMultiselect
                v-model="pricePerUnitId"
                :options="formatPrices"
                :disabled="disabledCalculator"
                :searchable="true"
                attribute=""
                model=""
                @change="changePricePerUnit"
              />
              <span v-if="formatPrices.length === 0" class="mt-4 text-sm">
                Please
                <a
                  :href="`/third_parties/${thirdParty.id}/services/${service.id}`"
                  class="text-primary-600 underline"
                  >add prices associated with this service</a
                >
                to continue the booking.
              </span>
              <span
                v-if="!thirdParty.commission_vat_regime"
                class="mt-4 text-sm"
              >
                Please
                <a
                  :href="`/third_parties/${thirdParty.id}/edit?kind=provider`"
                  class="text-primary-600 underline"
                  >add a VAT commission regime to this third party</a
                >
                to continue the booking.
              </span>
            </div>
          </template>
        </Calculator>
      </div>
    </div>

    <div class="flex mb-4">
      <div class="w-[40%]">
        <h1 class="text-xl font-bold">Service description</h1>
        <span class="text-gray-600">
          Add detailed description of the service.
        </span>
      </div>
      <div class="w-[60%]">
        <div class="string required booking_service_name">
          <label
            class="block string required text-sm font-medium text-gray-600"
            for="booking_service_name"
          >
            Name <abbr title="required">*</abbr>
          </label>
          <input
            v-model="data.serviceName"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string required"
            type="text"
            name="booking_service[name]"
            id="booking_service_name"
            @input="validateInput('serviceName', data.serviceName)"
          />
          <span
            v-if="validations.serviceName.state.hasError"
            class="text-red-500"
            >{{ validations.serviceName.state.errorMessage }}</span
          >
        </div>

        <label for="Language_description" class="mb-4 block" v-show="!isUpdate">
          <p
            class="mt-4 block text optional text-sm font-medium text-gray-600 mb-2"
          >
            Language description
          </p>

          <BaseMultiselect v-model="data.language" :options="languages" />

          <input
            class="hidden"
            id="language_description"
            name="language_description"
            :value="data.language"
          />
        </label>

        <div
          :class="[
            'mb-4 text optional',
            {
              'mt-4': isUpdate,
            },
          ]"
        >
          <label
            class="block text optional text-sm font-medium text-gray-600"
            for="booking_service_description"
          >
            Description
          </label>
          <textarea
            v-if="!isUpdate"
            v-model="data.serviceDescription[data.language]"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out text optional"
            name="booking_service[description]"
            rows="6"
            id="booking_service_description"
            @keyup="validateInput('description', data.serviceDescription[data.language])"
          />
          <textarea
            v-if="isUpdate"
            v-model="data.serviceDescription"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out text optional"
            name="booking_service[description]"
            rows="6"
            id="booking_service_description"
            @keyup="validateInput('description', data.serviceDescription)"
          />

          <span
            v-if="validations.description.state.hasError"
            class="text-red-500"
            >{{ validations.description.state.errorMessage }}</span
          >
        </div>
      </div>
    </div>
    <button
      name="commit"
      :disabled="
        disabledButton ||
        validations.description.state.hasError ||
        validations.serviceName.state.hasError
      "
      class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:ring-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
    >
      {{ isUpdate ? 'Update service to booking' : 'Add service to booking' }}
    </button>
  </form>
</template>
