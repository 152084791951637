<script setup lang="ts">
import { ref } from "vue";

import BaseModal from "@/components/BaseModal.vue";
import BaseForm from "@/components/BaseForm.vue";

type Props = {
  contractId: number | null;
  selectedId: number | null;
  modelValue: boolean;
  kind: string;
};

const emits = defineEmits([
  "update-modal",
  "update:input",
  "update:modelValue",
]);
const props = withDefaults(defineProps<Props>(), {
  selectedId: null,
  contractId: null,
  modelValue: false,
  kind: "",
});

const errors = ref();

const successRequest = () => {
  emits("update-modal");
  errors.value = null;
};
const errorRequest = (err: { [key: string]: string }) => {
  errors.value = err;
};
</script>

<template>
  <div>
    <BaseModal
      :model-value="props.modelValue"
      :title="`Delete`"
      has-footer
      hasHeader
      @close-modal="$emit('update:modelValue', false)"
    >
      <template #content>
        <p class="text-sm text-slate-600">
          Are you sure you want to delete this element? This action cannot be
          reversed.
        </p>
        <BaseForm
          id="client_itinerary_client_ressources"
          :action="`/contracts/${contractId}/client_itineraries/${kind}s/${selectedId}`"
          method="delete"
          deleteAction
          :on-success="successRequest"
          :on-error="errorRequest"
        >
          <div class="flex justify-end mt-3">
            <button
              type="button"
              class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              @click="$emit('update:modelValue', false)"
            >
              Cancel
            </button>

            <button
              type="submit"
              class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500 cursor-pointer"
            >
              Delete
            </button>
          </div>
        </BaseForm>
      </template>
    </BaseModal>
  </div>
</template>
