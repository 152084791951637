<script setup lang="ts">
import { ref, computed } from "vue";

import BaseModal from "@/components/BaseModal.vue";
import BaseForm from "@/components/BaseForm.vue";
import BaseInput from "@/components/BaseInput.vue";

import type { Catalogs } from "@/types";

type Props = {
  modelValue: boolean;
  selectedCatalog: Catalogs;
};

const emits = defineEmits(["update-modal", "update:modelValue"]);

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
});

const contract = window.gon?.contract ? ref(window.gon?.contract) : ref(null);
const contractId = contract.value.id;
const errors = ref(null);

const generateLocaleLabel = computed(() => {
  return props.selectedCatalog.locale === "fr"
    ? "File for 🇫🇷 French catalogue"
    : "File for 🇬🇧 English catalogue";
});

const generateCatalogueTitle = computed(() => {
  return props.selectedCatalog.name === null
    ? "Upload services & experiences catalogue"
    : "Edit services & experiences catalogue";
});

const successRequest = () => {
  emits("update-modal");
  errors.value = null;
};

const errorRequest = (err: { [key: string]: string }) => {
  errors.value = err;
};

const getErrors = (locale: string) => {
  if (errors.value) {
    const errorArray = errors.value[`catalogue_${locale}`];
    if (errorArray && errorArray.length > 0) {
      return errorArray[0].error;
    }
  }
};
</script>

<template>
  <BaseModal
    :model-value="props.modelValue"
    :title="generateCatalogueTitle"
    hasFooter
    hasHeader
    @close-modal="$emit('update:modelValue', false)"
  >
    <template #content>
      <BaseForm
        id="client_itinerary_client_catalog"
        :action="`/contracts/${contractId}/client_itineraries`"
        :on-success="successRequest"
        :on-error="errorRequest"
        method="put"
      >
        <BaseInput
          :label="generateLocaleLabel"
          :errors="getErrors(selectedCatalog.locale)"
          type="file"
          :name="`client_itinerary[catalogue_${selectedCatalog.locale}]`"
          id="client_itinerary[catalogue_fr]"
          accept="application/pdf"
        />
        <div class="flex justify-end mt-3">
          <button
            type="button"
            class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-primary-700 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
            @click="$emit('update:modelValue', false)"
          >
            Cancel
          </button>

          <button
            type="submit"
            class="inline-flex items-center px-3 py-2 ml-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
          >
            Save
          </button>
        </div>
      </BaseForm>
    </template>
  </BaseModal>
</template>
