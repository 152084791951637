<script setup lang="ts">
import { ref, onUnmounted } from "vue";

import BaseModal from "@/components/BaseModal.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";

import { useEditor, EditorContent } from "@tiptap/vue-3";
import Placeholder from "@tiptap/extension-placeholder";
import HardBreak from "@tiptap/extension-hard-break";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Color from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";

type Props = {
  errors?: string | null;
  id?: string;
  label?: string;
  disabled?: boolean;
  modelValue?: string;
  name?: string;
  height?: number;
};

const emits = defineEmits(["update:model-value", "update-errors"]);

const props = withDefaults(defineProps<Props>(), {
  errors: null,
  id: "",
  label: "",
  name: "",
  modelValue: "",
});

const editor = useEditor({
  editorProps: {
    transformPastedText(text) {
      return text.replace(/\xA0/g, " ");
    },
    transformPastedHTML(html) {
      const cleanHTMLRegex = /(<([^>]+)>)/gi;
      const cleanSpaceRegex = /\xA0/g;

      return html.replace(cleanHTMLRegex, " ").replace(cleanSpaceRegex, " ");
    },
  },
  extensions: [
    Color,
    StarterKit,
    TextStyle,
    Underline,
    Image.configure({
      allowBase64: true,
      HTMLAttributes: {
        class: "tailor-img",
      },
    }),
    HardBreak.configure({
      HTMLAttributes: {
        class: "tailor-hardbreack",
      },
    }),
    Link.configure({
      HTMLAttributes: {
        rel: "noopener noreferrer",
        class: "tailor-link",
      },
    }),
    Placeholder.configure({
      placeholder: "Type something...",
    }),
  ],
  content: props.modelValue,
  onUpdate: () => {
    emits("update:model-value", editor.value?.getHTML());
  },
});

// Link
const linkModal = ref(false);
const link = ref("");
const setLink = () => {
  if (editor.value) {
    const url = link.value;

    // cancelled
    if (url === null) {
      linkModal.value = false;
      return;
    }

    // empty
    if (url === "") {
      editor.value.chain().focus().extendMarkRange("link").unsetLink().run();

      linkModal.value = false;
      return;
    }

    // update link
    editor.value
      .chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: url })
      .run();

    linkModal.value = false;
    link.value = ''
  }
};

const updateColor = (e: Event) => {
  const target = e.target as HTMLInputElement;

  if (target) {
    editor.value?.chain().focus().setColor(target.value).run();
  }
};

onUnmounted(() => {
  if (editor.value) {
    editor.value.destroy();
  }
});
</script>

<template>
  <div class="mt-4">
    <label :for="id" class="block mb-2">{{ label }}</label>
    <div
      v-if="editor"
      :class="[
        {
          'pointer-events-none rounded-md bg-gray-100 text-slate-400': disabled,
        },
      ]"
    >
      <header
        class="header-wysiwyg flex items-center border-t border-x border-slate-200 dark:bg-white white:rounded-t-md p-4 dark:text-slate-900"
      >
        <button
          type="button"
          :disabled="!editor.can().chain().focus().toggleBold().run()"
          :class="['mr-4', { 'is-active': editor.isActive('bold') }]"
          @click="editor?.chain().focus().toggleBold().run()"
        >
          <span>B</span>
        </button>
        <button
          type="button"
          :disabled="!editor.can().chain().focus().toggleItalic().run()"
          :class="['mr-4', { 'is-active': editor.isActive('italic') }]"
          @click="editor?.chain().focus().toggleItalic().run()"
        >
          <span class="italic">I</span>
        </button>
        <button
          type="button"
          :class="['mr-4', { 'is-active': editor.isActive('paragraph') }]"
          @click="editor?.chain().focus().setParagraph().run()"
        >
          p
        </button>
        <button
          type="button"
          :class="['mr-4', { 'is-active': editor.isActive('paragraph') }]"
          @click="editor?.chain().focus().toggleUnderline().run()"
        >
          <BaseIcon name="underline" />
        </button>
        <div class="flex items-center justify-center mr-4">
          <input type="color" @input="updateColor($event)" />
        </div>
        <button
          type="button"
          :class="['mr-4', { 'is-active': editor.isActive('bulletList') }]"
          @click="editor?.chain().focus().toggleBulletList().run()"
        >
          <BaseIcon name="list" />
        </button>
        <button
          type="button"
          :class="['mr-4', { 'is-active': editor.isActive('orderedList') }]"
          @click="editor?.chain().focus().toggleOrderedList().run()"
        >
          <BaseIcon name="numberedListLeft" />
        </button>
        <button
          type="button"
          :class="['mr-4', { 'is-active': editor.isActive('blockquote') }]"
          @click="linkModal = true"
        >
          <BaseIcon name="link" />
        </button>
      </header>

      <div
        class="p-4 border rounded-b-md border-slate-200 md:min-h-[200px] dark:bg-white dark:text-slate-900"
      >
        <editor-content :model-value="modelValue" :editor="editor" />
        <input
          :id="id"
          class="hidden"
          :value="editor.getHTML()"
          type="hidden"
          :name="name"
        />
      </div>
      <p v-if="errors" class="text-red-400 text-sm mt-2">
        Can't be {{ errors }}
      </p>
    </div>
  </div>

  <BaseModal v-model="linkModal" title="Add a link">
    <template #content>
      <BaseInput v-model="link" label="Link" placeholder="https://" />
    </template>

    <template #footer>
      <div class="mt-8">
        <BaseButton
          color="secondary"
          class="mr-4"
          @click="linkModal = false"
          text="Cancel"
        />
        <BaseButton @click="setLink" text="Add" />
      </div>
    </template>
  </BaseModal>
</template>

<style>
.tiptap {
  outline: none;
}
.tiptap p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
.tiptap ul,
.tiptap ol {
  @apply px-4;
}
.tiptap ul li {
  @apply list-item;
  text-align: -webkit-match-parent;
  list-style: circle;
}
.tiptap ol li {
  @apply list-item;
  text-align: -webkit-match-parent;
  list-style: auto;
}
.tailor-link {
  @apply underline;
}
.header-wysiwyg .is-active {
  @apply font-bold;
}

/* Color */
input[type="color" i] {
  @apply w-[20px] h-[20px] border-0 border-white shadow-none box-content;
  inline-size: 20px;
  block-size: 20px;
}
input[type="color" i] {
  padding: 1px;
}
input[type="color" i]::-webkit-color-swatch-wrapper {
  padding: 1px;
}
</style>
