<script setup lang="ts">
import { computed } from "vue";
import { TMenu } from "@/types";

type Props = {
  menu: TMenu;
  showText: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  showText: true,
});

const isSelected = computed(() => {
  const currentPath = window.location.pathname;

  return currentPath.includes(props.menu.link);
});
</script>

<template>
  <a :href="menu.link">
    <div
      :class="[
        'flex rounded',
        { 'is-selected': isSelected, 'justify-center item-center': showText },
      ]"
    >
      <component
        v-if="menu?.componentIcon"
        :is="menu?.componentIcon"
        :is-selected="isSelected"
      />
      <span v-if="!showText" class="pt-2">
        {{ menu.label }}
      </span>
    </div>
  </a>
</template>

<style scoped>
.is-selected {
  background-color: #033535;
  color: white;
}
</style>
