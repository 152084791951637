<script setup lang="ts">
import { ref } from "vue";

import ClientItineraryRessourcesModal from "@/views/Contracts/ClientItinerary/components/ClientItineraryRessourcesModal.vue";
import ClientItineraryRessourcesModalDelete from "@/views/Contracts/ClientItinerary/components/ClientItineraryRessourcesModalDelete.vue";
import BaseIcon from "@/components/BaseIcon.vue";

import type { Contract } from "@/types";

type ClientItineraryChanges = {
  client_resources: {
    date: string;
    time: string;
    user_name: string;
  };
};

const contract = window.gon?.contract
  ? ref<Contract>(window.gon.contract)
  : ref([]);
const linksAndDocuments = window.gon
  ? ref(window.gon?.links_and_documents)
  : ref([]);
const clientItineraryChanges = ref<ClientItineraryChanges>(
  window.gon?.audited_changes,
);
const kind = ref<string>("");
const showModal = ref(false);
const showModalDelete = ref(false);
const selectedId = ref<number | null>(null);

const openModal = (value: string) => {
  showModal.value = true;
  kind.value = value;
};
const openDeleteModal = (id: number, type: string) => {
  showModalDelete.value = true;
  selectedId.value = id;
  kind.value = type;
};
const handleCloseModal = () => {
  showModal.value = false;
  showModalDelete.value = false;
};
const updateModal = () => {
  linksAndDocuments.value = { ...window.gon?.links_and_documents };
  clientItineraryChanges.value = { ...window.gon?.audited_changes };

  showModalDelete.value = false;
  showModal.value = false;
};
</script>

<template>
  <div class="flex items-center justify-between">
    <div class="flex-1 mt-10">
      <h2
        class="text-xl font-bold text-gray-900 sm:text-xl sm:truncate px-2 pb-2"
      >
        Client resources
      </h2>
      <p
        v-if="clientItineraryChanges?.client_resources?.date"
        class="font-medium leading-5 pb-4 px-2 rounded-md text-sm text-gray-500"
      >
        Last edit:
        {{ clientItineraryChanges.client_resources.date }}
        at
        {{ clientItineraryChanges.client_resources.time }}
        by
        {{ clientItineraryChanges.client_resources.user_name }}
      </p>
    </div>
    <button
      class="ml-3 inline-flex items-center px-4 py-2 cursor-pointer border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
      @click="openModal('link')"
    >
      + Add a link
    </button>
    <button
      @click="openModal('document')"
      class="ml-3 inline-flex items-center px-4 py-2 cursor-pointer border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
    >
      + Add a document
    </button>
  </div>
  <div
    class="relative rounded-xl overflow-auto border border-solid divide-stone-200 shadow-lg"
  >
    <table class="table-auto max-w-[1432px] w-full">
      <thead class="text-gray-500 text-left sm:not-sr-only bg-gray-50">
        <tr>
          <th class="py-4 px-6 font-bold" scope="col">Resource name</th>
          <th class="py-4 px-6 font-bold" scope="col">
            Document or external link
          </th>
          <th
            class="hidden py-4 px-6 font-bold sm:table-cell w-[107px]"
            scope="col"
          >
            Action
          </th>
        </tr>
      </thead>
      <tbody
        class="border-b border-gray-200 divide-y divide-gray-200 sm:border-t"
      >
        <tr v-for="(resources, index) in linksAndDocuments" :key="index">
          <td class="py-3 px-6 font-medium text-gray-900">
            {{ resources.table.name }}
          </td>
          <td class="py-3 px-6 font-medium text-gray-900">
            <a target="_blank" :href="resources.table.url">{{
              resources.table.kind === "document"
                ? resources.table.filename
                : resources.table.url
            }}</a>
          </td>
          <td class="py-3 px-6 font-medium text-gray-900">
            <BaseIcon
              name="trash"
              class="cursor-pointer"
              @click="openDeleteModal(resources.table.id, resources.table.kind)"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <ClientItineraryRessourcesModal
      v-model="showModal"
      :contractId="contract.id"
      :kind="kind"
      @close-modal="handleCloseModal"
      @update-modal="updateModal"
    />
    <ClientItineraryRessourcesModalDelete
      v-model="showModalDelete"
      :contractId="contract.id"
      :selectedId="selectedId"
      :kind="kind"
      @close-modal="handleCloseModal"
      @update-modal="updateModal"
    />
  </div>
</template>
