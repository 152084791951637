<script setup lang="ts">
import { ref, reactive } from "vue";

import BaseModal from "@/components/BaseModal.vue";
import BaseForm from "@/components/BaseForm.vue";

import BaseButton from "@/components/BaseButton.vue";
import BaseDate from "@/components/BaseDate.vue";
import BaseTime from "@/components/BaseTime.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseWysiwyg from "@/components/BaseWysiwyg.vue";

import ClientItineraryLabel from "./ClientItineraryLabel.vue";
import ClientItineraryAddServiceModalUpload from "./ClientItineraryAddServiceModalUpload.vue";
import ClientItineraryAddServiceModalUnpaidToggle from "./ClientItineraryAddServiceModalUnpaidToggle.vue";

import { useFormData } from "../composables/useFormData";
import { format } from "@/helpers/dates";

import type { Appointments, Contract } from "@/types";
import type { ClientItineraryData } from "../composables/useFormData";

type Props = {
  modelValue: boolean;
  selectedClientItinerary: Appointments | null;
};
type InitialData = {
  startAt: string;
  endAt: string;
  startAtTime: string;
  endAtTime: string;
};

const emits = defineEmits([
  "update:modelValue",
  "update:datetime",
  "update:name",
  "update:input",
  "update-published",
  "update-service",
]);

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  selectedClientItinerary: null,
});

const contract = ref<Contract>(window.gon.contract);
const errors = ref(null);
const showMenu = ref(false);
const contractId = ref<number>(contract.value?.id);
const { formData, displayToggleData, setFormData } = useFormData(
  props.selectedClientItinerary,
);

const dateRangeData = reactive<InitialData>({
  startAt: format(
    props.selectedClientItinerary?.start_at ?? new Date(),
    "YYYY-MM-DD",
  ),
  endAt: format(
    props.selectedClientItinerary?.end_at ?? new Date(),
    "YYYY-MM-DD",
  ),
  startAtTime: format(
    props.selectedClientItinerary?.start_at_time ?? new Date(),
    "HH:mm",
  ),
  endAtTime: format(
    props.selectedClientItinerary?.end_at_time ?? new Date(),
    "HH:mm",
  ),
});

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};
const closeModal = () => {
  emits("update:modelValue", false);
  errors.value = null;
};

const togglePublished = () => {
  const newValue = 1 - displayToggleData.value.value;

  displayToggleData.value = {
    value: newValue,
    status: newValue === 1 ? "Published" : "Not published",
    text: newValue === 1 ? "Not published" : "Published",
  };
};

const changeDateTime = (value: string, type: keyof InitialData) => {
  emits("update:datetime", value);
  dateRangeData[type] = value;
};
const updateErrors = (type: keyof ClientItineraryData) => {
  if (errors.value) delete errors.value[type];
};
const successRequest = () => {
  emits("update-service");
  errors.value = null;
  displayToggleData.value.value = 0;
  displayToggleData.value.status = "Not published";
  displayToggleData.value.text = "Not Published";
};

const errorRequest = async (err: { [key: string]: string }) => {
  errors.value = err;
};
const updatePlace = (place: ClientItineraryData) => {
  setFormData({ ...place, at_the_propery: false });
};
</script>

<template>
  <BaseModal
    class="h-full md:w-[500px]"
    @close-modal="closeModal"
    :model-value="modelValue"
    hasFooter
    hasHeader
    :title="`Add a new reservation`"
  >
    <template #content>
      <p class="text-sm pb-2 text-gray-500">
        Paid directly by the client to the provider (no LC fees).
      </p>

      <BaseForm
        id="addReservation"
        :action="`/contracts/${contractId}/client_itineraries/appointments`"
        :on-success="successRequest"
        :on-error="errorRequest"
      >
        <div class="mb-6">
          <ClientItineraryLabel
            @click="toggleMenu"
            :label="displayToggleData.status"
          />

          <div
            v-show="showMenu"
            @click="toggleMenu"
            class="cursor-pointer mt-1 rounded-b-lg shadow-md text-center w-28"
          >
            <div class="p-3" @click="togglePublished">
              <span class="text-xs">
                {{ displayToggleData.text }}
              </span>
              <input
                :value="displayToggleData.value"
                type="hidden"
                name="appointment[published]"
                id="appointment_published"
              />
            </div>
          </div>
        </div>

        <fieldset class="font-bold mb-0 text-[14px]">
          Reservation name
          <strong>*</strong>
        </fieldset>
        <div class="pb-4">
          <BaseInput
            :errors="
              (Array.isArray(errors?.name) && errors?.name[0]?.error) || ''
            "
            id="appointment_name"
            name="appointment[name]"
            placeholder="Write the reservation name as it will appear in-app"
            type="text"
            @input="updateErrors('name')"
          />
        </div>

        <fieldset class="font-bold mb-0 text-[14px]">
          Local date & time <strong>*</strong>
        </fieldset>
        <div class="grid grid-cols-12 gap-4 mt-4">
          <BaseDate
            class="col-span-6"
            :as-schedules="false"
            :date="dateRangeData.startAt"
            :disabled="false"
            :index="0"
            model="appointment"
            @update:date="changeDateTime($event, 'startAt')"
            attribute="start_at"
            label="Start date"
            min="2018-01-01"
            customClassInput="w-full"
          />
          <BaseTime
            :as-schedules="false"
            attribute="start_at_time"
            :index="0"
            model="appointment"
            :time="dateRangeData.startAtTime"
            @update:time="changeDateTime($event, 'startAtTime')"
            class="col-span-6"
          />
          <BaseDate
            :as-schedules="false"
            :date="dateRangeData.endAt"
            :disabled="false"
            :index="1"
            model="appointment"
            @update:date="changeDateTime($event, 'endAt')"
            attribute="end_at"
            label="End date"
            min="2018-01-01"
            class="col-span-6"
            customClassInput="w-full"
          />
          <BaseTime
            :as-schedules="false"
            attribute="end_at_time"
            :index="1"
            model="appointment"
            :time="dateRangeData.endAtTime"
            @update:time="changeDateTime($event, 'endAtTime')"
            class="col-span-6"
          />
          <p
            v-if="Array.isArray(errors?.end_at) && errors?.end_at[0]?.error"
            class="text-red-400 text-sm mt-2"
          >
            Departure date is
            {{
              (Array.isArray(errors?.end_at) && errors?.end_at[0]?.error) || ""
            }}
            arrival date
          </p>
        </div>
        <ClientItineraryAddServiceModalUnpaidToggle
          class="mt-6"
          :form-data="formData"
          :errors="errors"
          @update-errors="updateErrors"
          @update-place="updatePlace"
        />

        <fieldset class="font-semibold text-[14px] block mt-6">
          Service Details
        </fieldset>
        <div class="mt-2">
          <label class="label block" for="appointment_description">
            Description for the client
          </label>
          <BaseWysiwyg
            :model-value="formData?.description"
            id="appointment_description"
            name="appointment[description]"
            :height="200"
          />
        </div>

        <div class="flex flex-col w-full">
          <BaseInput
            id="appointment_confirmation"
            label="Booking confirmation (optional)"
            name="appointment[confirmation]"
            type="file"
          />
          <ClientItineraryAddServiceModalUpload
            :photo-url="formData?.photoUrl"
            :errors="
              errors?.image && Array.isArray(errors.image)
                ? errors.image[0].error
                : null
            "
          />
        </div>

        <div class="flex justify-end mt-4">
          <BaseButton
            @click="$emit('update:modelValue', false)"
            type="button"
            color="secondary"
            text="Cancel"
            class="mr-4"
          />
          <BaseButton type="submit" color="primary" text="Save" />
        </div>
      </BaseForm>
    </template>
  </BaseModal>
</template>

<style scoped>
img {
  max-width: 100%;
  height: fit-content;
}
</style>
