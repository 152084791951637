<script setup lang="ts">
import { ref } from "vue";

import BaseIcon from "@/components/BaseIcon.vue";
import CatalogModal from "@/views/Contracts/ClientItinerary/components/CatalogModal.vue";

import { Catalogs } from "@/types";

const catalogues = window.gon?.catalogues
  ? ref<Catalogs>(window.gon.catalogues)
  : ref([]);

const selectedCatalog = ref();
const showModal = ref(false);

const openModal = (catalog: Catalogs) => {
  showModal.value = true;
  selectedCatalog.value = catalog;
};

const handleCloseModal = () => {
  showModal.value = false;
};

const updateModal = () => {
  catalogues.value = { ...window.gon?.catalogues };
  showModal.value = false;
};
</script>

<template>
  <h2
    class="text-xl font-bold text-gray-900 sm:text-xl sm:truncate mt-10 px-2 pb-2"
  >
    Services &amp; experiences catalogue
  </h2>
  <div
    class="relative rounded-xl overflow-auto border border-solid divide-stone-200 shadow-lg"
  >
    <table class="table-auto max-w-[1432px] w-full">
      <thead class="text-sm text-gray-500 text-left sm:not-sr-only bg-gray-50">
        <tr>
          <th class="py-4 px-6 font-bold" scope="col">
            Services &amp; experiences catalogue
          </th>
          <th
            class="hidden py-4 px-6 font-bold sm:table-cell w-[107px]"
            scope="col"
          >
            Action
          </th>
        </tr>
      </thead>
      <tbody class="">
        <tr v-for="(catalog, index) in catalogues" :key="index">
          <td class="py-3 px-6 text-sm font-medium text-gray-900">
            {{ catalog.locale === "fr" ? "🇫🇷" : "🇬🇧" }}
            <a
              @click="openModal(catalog)"
              v-if="catalog.name === null"
              class="inline ml-2 text-gray-600 hover:underline cursor-pointer"
            >
              Upload the catalogue</a
            >
            <a
              class="inline ml-2 text-gray-600"
              v-else
              :href="catalog.url"
              target="_blank"
              >{{ catalog.name }}</a
            >
          </td>
          <td class="py-3 px-6 text-sm font-medium text-gray-900">
            <button
              class="edit-link"
              data-modal-id=""
              type="button"
              @click="openModal(catalog)"
            >
              <BaseIcon name="edit" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <CatalogModal
    v-if="showModal"
    v-model="showModal"
    :selected-catalog="selectedCatalog"
    @close-modal="handleCloseModal"
    @update-modal="updateModal"
  />
</template>
