<script setup lang="ts">
import { computed, ref } from "vue";

import BaseInput from "@/components/BaseInput.vue";
import BaseMediaImagePlus from "@/components/icons/BaseMediaImagePlus.vue";

const CDN_URL = import.meta.env.VITE_CDN_URL;
const props = defineProps({
  photoUrl: {
    type: String,
    default: "",
  },
  errors: {
    type: String,
    default: null,
  },
});

const src = ref("");
const uploadPhoto = (fileUrl: string) => {
  src.value = fileUrl;
};

const cleanUrl = computed(() => {
  return props.photoUrl.replace(CDN_URL, "");
});
</script>

<template>
  <div class="flex flex-col">
    <VTooltip class="w-auto mt-4 -mb-2" :skidding="100">
      <div class="flex">
        <p class="label">Image *</p>
        <BaseIcon name="warning" />
      </div>

      <template #popper>
        Your image will be displayed in 786x580 and 272x226 within the app. If
        you find it unsatisfactory, feel free to crop it and upload the revised
        version.
      </template>
    </VTooltip>
    <BaseInput
      id="appointment_image"
      required
      name="appointment[image]"
      type="file"
      @update:file="uploadPhoto"
    />
    <p class="text-red-400 text-sm mt-2" v-if="errors && !src">
      Can't be {{ errors }}
    </p>

    <div v-if="!src && !photoUrl" class="flex items-start mt-4">
      <div
        class="w-[320px] h-[241px] bg-[#E2E8F0] mr-4 border border-slate-200 flex items-center justify-center"
      >
        <BaseMediaImagePlus class="text-slate-400" />
      </div>
      <div
        class="w-[113px] h-[113px] bg-[#E2E8F0] border border-slate-200 flex items-center justify-center"
      >
        <BaseMediaImagePlus class="text-slate-400" />
      </div>
    </div>

    <div v-if="src" class="flex items-start mt-4">
      <img
        :src="src"
        width="320"
        height="241"
        class="w-[320px] h-[241px] object-cover mr-4 border border-slate-200"
      />
      <img
        :src="src"
        width="113"
        height="113"
        class="w-[113px] h-[113px] object-cover border border-slate-200"
      />
    </div>

    <div v-if="photoUrl && !src" class="flex items-start mt-4">
      <img
        :src="photoUrl"
        width="320"
        height="241"
        class="w-[320px] h-[241px] object-cover mr-4 border border-slate-200"
      />
      <img
        :src="photoUrl"
        width="113"
        height="113"
        class="w-[113px] h-[113px] object-cover border border-slate-200"
      />

      <input
        class="hidden"
        type="text"
        name="appointment[place_image_path]"
        :value="cleanUrl"
      />
    </div>
  </div>
</template>

<style>
.v-popper--theme-tooltip {
  width: 300px;
  left: -100px;
}
</style>
