<script setup lang="ts">
import { ref, onMounted } from "vue";
import { vOnClickOutside } from "@vueuse/components";

import BaseIcon from "@/components/BaseIcon.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseModal from "@/components/BaseModal.vue";

type ActionList = {
  name: string;
  iconName: string;
  class: string;
  action: () => void;
};

const props = defineProps<{
  edit?: string;
  duplicate?: string;
  delete?: string;
}>();

const showModal = ref<boolean>(false);
const showMenu = ref<boolean>(false);
const actions = ref<ActionList[]>([]);

const setListActions = () => {
  if (props.edit) {
    actions.value.push({
      name: "Edit",
      iconName: "edit",
      class: "text-gray-600 hover:bg-gray-100 w-full rounded-t-lg",
      action: () => editAction(),
    });
  }
  if (props.duplicate) {
    actions.value.push({
      name: "Duplicate",
      iconName: "duplicate",
      class: "text-gray-600 hover:bg-gray-100 w-full",
      action: () => duplicateAction(),
    });
  }
  if (props.delete) {
    actions.value.push({
      name: "Delete",
      iconName: "trash",
      class: "text-red-600 hover:bg-red-100 w-full rounded-b-lg",
      action: () => showDeleteModal(),
    });
  }
};

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};

const closeMenu = () => {
  if (showMenu.value) {
    showMenu.value = false;
  }
};

const showDeleteModal = () => {
  showModal.value = true;
};

const editAction = () => {
  if (props.edit) {
    window.location.href = props.edit;
  }
};

const duplicateAction = () => {
  if (props.duplicate) {
    const form = document.querySelector(`form[action="${props.duplicate}"]`);
    if (form) {
      (form as HTMLFormElement).submit();
    }
  }
};

const deleteAction = () => {
  if (props.delete) {
    const form = document.querySelector(`form[action="${props.delete}"]`);
    if (form) {
      (form as HTMLFormElement).submit();
    }
  }
};

onMounted(() => {
  setListActions();
});
</script>

<template>
  <div
    class="flex items-center justify-center relative"
    v-on-click-outside="closeMenu"
  >
    <button type="button" class="focus:outline-none" @click="toggleMenu">
      <BaseIcon name="list" class="w-6 h-6" />
    </button>
    <div
      v-if="showMenu"
      class="absolute top-full right-0 z-10 mt-2 w-30 bg-white shadow-lg rounded-lg border border-gray-200"
    >
      <button
        v-for="(action, index) in actions"
        :key="index"
        type="button"
        class="flex items-center px-4 py-2 text-sm"
        :class="action.class"
        @click="action.action"
      >
        <BaseIcon :name="action.iconName" class="mr-2" />
        {{ action.name }}
      </button>
    </div>

    <BaseModal has-footer v-model="showModal">
      <template #content>
        <p class="text-lg font-bold text-slate-800 text-center mb-2">
          Be careful
        </p>
        <p class="text-sm font-medium text-gray-900 mb-4">
          Are you sure you want to delete this service?
        </p>
      </template>

      <template #footer>
        <BaseButton
          @click="showModal = false"
          color="secondary"
          type="button"
          text="No"
          class="mr-2"
        />
        <BaseButton @click="deleteAction" type="button" text="Yes" />
      </template>
    </BaseModal>
  </div>
</template>
