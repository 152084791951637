<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <circle cx="20" cy="20" r="20" fill="white" />
    <path
      d="M21.9046 30C16.785 30 13.8094 26.4498 13.8094 20C13.8094 13.5501 16.6859 9.0476 20.9522 9.0476C23.4172 9.0476 23.9063 10.1974 24.2856 11.9047C24.6648 13.6121 25.1496 14.2857 26.6665 14.2857C27.2266 14.3393 27.6796 14.189 28.0951 13.8095C28.5106 13.43 28.5738 12.9438 28.5713 12.3809C28.5713 10.2942 25.8822 8.09521 20.9522 8.09521C14.0313 8.09521 8.57129 13.1726 8.57129 20.4762C8.57129 27.7797 13.3655 31.4285 20.4761 31.4285C25.406 31.4285 28.86 29.0321 29.5237 25.2381H28.5713C27.9076 28.2733 25.3177 30 21.9046 30Z"
      fill="#202020"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 37.1429C29.4678 37.1429 37.1429 29.4678 37.1429 20C37.1429 10.5323 29.4678 2.85717 20 2.85717C10.5323 2.85717 2.85717 10.5323 2.85717 20C2.85717 29.4678 10.5323 37.1429 20 37.1429ZM20 38.0953C29.9937 38.0953 38.0953 29.9937 38.0953 20C38.0953 10.0063 29.9937 1.90479 20 1.90479C10.0063 1.90479 1.90479 10.0063 1.90479 20C1.90479 29.9937 10.0063 38.0953 20 38.0953Z"
      fill="#202020"
    />
  </svg>
</template>
