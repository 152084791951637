<script setup lang="ts">
import { computed, reactive, ref, nextTick } from "vue";

import BaseMultiselect from "@/components/BaseMultiselect.vue";

const token = ref(
  document.getElementsByName("csrf-token")[0].getAttribute("content"),
);

const gon = window.gon;
const tailors = ref(gon?.tailors || []);
const contract = ref(gon?.contracts || []);

type Tailors = {
  id: string;
  name: string;
};

interface InitialData {
  tailors: Tailors;
  tailorId: string;
}

const data = reactive<InitialData>({
  tailors: tailors.value,
  tailorId: contract.value?.tailor_id || 0,
});

const formatTailors = computed(() => {
  return (
    tailors.value?.map((tailor: Tailors) => {
      return {
        value: tailor.id,
        label: tailor.name,
      };
    }) || []
  );
});

const form = ref<HTMLFormElement | null>(null);

const submitForm = ($event) => {
  if ($event == undefined) {
    return;
  } else {
    nextTick(() => {
      form.value.submit();
    });
  }
};
</script>

<template>
  <form
    ref="form"
    id="contract_tailor_select_form"
    enctype="multipart/form-data"
    :action="`/contracts/${contract.id}`"
    accept-charset="UTF-8"
    method="post"
  >
    <input type="hidden" name="_method" value="patch" />
    <input
      type="hidden"
      name="authenticity_token"
      autocomplete="off"
      :value="token"
    />
    <BaseMultiselect
      v-model="data.tailorId"
      :options="formatTailors"
      :searchable="true"
      attribute="tailor_id"
      model="contract"
      @update:model-value="submitForm($event)"
    />
  </form>
</template>
