<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { clientApi } from "@/api/config";

import BaseModal from "@/components/BaseModal.vue";
import BaseForm from "@/components/BaseForm.vue";
import BaseInput from "@/components/BaseInput.vue";

type Props = {
  modelValue: boolean;
  contractId: number;
};

type ErrorResponse = {
  errors: {
    guest_contact: {
      email: {
        error: string;
        value: string;
      }[];
    };
  }[];
};

const emits = defineEmits(["update-modal", "update:input", "update:modelValue"]);
const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
});
const VITE_API_BASEURL = import.meta.env.VITE_API_BASEURL;
const api = clientApi.client;
const email = ref("");
const firstName = ref("");
const lastName = ref("");
const errors = ref<ErrorResponse | null>(null);

const submitForm = async () => {
  try {
    const response = await api.guestContracts.create(props.contractId, {
      guest_contact: {
        email: email.value,
      },
    });

    if (response) {
      emits("update:modelValue", false);
      emits("update-modal");
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      errors.value = error.response.data;
    } else {
      console.error(error);
    }
  }
};

const mapErrors = (errors: ErrorResponse | null) => {
  if (!errors) return null;
  const emailError = errors?.errors[0]?.guest_contact?.email[0]?.error;
  return emailError ? `the email has ${emailError}` : null;
};

watch(email, (newEmail) => {
  email.value = newEmail.toLowerCase();
});
</script>

<template>
  <BaseModal
    :model-value="props.modelValue"
    :title="`Invite guest to the stay`"
    hasFooter
    hasHeader
    @close-modal="$emit('update:modelValue', false)"
  >
    <template #content>
      <p class="text-sm text-slate-600">
        The guests invited to the stay will have to create an account on the app and will
        be then allowed to access to the stay itinerary. They’ll not see all the financial
        information.
      </p>

      <form id="client_itinerary_guest" @submit.prevent="submitForm">
        <BaseInput
          v-model="firstName"
          type="text"
          name="`first_name`"
          label="First name"
          id="first_name"
        />
        <BaseInput
          v-model="lastName"
          type="text"
          name="`last_name`"
          label="Last name"
          id="last_name"
        />
        <BaseInput
          :errors="mapErrors(errors)"
          v-model="email"
          type="text"
          name="`email`"
          label="Email"
          id="email"
        />
        <div class="flex justify-end mt-3">
          <button
            type="button"
            class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-primary-700 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
            @click="$emit('update:modelValue', false)"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="inline-flex items-center px-3 py-2 ml-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
          >
            Send invitation
          </button>
        </div>
      </form>
    </template>
  </BaseModal>
</template>
