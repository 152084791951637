const truncateString = (str: string, num: number): string => {
  if (typeof str === "string") {
    if (str.length <= num) {
      return str;
    }
    return `${str.slice(0, num)}...`;
  } else {
    return str;
  }
};

export { truncateString };
