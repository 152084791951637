<script setup lang="ts">
import { ref } from "vue";

import BaseRadio from "@/components/BaseRadio.vue";
import ClientItineraryAddServiceSearch from "./ClientItineraryAddServiceSearch.vue";
import ClientItineraryAddServiceFormAddress from "./ClientItineraryAddServiceFormAddress.vue";

import type { PropType } from "vue";
import type { ClientItineraryData } from "../composables/useFormData";

const emits = defineEmits(["update-errors", "update-place", "update-phone"]);
const props = defineProps({
  formData: {
    type: Object as PropType<ClientItineraryData>,
    required: true,
  },
  errors: {
    type: Object as PropType<Record<string, string[]>>,
    default: null,
  },
  showTitle: {
    type: Boolean,
    default: true,
  },
});

const toggleNav = ref(props.formData.at_the_propery);

const updateErrors = (type: keyof ClientItineraryData) => {
  emits("update-errors", type);
};
const updatePlace = (place: ClientItineraryData) => {
  emits("update-place", place);
};
const handleAtTheProperty = (bool: boolean) => {
  toggleNav.value = bool;
};
const updatePhoneContainsLetters = (containsLetters: boolean) => {
  emits("update-phone", containsLetters);
};
</script>

<template>
  <div class="mt-8">
    <fieldset class="font-bold mb-0 text-[14px]">
      Service Location <strong>*</strong>
    </fieldset>
    <div class="flex items-center mt-4">
      <BaseRadio
        :model-value="formData.at_the_propery"
        :value="false"
        id="specific_address"
        name="appointment[fill_in_manually]"
        label="Search from address book"
        class="mr-4"
        @update:model-value="handleAtTheProperty(false)"
      />
      <BaseRadio
        :model-value="formData.at_the_propery"
        :value="true"
        id="at_the_property"
        name="appointment[fill_in_manually]"
        label="Fill in manually"
        @update:model-value="handleAtTheProperty(true)"
      />
    </div>

    <div class="mt-4">
      <ClientItineraryAddServiceSearch
        v-if="!toggleNav"
        :form-data="formData"
        :api-place-id="formData.api_place_id"
        :api-place-name="formData.name"
        @update-place="updatePlace"
      />
      <ClientItineraryAddServiceFormAddress
        v-if="toggleNav"
        filled-input
        :errors="errors"
        :form-data="formData"
        :show-title="showTitle && !formData.at_the_propery"
        @update-errors="updateErrors"
        @update-phone="updatePhoneContainsLetters"
      />
    </div>
  </div>
</template>
