const queryParams = () => {
  const href = new URL(window.location.href);
  const customSearchParams = Object.create(href.searchParams);

  customSearchParams.set = (param: string, val: string) => {
    href.searchParams.set(param, val);
    window.location.href = href.toString();
  };
  customSearchParams.get = (param: string) => href.searchParams.get(param);
  customSearchParams.remove = (param: string) => {
    href.searchParams.delete(param);
    window.location.href = href.toString();
  };

  return customSearchParams;
};

export default queryParams;
