<script setup lang="ts">
import { computed, reactive, ref } from "vue";

const emits = defineEmits(["update:time"]);

type Props = {
  asSchedules: boolean;
  attribute: string;
  index: number;
  model: string;
  time: string;
};

type TimeValue = {
  hour: string;
  minute: string;
};

const props = withDefaults(defineProps<Props>(), {
  index: 0,
  time: "",
  model: "",
  asSchedules: true,
  attribute: "",
});

const hours = ref<string[]>(
  Array.from({ length: 24 }, (_, index) =>
    index < 10 ? `0${index}` : `${index}`,
  ),
);

const minutes = ref<string[]>(
  Array.from({ length: 60 }, (_, index) =>
    index < 10 ? `0${index}` : `${index}`,
  ),
);

const changeTime = () => {
  const newTime = `${timeValue.hour}:${timeValue.minute}`;

  emits("update:time", newTime);
};

const id = computed(() => {
  if (props.asSchedules)
    return `${props.model}_schedules_attributes_${props.index}_${props.attribute}`;

  return `${props.model}_${props.attribute}`;
});

const name = computed(() => {
  if (props.asSchedules)
    return `${props.model}[schedules_attributes][${props.index}][${props.attribute}`;

  return `${props.model}[${props.attribute}`;
});

const splitTime = computed(() => {
  const timeCopy = JSON.parse(JSON.stringify(props.time));
  const [hour, minute] = timeCopy.split(":");

  return {
    hour,
    minute,
  };
});

const timeValue = reactive<TimeValue>({
  hour: splitTime.value.hour,
  minute: splitTime.value.minute,
});
</script>

<template>
  <div class="time">
    <legend class="text-sm font-medium text-gray-600 mb-2">Time</legend>

    <div class="flex h-10">
      <select
        v-model="timeValue.hour"
        :id="`${id}_4i`"
        :name="`${name}(4i)]`"
        class="flex w-full shadow appearance-none border border-gray-300 rounded py-[9px] px-2 bg-white focus:outline-none focus:border-blue-500 text-slate-950 leading-4 transition-colors duration-200 ease-in-out date required"
        @change="changeTime"
      >
        <option v-for="(hour, index) in hours" :key="index" :value="hour">
          {{ hour }}
        </option>
      </select>
      <span class="flex items-center ml-1 mr-1">:</span>
      <select
        v-model="timeValue.minute"
        :id="`${id}_5i`"
        :name="`${name}(5i)]`"
        class="flex w-full shadow appearance-none border border-gray-300 rounded py-[9px] px-2 bg-white focus:outline-none focus:border-blue-500 text-slate-950 leading-4 transition-colors duration-200 ease-in-out date required"
        @change="changeTime"
      >
        <option v-for="(minute, index) in minutes" :key="index" :value="minute">
          {{ minute }}
        </option>
      </select>
    </div>
  </div>
</template>
