<script setup lang="ts">
import BaseDate from "@/components/BaseDate.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import BaseTime from "@/components/BaseTime.vue";
import BaseMultiselect from "@/components/BaseMultiselect.vue";

const emits = defineEmits([
  "delete-schedule",
  "update:end-date",
  "update:number-of-hours",
  "update:number-of-minutes",
  "update:start-date",
  "update:time",
  "update:total-duration",
]);

interface Props {
  dateId: string | undefined;
  dateRange: boolean;
  endDate: string | null;
  index: number;
  model: string;
  numberOfHours?: number;
  numberOfMinutes?: number;
  pricingType: string;
  startDate: string;
  time: string;
  totalDuration?: number;
}
const props = withDefaults(defineProps<Props>(), {
  dateId: "",
  dateRange: false,
  endDate: null,
  index: 0,
  model: "",
  numberOfHours: 1,
  numberOfMinutes: 0,
  pricingType: "",
  startDate: "",
  time: "",
  totalDuration: 1,
});

const updateNumberOfHours = (value: number) => {
  emits("update:number-of-hours", value);
  emits("update:total-duration");
};

const updateNumberOfMinutes = (value: number) => {
  emits("update:number-of-minutes", value);
  emits("update:total-duration");
};

const changeStartDate = (value: string) => {
  emits("update:start-date", value);
};
const changeEndDate = (value: string) => {
  emits("update:end-date", value);
};
const changeTime = (value: string) => {
  emits("update:time", value);
};
const deleteSchedule = () => {
  emits("delete-schedule", props.dateId || props.index);
};
</script>

<template>
  <div class="mb-4 date required booking_service_schedules_start_date">
    <div class="relative flex items-end">
      <BaseDate
        :date="startDate"
        :disabled="false"
        :index="index"
        :model="model"
        @update:date="changeStartDate"
        as-schedules
        attribute="start_date"
        label="From date"
        min="2018-01-01"
      />
      <BaseDate
        :date="endDate"
        :disabled="pricingType === 'number_of_weeks'"
        :index="index"
        :min="startDate"
        :model="model"
        @update:date="changeEndDate"
        class="ml-2"
        as-schedules
        attribute="end_date"
        label="To date"
        v-if="dateRange"
      />
      <div
        v-if="pricingType === 'number_of_hours'"
        class="select required duration ml-4"
      >
        <label
          class="block select required text-sm font-medium text-gray-600 mb-2"
          for="duration"
        >
          Duration per unit <abbr title="required">*</abbr>
        </label>
        <div class="flex items-center">
          <BaseMultiselect
            :can-clear="false"
            :index="index"
            :nestedAttributes="true"
            :options="[1, 2, 3, 4, 5, 6, 7, 8]"
            :modelValue="numberOfHours"
            attribute="number_of_hours"
            model="booking_service[schedules_attributes]"
            wrapper-class="w-32"
            @update:modelValue="updateNumberOfHours($event)"
          >
            <template #multiselect-singlelabel="{ value }">
              {{ value.value }}h
            </template>
            <template #multiselect-option="{ option }">
              {{ option.value }}h
            </template>
          </BaseMultiselect>

          <BaseMultiselect
            :nestedAttributes="true"
            :can-clear="false"
            :index="index"
            :options="[0, 15, 30, 45]"
            :modelValue="numberOfMinutes"
            attribute="number_of_minutes"
            model="booking_service[schedules_attributes]"
            wrapper-class="w-32 ml-2"
            @update:modelValue="updateNumberOfMinutes($event)"
          >
            <template #multiselect-singlelabel="{ value }">
              {{ value.value }}min
            </template>
            <template #multiselect-option="{ option }">
              {{ option.value }}min
            </template>
          </BaseMultiselect>
        </div>
      </div>

      <BaseTime
        v-if="pricingType !== 'number_of_weeks'"
        attribute="start_time"
        :index="index"
        :model="model"
        :time="time"
        @update:time="changeTime"
        class="ml-4"
      />

      <button
        v-if="index > 0"
        type="button"
        @click="deleteSchedule"
        class="w-10 h-10 flex items-center justify-center hover:bg-gray-50 rounded-full duration-300 ml-10 text-red-600"
      >
        <BaseIcon name="close" />
      </button>
    </div>
  </div>
</template>
