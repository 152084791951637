<script setup lang="ts">
import { computed, reactive, ref } from "vue";

import BaseMultiselect from "@/components/BaseMultiselect.vue";

import type { Category, Destination, ThirdParty, PaymentError } from "@/types";

type TData = {
  accountNumber: string | null;
  name: string;
  accountOwner: string | null;
  iban: string;
  bic: string;
  files: null | FileList;
};

const token = ref<string | null>(
  document.getElementsByName("csrf-token")[0].getAttribute("content"),
);

const categories = ref<Category[]>(window.gon?.categories);
const destinations = ref<Destination[]>(window.gon?.destinations);
const errors = ref<PaymentError | undefined>(window.gon?.errors);
const thirdParty = ref<ThirdParty>(window.gon?.thirdParty);
const thirdPartyCategories = ref<number[] | undefined>(
  window.gon?.thirdPartyCategories,
);
const thirdPartyDestinations = ref<number[] | undefined>(
  window.gon?.thirdPartyDestinations,
);

const data = reactive<TData>({
  accountNumber: thirdParty.value?.account_owner,
  name: thirdParty.value?.name,
  accountOwner: thirdParty.value?.account_owner,
  iban: thirdParty.value?.iban,
  bic: thirdParty.value?.bic,
  files: null,
});

const isUpdate = computed(() => {
  return thirdParty.value?.id;
});
const action = computed(() => {
  if (isUpdate.value) return `/third_parties/${thirdParty.value.id}`;

  return "/third_parties";
});

const formatDestinations = computed(() => {
  return destinations.value.map((desti: Destination) => {
    return {
      value: desti.id,
      label: desti.name.en,
    };
  });
});
const formatCategories = computed(() => {
  return categories?.value.map((category: Category) => ({
    value: category.id,
    label: category.name,
  }));
});

const disabledButton = computed(() => {
  if (
    data.name &&
    data.accountOwner &&
    thirdPartyCategories.value?.length &&
    thirdPartyDestinations.value?.length
  ) {
    return false;
  }

  return true;
});

const onFileChange = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const files = e instanceof DragEvent ? e.dataTransfer?.files : target.files;

  if (!files?.length) return;

  data.files = files;
};
</script>

<template>
  <form
    :action="action"
    class="mt-8"
    novalidate
    enctype="multipart/form-data"
    accept-charset="UTF-8"
    method="post"
  >
    <input
      v-if="isUpdate"
      type="hidden"
      name="_method"
      value="patch"
      autocomplete="off"
    />
    <input
      :value="token"
      type="hidden"
      name="authenticity_token"
      autocomplete="off"
    />

    <div class="flex mb-4">
      <div class="w-[40%]">
        <h1 class="text-xl font-bold">General information</h1>
        <span class="text-gray-600"
          >Complete all general information about third party.</span
        >
      </div>
      <div class="w-[60%]">
        <div class="mb-4 string required third_party_name">
          <label
            class="block string required text-sm font-medium text-gray-600"
            for="third_party_name"
          >
            Name <abbr title="required">*</abbr>
          </label>
          <input
            v-model="data.name"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string required"
            type="text"
            name="third_party[name]"
            id="third_party_name"
          />
        </div>
        <div class="my-4 check_boxes required third_party_categories">
          <legend class="text-sm font-medium text-gray-600">
            Categories <abbr title="required">*</abbr>
          </legend>

          <BaseMultiselect
            v-model="thirdPartyCategories"
            :options="formatCategories"
            :searchable="true"
            mode="tags"
            attribute="category_ids"
            model="third_party"
          />
        </div>

        <div class="mb-4">
          <label
            class="block select optional text-sm font-medium text-gray-600"
            for="third_party_destination_ids"
          >
            Destinations <abbr title="required">*</abbr>
          </label>

          <BaseMultiselect
            v-model="thirdPartyDestinations"
            :options="formatDestinations"
            :searchable="true"
            mode="tags"
            attribute="destination_ids"
            model="third_party"
          />
        </div>
      </div>
    </div>

    <div class="flex mb-8">
      <div class="w-[40%]">
        <h1 class="text-xl font-bold">Bank details information</h1>
        <span class="text-gray-600">
          Complete all bank details information about third party, required for
          transactions or payments.
        </span>
      </div>
      <div class="w-[60%]">
        <div class="mb-4 string optional third_party_account_owner">
          <label
            class="block string optional text-sm font-medium text-gray-600"
            for="third_party_account_owner"
          >
            Account owner <abbr title="required">*</abbr>
          </label>
          <input
            v-model="data.accountOwner"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string optional"
            type="text"
            name="third_party[account_owner]"
            id="third_party_account_owner"
          />
        </div>

        <div class="mb-4 string optional third_party_account_number">
          <label
            class="block string optional text-sm font-medium text-gray-600"
            for="third_party_account_number"
          >
            Account software
          </label>
          <input
            v-model="data.accountNumber"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string optional"
            type="text"
            name="third_party[account_number]"
            id="third_party_account_number"
          />
        </div>

        <div class="mb-4 string optional third_party_iban">
          <label
            class="block string optional text-sm font-medium text-gray-600"
            for="third_party_iban"
          >
            IBAN
          </label>
          <input
            v-model="data.iban"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string optional"
            type="text"
            name="third_party[iban]"
            id="third_party_iban"
          />
          <p
            v-if="errors && errors.iban && errors && errors.iban[0]"
            class="text-red-400 text-sm mt-2"
          >
            {{ errors.iban[0] }}
          </p>
        </div>

        <div class="mb-4 string optional third_party_bic">
          <label
            class="block string optional text-sm font-medium text-gray-600"
            for="third_party_bic"
          >
            BIC
          </label>
          <input
            v-model="data.bic"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string optional"
            type="text"
            name="third_party[bic]"
            id="third_party_bic"
          />
        </div>

        <div class="mb-4 hidden third_party_kind">
          <input
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out hidden"
            value="client"
            autocomplete="off"
            type="hidden"
            name="third_party[kind]"
            id="third_party_kind"
          />
        </div>

        <div class="mb-4">
          <label
            class="text-sm font-medium text-gray-600 block file optional text-sm font-medium text-gray-600"
            for="third_party_bank_details_file"
          >
            Bank details file
          </label>
          <input
            class="w-full text-gray-500 px-3 py-2 border rounded file optional"
            type="file"
            name="third_party[bank_details_file]"
            id="third_party_bank_details_file"
            @change="onFileChange"
          />
        </div>
      </div>
    </div>

    <button
      type="submit"
      :disabled="disabledButton"
      class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
    >
      {{ isUpdate ? "Update Third party" : "Create Third party" }}
    </button>
  </form>
</template>
