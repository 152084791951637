import algoliasearch from "algoliasearch";

import { transformToCamelcase } from "./object";

import { RankingInfo } from "@algolia/client-search";

const ALGOLIA_ID: string = import.meta.env.VITE_ALGOLIA_ID as string;
const ALGOLIA_ADMIN_KEY: string = import.meta.env
  .VITE_ALGOLIA_ADMIN_KEY as string;
export const ALGOLIA_INDEX_PREFIX: string = import.meta.env
  .VITE_ALGOLIA_INDEX_PREFIX as string;

export const useAlgolia = (indexName: string) => {
  const CLIENT = algoliasearch(ALGOLIA_ID, ALGOLIA_ADMIN_KEY);
  const INDEX = CLIENT.initIndex(`${ALGOLIA_INDEX_PREFIX}_${indexName}`);

  return INDEX;
};

export type HitResponse = {
  objectID?: string;
  _highlightResult?: Record<string, unknown> | undefined;
  _snippetResult?: Record<string, unknown> | undefined;
  _rankingInfo?: RankingInfo | undefined;
  _distinctSeqID?: number | undefined;
  [key: number]: unknown;
};

export const transformHits = (response: HitResponse[]): HitResponse[] => {
  return response.map((res) => {
    delete res.objectID;
    delete res._highlightResult;

    return transformToCamelcase<HitResponse[]>(res);
  });
};
