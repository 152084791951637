<script setup lang="ts">
import { ref } from "vue";
import BaseModal from "@/components/BaseModal.vue";
import BaseForm from "@/components/BaseForm.vue";

import { formatWithLocalTime, formatWithUTC } from "@/helpers/dates";
type Props = {
  modelValue: boolean;
};

const emits = defineEmits(["update-modal", "update:modelValue"]);

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
});

const errors = ref();
const clientItinerary = ref(window?.gon?.client_itinerary);
const contract = ref(window?.gon?.contract);
const contractId = contract.value.id;

const successRequest = () => {
  emits("update-modal");
  errors.value = null;
};

const errorRequest = (err: { [key: string]: string }) => {
  errors.value = err;
};
</script>

<template>
  <BaseModal
    :model-value="props.modelValue"
    title="Notify clients of updates"
    hasFooter
    hasHeader
    @close-modal="$emit('update:modelValue', false)"
  >
    <template #content>
      <BaseForm
        :action="`/contracts/${contractId}/client_itineraries`"
        id="client_itinerary_client_notification"
        :on-success="successRequest"
        :on-error="errorRequest"
        method="put"
      >
        <p class="text-sm">
          Are you sure you want to send a notification? This notification will inform the
          client about the update of the services & reservations booked.
        </p>
        <p class="italic py-4 text-sm">Notification can be sent every two hours.</p>
        <p
          v-if="clientItinerary?.notification_information_updated_sent_at"
          class="font-bold pb-4 text-sm"
        >
          Last notification sent on
          {{
            formatWithUTC(
              clientItinerary.notification_information_updated_sent_at,
              "DD/MM/YYYY"
            )
          }}
          at
          {{
            formatWithUTC(
              clientItinerary.notification_information_updated_sent_at,
              "HH:mm"
            )
          }}
        </p>
        <input
          type="hidden"
          name="client_itinerary[notification_information_updated_sent_at]"
          :value="formatWithLocalTime(new Date(), 'HH:mm')"
        />
        <div class="flex justify-end mt-3">
          <button
            type="submit"
            class="inline-flex items-center px-3 py-2 ml-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary-700 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
          >
            Send notification
          </button>
        </div>
        <p class="text-red-400 text-sm mt-2" v-if="errors">
          The notification
          {{
            formatWithUTC(
              errors?.notification_information_updated_sent_at[0].value,
              "DD/MM/YYYY-HH:mm"
            )
          }}
          must be
          {{ errors?.notification_information_updated_sent_at[0].error }}
          {{
            formatWithUTC(
              errors?.notification_information_updated_sent_at[0].count,
              "DD/MM/YYYY-HH:mm"
            )
          }}
        </p>
      </BaseForm>
    </template>
  </BaseModal>
</template>
