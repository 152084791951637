<script setup>
import $ from "jquery";
import { computed, onMounted } from "vue";

const props = defineProps({
  onError: {
    type: Function,
    default: () => {},
  },
  onSuccess: {
    type: Function,
    default: () => {},
  },
  id: {
    type: String,
    default: "",
  },
  action: {
    type: String,
    required: true,
  },
  patch: {
    type: Boolean,
    default: false,
  },
  method: {
    type: String,
    default: "post",
  },
});

const token = computed(() =>
  document.getElementsByName("csrf-token")[0].getAttribute("content"),
);

const callback = (args, status) => {
  if (status === "on-success") props.onSuccess(args);
  if (status === "on-error") props.onError(args);
};

onMounted(() => {
  $(`#${props.id}`).on("ajax:success", function (_, data, status, xhr) {
    if (window.gon.errors && Object.entries(window.gon.errors).length) {
      callback(window.gon.errors, "on-error");
    } else {
      callback({}, "on-success");
    }
  });

  // Une fois qu'on envera la bonne erreur côté back
  // $(`#${props.name}`).on("ajax:error", function (_, xhr, status, error) {
  //   callback({ xhr, status, error }, "on-error");

  //   errors.value = window.gon.errors;
  // });
});
</script>

<template>
  <form
    :id="id"
    accept-charset="UTF-8"
    :method="method"
    :action="action"
    novalidate
    enctype="multipart/form-data"
    data-remote="true"
    @submit.prevent="submit"
  >
    <input
      type="hidden"
      name="authenticity_token"
      :value="token"
      autocomplete="off"
    />
    <input
      v-if="patch"
      type="hidden"
      name="_method"
      value="patch"
      autocomplete="off"
    />
    <slot />
  </form>
</template>
