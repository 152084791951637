<script setup lang="ts">
const emits = defineEmits(["update:modelValue"]);
defineProps({
  label: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  modelValue: {
    type: [String, Number, Boolean],
    default: "",
  },
  value: {
    type: [String, Number, Boolean],
    required: true,
  },
});

const updateInput = (e: Event & { target: HTMLInputElement }) => {
  emits("update:modelValue", e.target.value);
};
</script>

<template>
  <div class="flex items-center">
    <input
      :id="id"
      type="radio"
      :checked="modelValue === value"
      :value="value"
      :name="name"
      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      @change="updateInput"
    />
    <label
      :for="id"
      class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
    >
      {{ label }}
    </label>
  </div>
</template>
