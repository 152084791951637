<script setup lang="ts">
import { ref } from "vue";
import ClientItineraryIncludedServiceModal from "@/views/Contracts/ClientItinerary/components/ClientItineraryIncludedServiceModal.vue";

import type { Contract } from "@/types";

type ClientItinerary = {
  id: number;
  included_services: string;
};

type ClientItineraryChanges = {
  included_services: {
    date: string;
    time: string;
    user_name: string;
  };
};

const contract = ref(window.gon.contract) as unknown as Contract;
const clientItinerary = ref<ClientItinerary>(window.gon?.client_itinerary);
const clientItineraryChanges = ref<ClientItineraryChanges>(
  window.gon?.audited_changes,
);
const showModal = ref(false);
const selectedItinerary = ref<{
  includedServices: string;
}>();

const openModal = () => {
  selectedItinerary.value = {
    includedServices: clientItinerary.value.included_services || "",
  };
  showModal.value = true;
};

const updateModal = () => {
  clientItinerary.value = { ...window.gon?.client_itinerary };
  clientItineraryChanges.value = { ...window.gon?.audited_changes };

  showModal.value = false;
};
</script>

<template>
  <h2
    class="text-xl font-bold text-gray-900 sm:text-xl sm:truncate mt-10 px-2 pb-2"
  >
    Included Services
  </h2>
  <p
    v-if="clientItineraryChanges?.included_services?.date"
    class="font-medium leading-5 pb-4 px-2 rounded-md text-sm text-gray-500"
  >
    Last edit:
    {{ clientItineraryChanges.included_services.date }}
    at
    {{ clientItineraryChanges.included_services.time }}
    by
    {{ clientItineraryChanges.included_services.user_name }}
  </p>
  <div
    class="relative rounded-xl overflow-auto border border-solid divide-stone-200 shadow-lg"
  >
    <table class="table-auto max-w-[1432px] w-full">
      <thead class="text-sm text-gray-500 text-left sm:not-sr-only bg-gray-50">
        <tr>
          <th class="py-4 px-6 font-bold sm:table-cell" scope="col">
            Description
          </th>
          <th
            class="py-4 px-6 font-bold text-center sm:table-cell w-[107px]"
            scope="col"
          >
            Action
          </th>
        </tr>
      </thead>
      <tbody
        class="border-b border-gray-200 divide-y divide-gray-200 text-gray-900 sm:border-t"
      >
        <tr>
          <td class="py-3 px-6 font-medium">
            <div v-html="clientItinerary.included_services" />
          </td>
          <td class="py-3 px-6 font-medium">
            <button
              class="edit-link"
              data-modal-id="client_itinerary_79"
              type="button"
              @click="openModal"
            >
              <BaseIcon name="edit" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ClientItineraryIncludedServiceModal
    v-model="showModal"
    :clientItineraryId="clientItinerary.id"
    :contractId="contract.id"
    :selectedItinerary="selectedItinerary"
    @update-modal="updateModal"
  />
</template>
