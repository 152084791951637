<script setup lang="ts">
import { computed, reactive, ref } from "vue";

import BaseMultiselect from "@/components/BaseMultiselect.vue";
import type { Booking, Contract, TInputFields } from "@/types";

type dataIndividual = {
  clientAddress: string;
  clientCity: string;
  clientCountry: string;
  clientEmail: string;
  clientFirstname: string;
  clientLastname: string;
  clientPhone: string;
  clientZipcode: string;
};

const token = ref<string | null>(
  document.getElementsByName("csrf-token")[0].getAttribute("content"),
);

const booking = ref<Booking | undefined>(window.gon?.booking);
const countries = ref<object>(window.gon?.countries);
const currentQuote = ref(window.gon?.current_quote);
const contract = ref<Contract>(window.gon?.contract);

const data = reactive<dataIndividual>({
  clientAddress:
    currentQuote.value?.client_address || contract.value?.client_city,
  clientCity: currentQuote.value?.client_city || contract.value?.client_city,
  clientCountry:
    currentQuote.value?.client_country || contract.value?.client_country,
  clientEmail: currentQuote.value?.client_email || contract.value?.client_email,
  clientFirstname:
    currentQuote.value?.client_firstname || contract.value?.client_firstname,
  clientLastname:
    currentQuote.value?.client_lastname || contract.value?.client_lastname,
  clientPhone: currentQuote.value?.client_phone || contract.value?.client_phone,
  clientZipcode:
    currentQuote.value?.client_zip_code || contract.value?.client_postal_code,
});

const action = computed(() => `/bookings/${booking.value?.id}/quotes`);

const disabledButton = computed(() => {
  return !(
    data.clientLastname &&
    data.clientLastname &&
    data.clientPhone &&
    data.clientEmail &&
    data.clientAddress &&
    data.clientCity &&
    data.clientZipcode &&
    data.clientCountry
  );
});

const formatCountries = computed(() => {
  return Object.entries(countries.value).map(([key, value]) => {
    return {
      value: key,
      label: value,
    };
  });
});

const inputFields = ref<TInputFields[]>([
  {
    label: "First name",
    model: "clientFirstname",
    name: "booking_quote[client_firstname]",
    id: "booking_quote_client_firstname",
  },
  {
    label: "Last name",
    model: "clientLastname",
    name: "booking_quote[client_lastname]",
    id: "booking_quote_client_lastname",
  },
  {
    label: "Phone number",
    model: "clientPhone",
    name: "booking_quote[client_phone]",
    id: "booking_quote_client_phone",
  },
  {
    label: "Email",
    model: "clientEmail",
    name: "booking_quote[client_email]",
    id: "booking_quote_client_email",
    rules: {
      type: "email",
      required: true,
    },
  },
  {
    label: "Address",
    model: "clientAddress",
    name: "booking_quote[client_address]",
    id: "booking_quote_client_address",
  },
  {
    label: "Zip code",
    model: "clientZipcode",
    name: "booking_quote[client_zip_code]",
    id: "booking_quote_client_zip_code",
  },
  {
    label: "City",
    model: "clientCity",
    name: "booking_quote[client_city]",
    id: "booking_quote_client_city",
  },
]);
</script>

<template>
  <form
    :action="action"
    class="mt-4"
    enctype="multipart/form-data"
    accept-charset="UTF-8"
    method="post"
  >
    <input
      type="hidden"
      name="booking_quote[kind]"
      value="individual"
      autocomplete="off"
    />
    <input
      :value="token"
      type="hidden"
      name="authenticity_token"
      autocomplete="off"
    />

    <div
      v-for="field in inputFields"
      :key="field.id"
      :class="`mb-4 string required booking_quote_${field.model}`"
    >
      <label
        :for="field.id"
        class="block string required text-sm font-medium text-gray-600"
      >
        {{ field.label }} <abbr title="required">*</abbr>
      </label>
      <input
        v-model="data[field.model as keyof dataIndividual]"
        v-bind="field.rules ?? { type: 'text', required: true }"
        :name="field.name"
        :id="field.id"
        class="appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string required invalid:border-red-500"
      />
    </div>

    <div class="mb-4 email third_party_country">
      <label
        class="block email text-sm font-medium text-gray-600"
        for="third_party_country"
      >
        Country <abbr title="required">*</abbr>
      </label>
      <BaseMultiselect
        v-model="data.clientCountry"
        :options="formatCountries"
        :searchable="true"
        name="booking_quote[client_country]"
        id="booking_quote_client_country"
        attribute="client_country"
        model="booking_quote"
      />
    </div>

    <button
      :disabled="disabledButton"
      type="submit"
      class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 mx-2 disabled:bg-gray-100 disabled:text-gray-400 cursor-pointer"
    >
      Validate & create quote on Zoho Books
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="ml-2"
      >
        <path
          d="M4 13.334L12 13.334"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.99984 2.66602V10.666M7.99984 10.666L10.3332 8.33268M7.99984 10.666L5.6665 8.33268"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </form>
</template>
