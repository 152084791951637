<script setup lang="ts">
import { computed, ref } from "vue";

import queryParams from "../../helpers/queryParams";

export interface Props {
  label?: string;
  type: string;
}

const props = withDefaults(defineProps<Props>(), {
  label: "Checkbox",
  type: "favorite",
});

const params = queryParams();

const checkedBool = ref(false);

const formatCheckbox = computed(() => {
  params.get(props.type) === "true"
    ? (checkedBool.value = true)
    : (checkedBool.value = false);
  return checkedBool.value;
});

const changeParams = (type, e) => {
  if (e !== null && e !== undefined) {
    params.set(type, e);
  }
};

const deleteParams = (type) => {
  params.remove(type);
};

const updateCheckbox = (bool) => {
  bool ? changeParams(props.type, bool) : deleteParams(props.type);
};
</script>

<template>
  <div class="w-auto">
    <BaseCheckbox
      @box-event="updateCheckbox"
      :label="props.label"
      :option="formatCheckbox"
      :type="props.type.toLocaleLowerCase()"
    />
  </div>
</template>
