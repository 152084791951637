<script setup lang="ts">
import { ref, shallowRef } from "vue";

import NavbarLink from "./NavbarLink.vue";

import {
  BaseIconContract,
  BaseIconBookings,
  BaseIconPayIn,
  BaseIconBankExport,
  BaseIconCategories,
  BaseIconServices,
  BaseIconThirdParties,
} from "@/components/icons";

import { TMenu } from "@/types";

type Props = {
  isTidy: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  isTidy: false,
});

const menus = ref<TMenu[]>([
  {
    key: "pay-ins",
    componentIcon: shallowRef(BaseIconPayIn),
    label: "Pay-in",
    link: "/pay_ins",
  },
  {
    key: "contracts",
    componentIcon: shallowRef(BaseIconContract),
    label: "Contracts",
    link: "/contracts",
  },
  {
    key: "bookings",
    componentIcon: shallowRef(BaseIconBookings),
    label: "Bookings",
    link: "/bookings",
  },
  {
    key: "bank_export",
    componentIcon: shallowRef(BaseIconBankExport),
    label: "Bank export",
    link: "/bank_exports",
  },
  {
    key: "third_parties",
    componentIcon: shallowRef(BaseIconThirdParties),
    label: "Third parties",
    link: "/third_parties",
  },
  {
    key: "services",
    componentIcon: shallowRef(BaseIconServices),
    label: "Services",
    link: "/services",
  },
  {
    key: "categories",
    componentIcon: shallowRef(BaseIconCategories),
    label: "Categories",
    link: "/categories",
  },
]);
</script>

<template>
  <nav :class="['flex flex-col']">
    <div class="pt-4">
      <nav class="px-4">
        <div v-for="menu in menus" :key="menu.key" class="cursor-pointer h-16">
          <navbar-link :menu="menu" :show-text="props.isTidy" />
        </div>
      </nav>
    </div>
  </nav>
</template>
