<script setup lang="ts">
import { computed } from "vue";

type Props = {
  isSelected: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  isSelected: false,
});

const colors = computed(() => ({
  rect: props.isSelected ? "#033535" : "white",
  path: props.isSelected ? "white" : "#757575",
}));
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <rect width="40" height="40" rx="4" :fill="colors.rect" />
    <path
      d="M23 12V10M23 12V14M23 12H18.5M11 18V27C11 28.1046 11.8954 29 13 29H27C28.1046 29 29 28.1046 29 27V18H11Z"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 18V14C11 12.8954 11.8954 12 13 12H15"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 10V14"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29 18V14C29 12.8954 28.1046 12 27 12H26.5"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
