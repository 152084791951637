<script setup lang="ts">
import { ref, reactive } from "vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";

import BaseInput from "@/components/BaseInput.vue";

import type { PropType } from "vue";
import type { ClientItineraryData } from "../composables/useFormData";

const emits = defineEmits(["update-errors", "update-phone"]);

const props = defineProps({
  errors: {
    type: Object as PropType<Record<string, string[]>>,
    default: null,
  },
  formData: {
    type: Object as PropType<ClientItineraryData>,
    required: true,
  },
  filledInput: {
    type: Boolean,
    default: false,
  },
  showTitle: {
    type: Boolean,
    default: true,
  },
});

const changeInput = (type: keyof ClientItineraryData) => {
  emits("update-errors", type, null);
};
const isPhoneInvalid = ref(false);

const updatePhoneValidity = (containsLetters: boolean) => {
  isPhoneInvalid.value = containsLetters;
};

let data = reactive({
  address: "",
  zip_code: "",
  city: "",
  gpslatitude: "",
  gpslongitude: "",
  website: "",
  phone: "",
});

const phoneError = ref<string | null>(null);

const onInput = (number: string, phoneObject: { formatted: string; valid: boolean }) => {
  if (props.filledInput) {
    data.phone = phoneObject.formatted;
  }
};

const onValidate = (phoneObject: { valid: boolean | undefined }) => {
  if (phoneObject.valid) {
    phoneError.value = null;
  } else {
    phoneError.value = "Le numéro de téléphone est invalide.";
  }
  const hasValidProperty = phoneObject.valid !== undefined;
  emits("update-phone", !phoneObject.valid && hasValidProperty);
};

if (props.filledInput) {
  data = props.formData;
}
</script>

<template>
  <div class="grid grid-cols-12 gap-4">
    <div class="col-span-12">
      <BaseInput
        class-wrapper=""
        id="appointment_address"
        label="Address"
        :modelValue="data.address"
        name="appointment[address]"
        @update:model-value="changeInput('address')"
        type="text"
      />
    </div>
    <div class="col-span-6">
      <BaseInput
        class-wrapper=""
        id="appointment_zip_code"
        label="Postcode"
        :modelValue="data.zip_code"
        name="appointment[zip_code]"
        @update:model-value="changeInput('zip_code')"
        type="text"
      />
    </div>
    <div class="col-span-6">
      <BaseInput
        class-wrapper=""
        id="appointment_city"
        label="City"
        :modelValue="data.city"
        name="appointment[city]"
        @update:model-value="changeInput('city')"
        type="text"
      />
    </div>
    <div class="col-span-6">
      <BaseInput
        required
        class-wrapper=""
        :errors="
          errors?.gpslatitude && Array.isArray(errors.gpslatitude)
            ? errors.gpslatitude[0].error
            : null
        "
        id="appointment_gpslatitude"
        label="Latitude"
        :modelValue="data.gpslatitude"
        name="appointment[gpslatitude]"
        @update:model-value="changeInput('gpslatitude')"
        type="text"
      />
    </div>
    <div class="col-span-6">
      <BaseInput
        required
        class-wrapper=""
        :errors="
          errors?.gpslongitude && Array.isArray(errors.gpslongitude)
            ? errors.gpslongitude[0].error
            : null
        "
        id="appointment_gpslongitude"
        label="Longitude"
        :modelValue="data.gpslongitude || null || undefined"
        name="appointment[gpslongitude]"
        @update:model-value="changeInput('gpslongitude')"
        type="text"
      />
    </div>

    <div class="col-span-12" v-if="showTitle">
      <fieldset class="font-bold text-[14px] mt-8">Service Details</fieldset>
    </div>

    <div class="col-span-6">
      <BaseInput
        class-wrapper=""
        id="appointment_website"
        label="Website link"
        :modelValue="data.website || null || undefined"
        name="appointment[website]"
        type="text"
      />
    </div>
    <div class="col-span-6">
      <label for="base-tel-input" class="mb-2 block label"> Phone number </label>
      <VueTelInput
        class="shadow appearance-none border border-gray-300 rounded w-full bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-800 leading-6 transition-colors duration-200 ease-in-out"
        id="base-tel-input"
        :model-value="data.phone || null || undefined"
        :auto-default-country="false"
        :preferred-countries="['fr', 'gb', 'us']"
        data-testid="base-tel-input"
        mode="international"
        :validCharactersOnly="true"
        v-bind="$attrs"
        @on-input="onInput"
        @validate="onValidate"
      />
      <input type="hidden" name="appointment[phone]" :value="data.phone" />
      <p v-if="phoneError && data.phone" class="text-red-500 text-sm mt-1">
        {{ phoneError }}
      </p>
    </div>
  </div>
</template>

<style>
.vue-tel-input {
  @apply mt-0;
}
</style>
