import $ from "jquery";

const syncPayOutSelection = () => {
  var checkboxes = document.getElementsByClassName("pay-out-selection");

  for (var i = 0; i < checkboxes.length; i++) {
    checkboxes[i].addEventListener(
      "input",
      function (e) {
        const selectedPayOutId = e.target.dataset.payOutId;

        const formCheckbox = document.getElementById(
          `bank_export_pay_out_ids_${selectedPayOutId}`,
        );
        formCheckbox.checked = e.srcElement.checked;
      },
      false,
    );
  }
};

//// Retro commission
const syncBookingServiceSelection = () => {
  const selectAllCheckbox = $("#all_retrocommission");

  // Sélectionnez toutes les autres cases à cocher à l'intérieur du tableau
  const otherCheckboxes = $('input[name="invoice[booking_service_ids][]"]');

  // Ajoutez un gestionnaire d'événements de clic à la case "all_retrocommission"
  selectAllCheckbox.on("click", function () {
    // Obtenez l'état de la case "all_retrocommission"
    const isChecked = selectAllCheckbox.prop("checked");

    // Appliquez cet état à toutes les autres cases à cocher
    otherCheckboxes.prop("checked", isChecked);
  });
};

const ServicesCreatePrice = () => {
  // Services enabled
  const VATPrice = $("#price_supplier_vat_rate");

  // Define variable
  const SupplierPublicPriceIncVat = $("#supplier_public_price");
  const SupplierPublicPriceExclVAT = $(
    "#price_supplier_public_price_without_vat",
  );
  const TargetLCCommission = $("#price_lc_target_commission_rate");
  const SupplierDiscountRate = $("#price_supplier_discount_rate");
  const ServiceDescriptionBtn = $(".copy-description-btn");

  // Services disabled
  const SupplierPriceIncVat = $("#supplier_price_incl_vat");
  const SupplierPriceExlVat = $("#supplier_price_excl_vat");
  const ClientPriceIncl = $("#client_price_incl");
  const ClientPriceExcl = $("#client_price_excl");
  const LCCommissionIncl = $("#lc_com_incl");
  const LCCommissionExcl = $("#lc_com_excl");
  const RetroComIncl = $("#retro_com_incl");
  const RetroComExcl = $("#retro_com_excl");

  const fullCommissionThirdParty = $("#full_commission_third_party");

  // vat method
  const transformVAT = (vat) => {
    return 1 + Number(vat) / 100;
  };
  const VAT = () => {
    const VAT = VATPrice.val();

    return transformVAT(VAT);
  };

  // Calculate method
  const calculSupplierPriceIncVat = () => {
    if (SupplierPublicPriceExclVAT.val() && SupplierPublicPriceIncVat.val()) {
      const newSupplierPublicPriceIncVat =
        Number(SupplierPublicPriceExclVAT.val()) * VAT();

      SupplierPublicPriceIncVat.val(newSupplierPublicPriceIncVat.toFixed(2));
      SupplierPriceIncVat.val(newSupplierPublicPriceIncVat.toFixed(2));
    } else if (SupplierPublicPriceExclVAT.val()) {
      const newSupplierPublicPriceIncVat =
        Number(SupplierPublicPriceExclVAT.val()) * VAT();

      SupplierPublicPriceIncVat.val(newSupplierPublicPriceIncVat.toFixed(2));
    }
  };
  const calculSupplierPrinceExclVat = () => {
    const newSupplierPublicPriceExclVAT =
      SupplierPublicPriceIncVat.val() / VAT();

    SupplierPublicPriceExclVAT.val(newSupplierPublicPriceExclVAT.toFixed(4));
  };
  const calculClientPrice = () => {
    const newClientPriceExcl =
      Number(SupplierPriceExlVat.val()) /
      (1 - Number(TargetLCCommission.val()) / 100);
    ClientPriceExcl.val(newClientPriceExcl.toFixed(2));

    const newClientPriceIncl =
      Number(SupplierPriceIncVat.val()) /
      (1 - Number(TargetLCCommission.val()) / 100);

    ClientPriceIncl.val(newClientPriceIncl.toFixed(2));
  };
  const calculLCCommission = () => {
    if (fullCommissionThirdParty.length) {
      const newLCCommission = Number(ClientPriceIncl.val());
      LCCommissionIncl.val(newLCCommission.toFixed(2));

      const newLCCommissionExcl = Number(ClientPriceExcl.val());
      LCCommissionExcl.val(newLCCommissionExcl.toFixed(2));
    } else {
      const newLCCommission =
        Number(ClientPriceIncl.val()) - Number(SupplierPriceIncVat.val());

      LCCommissionIncl.val(newLCCommission.toFixed(2));

      const newLCCommissionExcl =
        Number(ClientPriceExcl.val()) - Number(SupplierPriceExlVat.val());

      LCCommissionExcl.val(newLCCommissionExcl.toFixed(2));
    }
  };
  const calculSupplierPriceExclAndIncl = () => {
    const newSupplierPublicPriceIncVat =
      Number(SupplierPublicPriceExclVAT.val()) * VAT();

    SupplierPriceIncVat.val(newSupplierPublicPriceIncVat.toFixed(4));
    SupplierPriceExlVat.val(SupplierPublicPriceExclVAT.val());
  };
  const calculSupplierPriceIncVatWithLCCommission = () => {
    const newClientPriceIncl =
      Number(SupplierPublicPriceIncVat.val()) *
      (1 - Number(SupplierDiscountRate.val()) / 100);

    SupplierPriceIncVat.val(newClientPriceIncl.toFixed(2));
    SupplierPriceExlVat.val((newClientPriceIncl / VAT()).toFixed(2));
  };
  const calculRetroCommissionIncl = () => {
    const newRetroComIncl =
      SupplierPublicPriceIncVat.val() * (SupplierDiscountRate.val() / 100);
    const newRetroComExcl =
      SupplierPublicPriceExclVAT.val() * (SupplierDiscountRate.val() / 100);

    RetroComIncl.val(newRetroComIncl.toFixed(2));
    RetroComExcl.val(newRetroComExcl.toFixed(2));
  };

  const calculAllDisabledFields = () => {
    calculSupplierPriceIncVat();
    calculSupplierPrinceExclVat();
    calculSupplierPriceExclAndIncl();
    if (SupplierDiscountRate.val()) {
      calculSupplierPriceIncVatWithLCCommission();
    }
    calculClientPrice();
    calculLCCommission();
    calculRetroCommissionIncl();
  };

  const handleDisplayNoticeModal = (message) => {
    let modalContainer = $("#modal-container");

    if (!modalContainer.length) {
      modalContainer = $('<div id="modal-container"></div>');
      $("body").append(modalContainer);
    }

    const modalHTML = `
    <div id="notice-alert" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-10" aria-live="assertive">
      <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
        <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-center">
              <div class="w-0 flex-1 flex justify-between">
                <p class="w-0 flex-1 text-sm font-medium text-gray-900">
                  ${message}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;

    modalContainer.html(modalHTML);
    modalContainer.show().delay(2000).fadeOut("slow");
  };

  const handleCloneDescriptionService = (element) => {
    const ServiceDescriptionText = $(element)
      .closest("tr")
      .find(".booking-description-text");
    ServiceDescriptionText.removeClass("hidden");

    const textareaContent = ServiceDescriptionText.val();
    const tempElement = ServiceDescriptionText.text(textareaContent);

    $("body").append(tempElement);
    tempElement.trigger("select");
    navigator.clipboard.writeText(textareaContent);
    tempElement.remove();
    ServiceDescriptionText.addClass("hidden");
  };

  // Listen simple VAT or supplier VAT
  VATPrice.on("input", function () {
    calculAllDisabledFields();
  });

  // Listen Supplier public price EXCL VAT
  SupplierPublicPriceExclVAT.on("input", function () {
    calculSupplierPriceIncVat();
    calculSupplierPriceExclAndIncl();
    if (SupplierDiscountRate.val()) {
      calculSupplierPriceIncVatWithLCCommission();
    }
    calculClientPrice();
    calculLCCommission();
    calculRetroCommissionIncl();
  });

  // Listen Supplier public price INCL VAT
  SupplierPublicPriceIncVat.on("input", function () {
    calculSupplierPrinceExclVat();

    // Fill Supplier price
    calculSupplierPriceExclAndIncl();
    if (SupplierDiscountRate.val()) {
      calculSupplierPriceIncVatWithLCCommission();
    }
    calculClientPrice();
    calculLCCommission();
    calculRetroCommissionIncl();
  });

  // Listen input Target LC% commission
  TargetLCCommission.on("input", function () {
    calculSupplierPriceExclAndIncl();
    if (SupplierDiscountRate.val()) {
      calculSupplierPriceIncVatWithLCCommission();
    }

    // Fill Supplier price
    calculClientPrice();
    calculLCCommission();
    calculRetroCommissionIncl();
  });

  // Listen % Supplier discount
  SupplierDiscountRate.on("input", function () {
    calculSupplierPriceIncVatWithLCCommission();

    // Fill Supplier price
    calculClientPrice();
    calculLCCommission();
    calculRetroCommissionIncl();
  });

  // Booking service
  ServiceDescriptionBtn.on("click", function () {
    handleCloneDescriptionService(this);
    handleDisplayNoticeModal("Service description copied in your clipboard");
  });

  // Edit page
  if (document.location.pathname.includes("edit")) {
    calculAllDisabledFields();
  }
};

export {
  ServicesCreatePrice,
  syncPayOutSelection,
  syncBookingServiceSelection,
};

$("#dropdownDefaultButton").on("click", function () {
  $("#dropdown").toggle();
});

$(document).ready(function () {
  $("#dropdown").hide();

  $("#dropdownDefaultButton").on("click", function () {
    $("#dropdown").toggle();
  });

  $(document).on("click", function (event) {
    if (!$(event.target).closest("#dropdownDefaultButton, #dropdown").length) {
      $("#dropdown").hide();
    }
  });
});
