<script setup lang="ts">
import { ref, computed } from "vue";
import { clientApi } from "@/api/config";

import BaseModal from "@/components/BaseModal.vue";
import BaseForm from "@/components/BaseForm.vue";
import BaseInput from "@/components/BaseInput.vue";

type Props = {
  modelValue: boolean;
  contractId: number;
  guestId: string;
};

type ErrorResponse = {
  errors: {
    guest_contact: {
      email: {
        error: string;
        value: string;
      }[];
    };
  }[];
};

const emits = defineEmits<{
  "update-modal": [];
  "update:model-value": [boolean];
}>();

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  contractId: null,
  guestId: "",
});
const VITE_API_BASEURL = import.meta.env.VITE_API_BASEURL;
const api = clientApi.client;
const errors = ref<ErrorResponse | null>(null);

const submitForm = async () => {
  try {
    const response = await api.guestContracts.delete(props.contractId, props.guestId);

    if (response) {
      emits("update:modelValue", false);
      emits("update-modal");
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      errors.value = error.response.data;
    } else {
      console.error(error);
    }
  }
};

const mapErrors = (errors: ErrorResponse | null) => {
  if (!errors) return null;
  const emailError = errors?.errors[0]?.guest_contact?.email[0]?.error;
  return emailError ? `the email has ${emailError}` : null;
};
</script>

<template>
  <BaseModal
    :model-value="props.modelValue"
    :title="`Delete guest from stay`"
    hasFooter
    hasHeader
    @close-modal="$emit('update:modelValue', false)"
  >
    <template #content>
      <p class="text-sm text-slate-600">
        Are you sure you want to remove this client from your stay? This action is
        irreversible, and all information associated with this client will be permanently
        lost.
      </p>
      <form id="client_itinerary_guest_delete" @submit.prevent="submitForm">
        <div class="flex justify-end mt-3">
          <button
            type="button"
            class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-primary-700 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
            @click="$emit('update:modelValue', false)"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="inline-flex items-center px-3 py-2 ml-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white text-red-600 bg-red-600 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
          >
            Delete
          </button>
        </div>
      </form>
    </template>
  </BaseModal>
</template>
