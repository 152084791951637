<script setup lang="ts">
import { inject } from "vue";

const props = defineProps<{
  isTidy: boolean;
}>();

const currentUser = inject("currentUser", {
  email: "",
  firstName: "",
  lastName: "",
});
</script>

<template>
  <div class="flex justify-center items-center h-8">
    <div>
      <div class="user-icon w-[20%]" />
    </div>
    <div v-if="!props.isTidy" class="w-[80%] pl-1">
      <div class="truncate font-bold">
        {{ currentUser?.firstName }} {{ currentUser?.lastName }}
      </div>
      <div class="truncate text-xs">{{ currentUser?.email }}</div>
    </div>
  </div>
</template>

<style scoped>
.user-icon {
  width: 1.7rem;
  height: 1.7rem;
  border: 1px solid black;
  border-radius: 50%;
}
</style>
