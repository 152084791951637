<script setup lang="ts">
import { computed, ref } from "vue";

import queryParams from "@/helpers/queryParams";

import BaseMultiselect from "@/components/BaseMultiselect.vue";

import {
  Destination,
  Category,
  PropsThirdParty,
  Status,
} from "@/types/ThirdParty";

const props = withDefaults(defineProps<PropsThirdParty>(), {
  placeHolder: "Filter",
  type: "destination",
});

const gon = window.gon;

const statuses = gon.statuses as Status[];
const destinations = gon.destinations as Destination[];
const categories = gon.categories as Category[];
const params = queryParams();
const selectedStatus = ref<string | null>(params.get(props.type));

const formatDestinations = computed(() => {
  return destinations.map((destination) => ({
    value: destination.id,
    label: destination.name.en,
  }));
});

const formatStatus = computed(() => {
  return statuses.map((status) => status);
});

const formatCategories = computed(() => {
  return categories.map((category: Category) => {
    return {
      value: category.id,
      label: category.name,
    };
  });
});

const changeParams = (type: string, e: any) => {
  if (e !== null && e !== undefined) {
    params.set(type, e);
  }
};

const deleteParams = (type: string) => {
  params.remove(type);
};

const selectedOptions = (type: string) => {
  if (type === "destination") {
    return formatDestinations.value;
  } else if (type === "category") {
    return formatCategories.value;
  } else {
    return formatStatus.value;
  }
};
</script>

<template>
  <div class="w-full">
    <BaseMultiselect
      v-model="selectedStatus"
      :searchable="true"
      :placeholder="props.placeHolder"
      :options="selectedOptions(props.type)"
      :attribute="props.type"
      :model="props.type"
      @change="changeParams(props.type, $event)"
      @close="deleteParams(props.type)"
    />
  </div>
</template>
