<script setup lang="ts">
import { reactive, ref, provide } from "vue";

import SidebarMenu from "./SidebarMenu.vue";
import BaseLogo from "@/components/BaseLogo.vue";
import BaseSmallLogo from "@/components/BaseSmallLogo.vue";
import BaseIconArrowMenu from "@/components/icons/BaseIconArrowMenu.vue";
import UserInfo from "./UserInfo.vue";

type TCurrentUser = {
  email: string;
  firstName: string;
  lastName: string;
};

const props = defineProps<{
  email: string;
  firstName: string;
  lastName: string;
}>();

const currentUser = reactive<TCurrentUser>({
  email: props.email,
  firstName: props.firstName,
  lastName: props.lastName,
});

provide("currentUser", currentUser);

const isTidyString = localStorage?.getItem("isTidyTailor");
const isTidy = ref<boolean>(isTidyString ? JSON.parse(isTidyString) : false);

const toggle = () => {
  isTidy.value = !isTidy.value;
  localStorage.setItem("isTidyTailor", JSON.stringify(isTidy.value));
};

const logout = async () => {
  const destroyUserSessionPath: string | undefined =
    document.getElementById("rails-routes")?.dataset.destroyUserSessionPath;
  const csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    ?.getAttribute("content");

  if (csrfToken && destroyUserSessionPath) {
    const headers = {
      "X-CSRF-Token": csrfToken,
    };
    fetch(destroyUserSessionPath as string, {
      method: "DELETE",
      credentials: "include",
      headers: headers,
    })
      .then((response: Response) => {
        if (response.ok) {
          window.location.reload();
        } else {
          console.error("Failed to sign out");
        }
      })
      .catch((error: Error) => {
        console.error("Error signing out:", error);
      });
  }
};
</script>

<template>
  <div
    :class="[
      'bg-white lg:flex h-[100%] z-10 flex flex-col justify-between duration-300 border-r',
      { 'w-20': isTidy },
      { 'w-60': !isTidy },
    ]"
  >
    <div>
      <div class="h-28 flex justify-center items-center">
        <base-logo v-if="!isTidy" />
        <base-small-logo
          v-else
          name="smallLogoLc"
          class="flex justify-center items-center"
        />
      </div>
      <div
        :class="[
          'flex border-t border-gray-200 px-6 pt-4 h-16',
          {
            'justify-center': isTidy,
            'justify-end': !isTidy,
          },
        ]"
      >
        <button @click="toggle">
          <BaseIconArrowMenu :class="{ 'arrow-menu-reversed': !isTidy }" />
        </button>
      </div>
    </div>
    <sidebar-menu :isTidy="isTidy" />
    <div class="h-16 px-4 pb-20">
      <user-info :isTidy="isTidy" />
      <button v-if="!isTidy" class="pl-10 text-sm font-bold" @click="logout">
        Log out
      </button>
    </div>
  </div>
</template>

<style scoped>
.arrow-menu-reversed {
  transform: rotate(180deg);
}
</style>
