<script setup lang="ts">
import { computed, reactive, ref } from "vue";

import BaseMultiselect from "@/components/BaseMultiselect.vue";
import Multiselect from "@vueform/multiselect";
import BaseForm from "@/components/BaseForm.vue";

import type {
  Contact,
  Category,
  Destination,
  Link,
  ThirdParty,
  PaymentError,
  Item,
} from "@/types";

type TValidation = {
  [key: number]: boolean;
};

type TData = {
  accountNumber: string | null;
  accountOwner: string | null;
  address: string;
  bankName: string | null;
  beneficiaryAddress: string | null;
  bic: string;
  city: string;
  comment: string;
  commissionType: string | null;
  commissionVatRegime: string;
  country: string;
  enabled: boolean;
  favorite: boolean;
  fixedCommissionRate: number | null;
  hasPriceAttached?: boolean;
  iban: string;
  invoicingType: string | null;
  mainContactEmail: string;
  maximumCommissionRate: number | null;
  minimumCommissionRate: number | null;
  name: string;
  vatNumber: string | null;
  vatRate: number;
  zipCode: string;
};

const token = ref<string | null>(
  document.getElementsByName("csrf-token")[0].getAttribute("content"),
);
const categories = ref<Category[]>(window.gon?.categories);
const commissionVatRegimes = ref<Item[]>(window.gon?.commissionVatRegimes);
const errors = ref<PaymentError | undefined>(window.gon?.errors);
const contacts = ref(<Contact[]>window.gon?.contacts || []);
const contactTypes = ref<Item[]>(window.gon?.contactTypes || []);
const countryOptions = ref<Item[]>(window.gon?.countryOptions || []);
const destinations = ref<Destination[]>(window.gon?.destinations);
const links = ref<Link[]>(window.gon?.links || []);
const thirdParty = ref<ThirdParty>(window.gon?.thirdParty);
const thirdPartyDestinations = ref<number[] | undefined>(
  window.gon?.thirdPartyDestinations || [],
);
const thirdPartyCategories = ref<number[] | undefined>(
  window.gon?.thirdPartyCategories || [],
);
const thirdPartyHasPricesAttached = ref<boolean | undefined>(
  window.gon?.thirdPartyHasPricesAttached || false,
);
const thirdPartyInvoicingTypes = ref<string[] | undefined>(
  window.gon?.thirdPartyInvoicingTypes || [],
);
const validation = ref<TValidation>({});

const data = reactive<TData>({
  accountNumber: thirdParty.value?.account_number,
  accountOwner: thirdParty.value?.account_owner,
  address: thirdParty.value?.address,
  bankName: thirdParty.value?.bank_name,
  beneficiaryAddress: thirdParty.value?.beneficiary_address,
  bic: thirdParty.value?.bic,
  city: thirdParty.value?.city,
  comment: thirdParty.value?.comment ?? "",
  commissionType: thirdParty.value?.commission_type,
  commissionVatRegime: thirdParty.value?.commission_vat_regime,
  country: thirdParty.value?.country,
  enabled: thirdParty.value?.enabled,
  favorite: thirdParty.value?.favorite,
  fixedCommissionRate: thirdParty.value?.fixed_commission_rate,
  hasPriceAttached: thirdPartyHasPricesAttached.value,
  iban: thirdParty.value?.iban,
  invoicingType: thirdParty.value?.invoicing_type,
  mainContactEmail: thirdParty.value?.main_contact_email,
  maximumCommissionRate: thirdParty.value?.maximum_commission_rate,
  minimumCommissionRate: thirdParty.value?.minimum_commission_rate,
  name: thirdParty.value?.name,
  vatNumber: thirdParty.value?.vat_number,
  vatRate: thirdParty.value?.vat_rate || 0,
  zipCode: thirdParty.value?.zip_code,
});

const filteredContactTypes = computed(() => {
  return contactTypes.value.map((contactType: Item) => {
    return {
      ...contactType,
      disabled:
        contacts.value.some(
          (contact: Contact) => contact.contact_type === "legal_representative",
        ) && contactType.value === "legal_representative",
    };
  });
});

const isUpdate = computed(() => {
  return thirdParty.value?.id;
});
const action = computed(() => {
  if (isUpdate.value) return `/third_parties/${thirdParty.value.id}`;

  return "/third_parties";
});
const formatCategories = computed(() => {
  return categories.value.map((category: Category) => {
    return {
      value: category.id,
      label: category.name,
    };
  });
});
const formatCommissionVatRegimes = computed(() => {
  return commissionVatRegimes.value.map((commissionVATRegime: Item) => {
    return commissionVATRegime;
  });
});
const formatCountries = computed(() => {
  return countryOptions.value.map((country: Item) => {
    return country;
  });
});
const formatDestinations = computed(() => {
  return destinations.value.map((desti: Destination) => {
    return {
      value: desti.id,
      label: desti.name.en,
    };
  });
});
const disabledButton = computed(() => {
  const commissionTypeVariable =
    (data.commissionType === "variable" &&
      data.minimumCommissionRate != undefined &&
      data.maximumCommissionRate) ||
    (data.commissionType === "fixed" &&
      data.fixedCommissionRate != undefined &&
      data.fixedCommissionRate.toString() !== "");

  const validateLinksUrl = Object.values(validation.value)?.every(
    (valid) => valid,
  );
  const isVatRate = () => {
    if (
      data.vatRate.toString() !== "" &&
      data.vatRate >= 0 &&
      data.vatRate <= 100
    ) {
      return true;
    }

    return false;
  };

  if (
    commissionTypeVariable &&
    data.invoicingType &&
    data.mainContactEmail &&
    isVatRate() &&
    data.name &&
    data.accountOwner &&
    thirdPartyCategories.value?.length &&
    thirdPartyDestinations.value?.length &&
    validateLinksUrl &&
    data.commissionVatRegime
  ) {
    return false;
  }

  return true;
});

const validateLinksUrl = (index: number, url: string) => {
  try {
    new URL(url);
    validation.value[index] = true;
  } catch (err) {
    validation.value[index] = false;
  }
};

const addContact = () => {
  contacts.value.push({
    contactType: "",
    email: "",
    phone: "",
    name: "",
    id: null,
  });
};

const addLinks = () => {
  links.value.push({
    name: "",
    url: "",
    id: null,
  });
};
</script>

<template>
  <form
    :action="action"
    class="mt-8"
    novalidate
    enctype="multipart/form-data"
    accept-charset="UTF-8"
    method="post"
  >
    <input
      v-if="isUpdate"
      type="hidden"
      name="_method"
      value="patch"
      autocomplete="off"
    />
    <input
      type="hidden"
      :value="token"
      name="authenticity_token"
      autocomplete="off"
    />
    <div class="flex mb-4">
      <div class="w-[40%]">
        <h1 class="text-xl font-bold">General information</h1>
        <span class="text-gray-600"
          >Complete all general information about third party.</span
        >
      </div>
      <div class="w-[60%]">
        <div class="mb-4 string required third_party_name">
          <label
            class="block string required text-sm font-medium text-gray-600"
            for="third_party_name"
          >
            Name <abbr title="required">*</abbr>
          </label>
          <input
            v-model="data.name"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string required"
            type="text"
            name="third_party[name]"
            id="third_party_name"
          />
        </div>
        <div class="mb-4 hidden third_party_kind">
          <input
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out hidden"
            value="provider"
            autocomplete="off"
            type="hidden"
            name="third_party[kind]"
            id="third_party_kind"
          />
        </div>

        <div class="mb-4 email required third_party_main_contact_email">
          <label
            class="block email required text-sm font-medium text-gray-600"
            for="third_party_main_contact_email"
          >
            Main contact email <abbr title="required">*</abbr>
          </label>
          <input
            v-model="data.mainContactEmail"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string email required"
            required
            aria-required="true"
            type="email"
            name="third_party[main_contact_email]"
            id="third_party_main_contact_email"
          />
        </div>

        <div class="mb-4 email third_party_address">
          <label
            class="block email text-sm font-medium text-gray-600"
            for="third_party_address"
          >
            Address
          </label>
          <input
            v-model="data.address"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string email"
            type="text"
            name="third_party[address]"
            id="third_party_address"
          />
        </div>

        <div class="mb-4 email third_party_zip_code">
          <label
            class="block email text-sm font-medium text-gray-600"
            for="third_party_zip_code"
          >
            Zip Code
          </label>
          <input
            v-model="data.zipCode"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string email"
            type="text"
            name="third_party[zip_code]"
            id="third_party_zip_code"
          />
        </div>

        <div class="mb-4 email third_party_city">
          <label
            class="block email text-sm font-medium text-gray-600"
            for="third_party_city"
          >
            City
          </label>
          <input
            v-model="data.city"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string email"
            type="text"
            name="third_party[city]"
            id="third_party_city"
          />
        </div>

        <div class="mb-4 email third_party_country">
          <label
            class="block email text-sm font-medium text-gray-600"
            for="third_party_country"
          >
            Country
          </label>
          <BaseMultiselect
            v-model="data.country"
            :options="formatCountries"
            :searchable="true"
            attribute="country"
            model="third_party"
          />
        </div>

        <div class="mb-4 string optional third_party_vat_number">
          <label
            class="block string optional text-sm font-medium text-gray-600"
            for="third_party_vat_number"
          >
            Vat number
          </label>
          <input
            v-model="data.vatNumber"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string optional"
            type="text"
            name="third_party[vat_number]"
            id="third_party_vat_number"
          />
        </div>

        <div class="my-4 check_boxes required third_party_categories">
          <legend class="text-sm font-medium text-gray-600">
            Categories <abbr title="required">*</abbr>
          </legend>

          <BaseMultiselect
            v-model="thirdPartyCategories"
            :options="formatCategories"
            :searchable="true"
            mode="tags"
            attribute="category_ids"
            model="third_party"
          />
        </div>

        <div class="mb-4">
          <label
            class="block select optional text-sm font-medium text-gray-600"
            for="third_party_destination_ids"
          >
            Destinations <abbr title="required">*</abbr>
          </label>

          <BaseMultiselect
            v-model="thirdPartyDestinations"
            :options="formatDestinations"
            :searchable="true"
            mode="tags"
            attribute="destination_ids"
            model="third_party"
          />
        </div>

        <div class="flex">
          <div
            class="mb-4 flex items-start boolean optional third_party_enabled"
          >
            <div class="flex items-center h-5">
              <input
                name="third_party[enabled]"
                type="hidden"
                value="0"
                autocomplete="off"
              />
              <input
                v-model="data.enabled"
                class="focus:ring-2 focus:ring-primary-500:focus ring-offset-2 h-4 w-4 text-primary-600 border-gray-300 rounded boolean optional"
                type="checkbox"
                value="1"
                checked
                name="third_party[enabled]"
                id="third_party_enabled"
              />
            </div>
            <div class="ml-3 text-sm">
              <label
                class="block boolean optional text-sm font-bold"
                for="third_party_enabled"
              >
                Enabled
              </label>
            </div>
          </div>
          <div
            class="mb-4 ml-4 flex items-start boolean optional third_party_favorite"
          >
            <div class="flex items-center h-5">
              <input
                name="third_party[favorite]"
                type="hidden"
                value="0"
                autocomplete="off"
              />
              <input
                v-model="data.favorite"
                class="focus:ring-2 focus:ring-primary-500:focus ring-offset-2 h-4 w-4 text-primary-600 border-gray-300 rounded boolean optional"
                type="checkbox"
                value="1"
                name="third_party[favorite]"
                id="third_party_favorite"
              />
            </div>
            <div class="ml-3 text-sm">
              <label
                class="block boolean optional text-sm font-bold"
                for="third_party_favorite"
              >
                Favorite
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex mb-8">
      <div class="w-[40%]">
        <h1 class="text-xl font-bold">Contact information</h1>
        <span class="text-gray-600"
          >Complete all contact information about third party.</span
        >
      </div>
      <div class="w-[60%]">
        <div
          v-for="(contact, index) in contacts"
          :key="index"
          id="fieldsetContainer"
        >
          <fieldset
            :id="index.toString()"
            class="py-4 border-b border-gray-400"
          >
            <p class="font-bold text-sm text-gray-600 mb-2">
              Contact n° {{ index + 1 }}
            </p>
            <div class="mb-4 string optional third_party_contacts_type">
              <label
                :for="`third_party_contacts_attributes_${index}_type`"
                class="block string optional text-sm font-medium text-gray-600"
              >
                Contact type <abbr title="required">*</abbr>
              </label>
              <Multiselect
                v-model="contact.contact_type"
                :options="filteredContactTypes"
              />
              <input
                :value="contact.contact_type"
                :name="`third_party[contacts_attributes][${index}][contact_type]`"
                :id="`third_party_contacts_attributes_${index}_contact_type`"
                class="hidden"
              />
              <p
                v-if="errors && errors.contacts && errors.contacts[0]"
                class="text-red-400 text-sm mt-2"
              >
                {{ errors.contacts[0] }}
              </p>
            </div>
            <div class="mb-4 string optional third_party_contacts_name">
              <label
                :for="`third_party_contacts_attributes_${index}_name`"
                class="block string optional text-sm font-medium text-gray-600"
              >
                Contact name <abbr title="required">*</abbr>
              </label>
              <input
                v-model="contact.name"
                :name="`third_party[contacts_attributes][${index}][name]`"
                :id="`third_party_contacts_attributes_${index}_name`"
                class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string optional"
                type="text"
              />
            </div>
            <div class="mb-4 email optional third_party_contacts_email">
              <label
                class="block email optional text-sm font-medium text-gray-600"
                :for="`third_party_contacts_attributes_${index}_email`"
              >
                Contact email
              </label>
              <input
                v-model="contact.email"
                class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string email optional"
                type="email"
                :name="`third_party[contacts_attributes][${index}][email]`"
                :id="`third_party_contacts_attributes_${index}_email`"
              />
            </div>
            <div class="mb-4 tel optional third_party_contacts_phone">
              <label
                class="block tel optional text-sm font-medium text-gray-600"
                :for="`third_party_contacts_attributes_${index}_phone`"
              >
                Contact phone
              </label>
              <input
                v-model="contact.phone"
                class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string tel optional"
                type="tel"
                :name="`third_party[contacts_attributes][${index}][phone]`"
                :id="`third_party_contacts_attributes_${index}_phone`"
              />
            </div>
          </fieldset>

          <input
            autocomplete="off"
            type="hidden"
            :value="contact.id"
            :name="`third_party[contacts_attributes][${index}][id]`"
            :id="`third_party_contacts_attributes_${index}_id`"
          />
        </div>

        <button
          class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mt-4"
          @click="addContact"
          type="button"
        >
          Add another contact
        </button>
      </div>
    </div>

    <div class="flex mb-8">
      <div class="w-[40%]">
        <h1 class="text-xl font-bold">Bank details information</h1>
        <span class="text-gray-600">
          Complete all bank details information about third party, required for
          transactions or payments.
        </span>
      </div>
      <div class="w-[60%]">
        <div class="mb-4 string optional third_party_account_owner">
          <label
            class="block string optional text-sm font-medium text-gray-600"
            for="third_party_account_owner"
          >
            Account owner <abbr title="required">*</abbr>
          </label>
          <input
            v-model="data.accountOwner"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string optional"
            type="text"
            name="third_party[account_owner]"
            id="third_party_account_owner"
          />
        </div>
        <div class="mb-4 string optional third_party_iban">
          <label
            class="block string optional text-sm font-medium text-gray-600"
            for="third_party_iban"
          >
            IBAN
          </label>
          <input
            v-model="data.iban"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string optional"
            type="text"
            name="third_party[iban]"
            id="third_party_iban"
          />
          <p
            v-if="errors && errors.iban && errors && errors.iban[0]"
            class="text-red-400 text-sm mt-2"
          >
            {{ errors.iban[0] }}
          </p>
        </div>
        <div class="mb-4 string optional third_party_bic">
          <label
            class="block string optional text-sm font-medium text-gray-600"
            for="third_party_bic"
          >
            BIC
          </label>
          <input
            v-model="data.bic"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string optional"
            type="text"
            name="third_party[bic]"
            id="third_party_bic"
          />
        </div>
        <div class="mb-4 string optional third_party_bank_name">
          <label
            class="block string optional text-sm font-medium text-gray-600"
            for="third_party_bank_name"
          >
            Bank name
          </label>
          <input
            v-model="data.bankName"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string optional"
            type="text"
            name="third_party[bank_name]"
            id="third_party_bank_name"
          />
        </div>
        <div class="mb-4 string optional third_party_beneficiary_address">
          <label
            class="block string optional text-sm font-medium text-gray-600"
            for="third_party_beneficiary_address"
          >
            Beneficiary address
          </label>
          <input
            v-model="data.beneficiaryAddress"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string optional"
            type="text"
            name="third_party[beneficiary_address]"
            id="third_party_beneficiary_address"
          />
        </div>
        <div class="mb-4 string optional third_party_account_number">
          <label
            class="block string optional text-sm font-medium text-gray-600"
            for="third_party_account_number"
          >
            Account software
          </label>
          <input
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string optional"
            type="text"
            v-model="data.accountNumber"
            name="third_party[account_number]"
            id="third_party_account_number"
          />
        </div>
        <div class="file optional third_party_bank_details_file">
          <label
            class="text-sm font-medium text-gray-600 block file optional"
            for="third_party_bank_details_file"
          >
            Bank details file
          </label>
          <input
            class="w-full text-gray-500 px-3 py-2 border rounded file optional"
            type="file"
            name="third_party[bank_details_file]"
            id="third_party_bank_details_file"
          />
        </div>
      </div>
    </div>

    <div class="flex mb-8">
      <div class="w-[40%]">
        <h1 class="text-xl font-bold">Pricing information</h1>
        <span class="text-gray-600">
          All pricing information (VAT, model commission, invoicing type etc...)
        </span>
      </div>
      <div class="w-[60%] flex">
        <div class="w-[40%]">
          <div class="mb-4 required third_party_invoicing_type">
            <label
              class="block required text-sm font-medium text-gray-600"
              for="third_party_invoicing_type"
            >
              Invoicing type<abbr title="required">*</abbr>
            </label>
            <select
              v-model="data.invoicingType"
              class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200"
              required
              aria-required="true"
              name="third_party[invoicing_type]"
              id="third_party_invoicing_type"
            >
              <option value=""></option>
              <option
                v-for="invoicingType in thirdPartyInvoicingTypes"
                :key="invoicingType"
                :value="invoicingType"
              >
                {{ invoicingType }}
              </option>
            </select>
          </div>

          <div class="mb-4">
            <label
              class="block select optional text-sm font-medium text-gray-600"
              for="third_party_commission_vat_regime"
            >
              Commission vat regime <abbr title="required">*</abbr>
            </label>

            <BaseMultiselect
              v-model="data.commissionVatRegime"
              :options="formatCommissionVatRegimes"
              attribute="commission_vat_regime"
              model="third_party"
            />
          </div>

          <div class="mb-4 required third_party_commission_type">
            <label
              class="block required text-sm font-medium text-gray-600"
              for="third_party_commission_type"
            >
              Commission type <abbr title="required">*</abbr>
            </label>
            <select
              class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200"
              required
              aria-required="true"
              name="third_party[commission_type]"
              v-model="data.commissionType"
              id="third_party_commission_type"
            >
              <option value="" label=""></option>
              <option value="fixed">fixed</option>
              <option selected value="variable">variable</option>
            </select>
          </div>
        </div>

        <div class="w-[40%] ml-5">
          <div class="mb-4 float optional third_party_vat_rate">
            <label
              class="block float optional text-sm font-medium text-gray-600"
              for="third_party_vat_rate"
            >
              Vat rate <abbr title="required">*</abbr>
            </label>
            <input
              class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out numeric float optional"
              type="number"
              step="any"
              v-model="data.vatRate"
              name="third_party[vat_rate]"
              id="third_party_vat_rate"
            />
          </div>
          <template v-if="data.commissionType === 'fixed'">
            <div class="mb-4 float optional third_party_fixed_commission_rate">
              <label
                class="block float optional text-sm font-medium text-gray-600"
                for="third_party_fixed_commission_rate"
              >
                Fixed commission rate <abbr title="required">*</abbr>
              </label>
              <input
                v-model="data.fixedCommissionRate"
                class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out numeric float optional"
                type="number"
                step="any"
                name="third_party[fixed_commission_rate]"
                id="third_party_fixed_commission_rate"
              />
              <p
                v-if="
                  errors &&
                  errors.fixed_commission_rate &&
                  errors.fixed_commission_rate[0]
                "
                class="text-red-400 text-sm mt-2"
              >
                {{ errors.fixed_commission_rate[0] }}
              </p>
            </div>
          </template>
          <template v-else>
            <div
              class="mb-4 float optional third_party_minimum_commission_rate"
            >
              <label
                class="block float optional text-sm font-medium text-gray-600"
                for="third_party_minimum_commission_rate"
              >
                Minimum commission rate <abbr title="required">*</abbr>
              </label>
              <input
                class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out numeric float optional"
                type="number"
                step="any"
                v-model="data.minimumCommissionRate"
                name="third_party[minimum_commission_rate]"
                id="third_party_minimum_commission_rate"
              />
            </div>
            <div
              class="mb-4 float optional third_party_maximum_commission_rate"
            >
              <label
                class="block float optional text-sm font-medium text-gray-600"
                for="third_party_maximum_commission_rate"
              >
                Maximum commission rate <abbr title="required">*</abbr>
              </label>
              <input
                class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out numeric float optional"
                type="number"
                step="any"
                v-model="data.maximumCommissionRate"
                name="third_party[maximum_commission_rate]"
                id="third_party_maximum_commission_rate"
              />
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="flex mb-8">
      <div class="w-[40%]">
        <h1 class="text-xl font-bold">Additional information</h1>
        <span class="text-gray-600">
          Add all internal comments and external links (website, catalogue,
          pdf...) about third party.
        </span>
      </div>
      <div class="w-[60%]">
        <div class="mb-4 string optional third_party_comment">
          <label
            class="block string optional text-sm font-medium text-gray-600"
            for="third_party_comment"
          >
            Internal comment
          </label>
          <textarea
            v-model="data.comment"
            name="third_party[comment]"
            id="third_party_comment"
            cols="30"
            rows="10"
            class="shadow appearance-none border border-gray-300 rounded w-full h-[120px] py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string optional"
          ></textarea>
        </div>

        <div id="fieldsetContainer">
          <fieldset
            :id="link.id?.toString()"
            v-for="(link, linkIndex) in links"
            :key="link.id?.toString()"
          >
            <div class="grid grid-cols-3 gap-6">
              <div>
                <div class="mb-4 string optional third_party_links_name">
                  <label
                    class="block string optional text-sm font-medium text-gray-600"
                    :for="`third_party_links_attributes_${linkIndex}_name`"
                  >
                    Name of link
                  </label>
                  <input
                    v-model="link.name"
                    class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string optional"
                    type="text"
                    :name="`third_party[links_attributes][${linkIndex}][name]`"
                    :id="`third_party_links_attributes_${linkIndex}_name`"
                  />
                </div>
              </div>
              <div class="col-span-2">
                <div class="mb-4 url optional third_party_links_url">
                  <label
                    class="block url optional text-sm font-medium text-gray-600"
                    :for="`third_party_links_attributes_${linkIndex}_url`"
                  >
                    External link
                  </label>
                  <input
                    v-model="link.url"
                    :class="[
                      'shadow appearance-none border rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string url optional',
                      {
                        'focus:border-red-300 border-red-300':
                          linkIndex in validation &&
                          validation[linkIndex] === false,
                        'focus:border-green-300 border-green-300':
                          linkIndex in validation &&
                          validation[linkIndex] === true,
                      },
                    ]"
                    type="url"
                    :name="`third_party[links_attributes][${linkIndex}][url]`"
                    :id="`third_party_links_attributes_${linkIndex}_url`"
                    @input="validateLinksUrl(linkIndex, link.url)"
                  />
                </div>
              </div>
            </div>

            <input
              autocomplete="off"
              type="hidden"
              :value="link.id"
              :name="`third_party[links_attributes][${linkIndex}][id]`"
              :id="`third_party_links_attributes_${linkIndex}_id`"
            />
          </fieldset>
        </div>

        <button
          class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 my-8 mr-4"
          id="addLink"
          type="button"
          @click="addLinks"
        >
          {{ links.length ? "Add another" : "Add a" }} link
        </button>
      </div>
    </div>

    <button
      type="submit"
      :disabled="disabledButton"
      class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
    >
      {{ isUpdate ? "Update Third party" : "Create Third party" }}
    </button>
  </form>
</template>
