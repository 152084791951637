<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { clientApi } from "@/api/config";

import GuestContactModal from "@/views/Contracts/ClientItinerary/components/GuestContactModal.vue";
import GuestContactModalDelete from "@/views/Contracts/ClientItinerary/components/GuestContactModalDelete.vue";
import NoticeModal from "@/components/BaseNoticeModal.vue";

import BaseIcon from "@/components/BaseIcon.vue";

import { formatWithUTC } from "@/helpers/dates";

const api = clientApi.client;
const guestContracts = ref({});

const contract = window.gon?.contract ? ref(window.gon?.contract) : ref(null);
const showModal = ref(false);
const showModalDelete = ref(false);
const guestId = ref("");
const noticeVisible = ref(false);
const noticeMessage = ref("");
const ACTIVE_GUEST_CONTACTS = import.meta.env.VITE_ACTIVE_GUEST_CONTACTS;
const isActiveGuestContacts = ref(import.meta.env.VITE_ACTIVE_GUEST_CONTACTS === "true");

const shouldShowButton = computed(
  () => Boolean(contract.value.api_contract_id) && isActiveGuestContacts.value
);

const openModal = () => {
  showModal.value = true;
};

const openModalDelete = (id: number) => {
  showModalDelete.value = true;
  guestId.value = id;
};

const handleCloseModal = () => {
  showModal.value = false;
};

const showGuestContacts = async (id: number) => {
  try {
    if (id) {
      const res = await api.guestContracts.show(id);
      guestContracts.value = res.data.data;
    }
  } catch (err) {
    console.warn(err);
    throw err;
  }
};

const updateModal = (message: string) => {
  showModal.value = false;
  showModalDelete.value = false;
  showGuestContacts(contract?.value?.api_contract_id);
  showNotice(message);
};

const showNotice = (message: string) => {
  noticeMessage.value = message;
  noticeVisible.value = true;
};

onMounted(async () => {
  await showGuestContacts(contract?.value?.api_contract_id);
});
</script>

<template>
  <div class="flex items-center justify-between">
    <div class="mt-10 flex-1">
      <h2 class="text-xl font-bold text-gray-900 sm:text-xl sm:truncate mt-10 px-2 pb-2">
        Guests of the stay
      </h2>
    </div>
    <template v-if="shouldShowButton">
      <button
        class="ml-3 inline-flex items-center px-4 py-2 cursor-pointer border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        @click="openModal"
      >
        + Invite Guest
      </button>
    </template>
  </div>
  <div
    class="relative rounded-xl overflow-auto border border-solid divide-stone-200 shadow-lg mb-8"
  >
    <table class="table-auto max-w-[1432px] w-full">
      <thead class="text-sm text-gray-500 text-left sm:not-sr-only bg-gray-50">
        <tr>
          <th class="py-4 px-6 font-bold" scope="col">Email</th>
          <th class="py-4 px-6 font-bold" scope="col">Name</th>
          <th class="hidden py-4 px-6 font-bold sm:table-cell" scope="col">
            Last app activity
          </th>
          <th class="hidden py-4 px-6 font-bold sm:table-cell" scope="col">
            Notification activated
          </th>
          <th class="py-4 px-6 font-bold" scope="col">Signatory</th>
          <th class="py-4 px-6 font-bold" scope="col">Action</th>
        </tr>
      </thead>
      <tbody class="border-b border-gray-200 divide-y divide-gray-200 sm:border-t">
        <tr v-for="(guest, index) in guestContracts" :key="index">
          <td class="py-3 px-6 font-medium">
            {{ guest.attributes.email }}
          </td>
          <td class="py-3 px-6 font-medium">
            {{ guest.attributes.fullName }}
          </td>
          <td class="py-3 px-6 font-medium">
            {{
              guest.attributes.lastAppActivity
                ? formatWithUTC(guest.attributes.lastAppActivity, "YYYY-MM-DD")
                : "Not dowloaded yet"
            }}
          </td>
          <td class="py-3 px-6 font-medium">
            <BaseIcon v-if="guest.attributes.notificationEnabled" name="checkCircle" />
            <BaseIcon v-else name="closeCircle" />
          </td>
          <td class="py-3 px-6 font-medium">
            <BaseIcon v-if="guest.attributes.signatory" name="checkCircle" />
            <BaseIcon v-else name="closeCircle" />
          </td>
          <td class="py-3 px-6 font-medium cursor-pointer">
            <BaseIcon
              @click="openModalDelete(guest.id)"
              v-if="!guest.attributes.signatory"
              name="trash"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <GuestContactModal
      v-model="showModal"
      @close-modal="handleCloseModal"
      @update-modal="() => updateModal('New guest invited to the stay!')"
      :contractId="contract.api_contract_id"
    />
    <GuestContactModalDelete
      v-model="showModalDelete"
      @close-modal="handleCloseModal"
      @update-modal="() => updateModal('Guest have been removed from the stay')"
      :guestId="guestId"
      :contractId="contract.api_contract_id"
    />
  </div>
  <NoticeModal
    :visible="noticeVisible"
    :message="noticeMessage"
    :delay="3000"
    @update:visible="(val) => (noticeVisible = val)"
  />
</template>
