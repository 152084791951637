<script setup lang="ts">
import { useAttrs, ref } from "vue";

const emits = defineEmits([
  "update:model-value",
  "update-errors",
  "update:file",
]);

defineOptions({
  inheritAttrs: false,
});
const props = defineProps({
  decimal: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  modelValue: {
    type: [Number, String],
    default: "",
  },
  errors: {
    type: String,
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  },
  classWrapper: {
    type: String,
    default: "pt-4",
  },
});

const fileUrl = ref("");
const attrs = useAttrs();
const updateInput = (e: Event & { target: HTMLInputElement }, type: string) => {
  emits("update:model-value", e.target.value, type);
};
const changeInput = (e: Event & { target: HTMLInputElement }) => {
  if (attrs.type === "file" && Object.keys(e?.target?.files as any).length) {
    const fileListObj: FileList | null = e?.target?.files;

    if (fileListObj) {
      fileUrl.value = URL.createObjectURL(fileListObj[0]);
      emits("update:file", fileUrl.value);
    }
  }
};

const isNumberKey = (
  event: Event & { which: number; keyCode: number; target: HTMLInputElement },
) => {
  const ASCIICode = event.which ? event.which : event.keyCode;
  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
    if (!props.decimal) {
      return false;
    } else {
      if (ASCIICode === 44) {
        return true;
      } else {
        return false;
      }
    }
  } else if (props.decimal) {
    return authorize2Decimal(event);
  }

  return true;
};
const authorize2Decimal = (event: Event & { target: HTMLInputElement }) => {
  if (
    event.target.value.includes(".") &&
    event.target.value.split(".")[1].length === 2
  ) {
    return false;
  }

  return true;
};
</script>

<template>
  <div :class="classWrapper">
    <label v-if="label" :for="id" class="mb-2 block label">
      {{ label }} <span v-if="required">*</span>
    </label>
    <input
      :value="modelValue"
      :name="name"
      :id="id"
      :class="[
        'shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-800 leading-6 transition-colors duration-200 ease-in-out string required',
        {
          'input-decimal': decimal,
        },
      ]"
      :onkeypress="$attrs.type === 'number' ? isNumberKey : null"
      v-bind="$attrs"
      @input="updateInput"
      @change="changeInput"
    />
    <p class="text-red-400 text-sm mt-2" v-if="errors">{{ errors }}</p>
  </div>
</template>

<style>
.input-decimal::-webkit-outer-spin-button,
.input-decimal::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-decimal[type="number"] {
  -moz-appearance: textfield;
}
.label {
  @apply text-[14px] text-[#6B7280] font-[600];
}
</style>
