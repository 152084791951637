<script setup lang="ts">
import { computed, ref } from "vue";
import BaseMultiselect from "@/components/BaseMultiselect.vue";
import queryParams from "@/helpers/queryParams";

type Status = {
  id: string;
  name: string;
};

type Tailor = {
  id: string;
  name: string;
};

const statuses = window.gon?.bookingStatuses
  ? ref(window.gon?.bookingStatuses)
  : ref([]);
const tailors = window.gon?.tailors ? ref(window.gon?.tailors) : ref([]);
const params = queryParams();
const selectedStatusId = ref<string | null>(params.get("booking_status"));
const selectedTailorId = ref<string | null>(params.get("tailor_id"));

const formatStatus = computed(() => {
  return statuses.value.map((status: Status) => {
    return status;
  });
});

const formatTailors = computed(() => {
  return tailors.value.map((tailor: Tailor) => {
    return tailor;
  });
});

const changeParams = (type, e) => {
  if (e !== null && e !== undefined) {
    params.set(type, e);
  }
};
const deleteParams = (type) => {
  params.remove(type);
};
</script>

<template>
  <BaseMultiselect
    v-model="selectedStatusId"
    :searchable="true"
    :placeholder="'Status'"
    :options="formatStatus"
    attribute="status"
    model="status"
    @change="changeParams('booking_status', $event)"
    @close="deleteParams('booking_status')"
  />
  <BaseMultiselect
    v-model="selectedTailorId"
    :searchable="true"
    :placeholder="'Tailor Owner'"
    :options="formatTailors"
    attribute="tailor_id"
    model="tailor"
    @change="changeParams('tailor_id', $event)"
    @close="deleteParams('tailor_id')"
  />
</template>

<style>
.bookingFilter > div {
  @apply w-full px-1;
}
</style>
