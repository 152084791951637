<script setup lang="ts">
import { computed } from "vue";

type Props = {
  isSelected: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  isSelected: false,
});

const colors = computed(() => ({
  rect: props.isSelected ? "#033535" : "white",
  path: props.isSelected ? "white" : "#757575",
}));
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <rect width="40" height="40" rx="4" :fill="colors.rect" />
    <path
      d="M18 16.2308C17.2008 15.4931 15.7935 14.98 14.5 14.9479M11 23.2308C11.7519 24.1562 13.1499 24.684 14.5 24.7288M14.5 14.9479C12.961 14.9096 11.5833 15.5523 11.5833 17.3077C11.5833 20.5385 18 18.9231 18 22.1538C18 23.9965 16.2922 24.7882 14.5 24.7288M14.5 14.9479V13M14.5 24.7288V27"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29 20H21M21 20L24.84 16M21 20L24.84 24"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
