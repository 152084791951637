import axios from "axios";
import * as services from "./api";

import type { AxiosInstance } from "@/types";

export type ApiResponse<Data, Included> = Promise<{
  data: Data;
  included?: Included;
  meta?: {
    current_page: number;
    next_page: number | null;
    per_page: number;
    prev_page: number | null;
    total_count: number;
    total_pages: number;
  };
}>;

const setClientApi = (axios: AxiosInstance) => {
  return {
    axiosConfig: axios.defaults,
    client: services.default,
  };
};

const setAxiosUrl = () => {
  const locale = "fr";
  const BASE_URL: string = import.meta.env.VITE_API_BASEURL as string;

  return `${BASE_URL}/${locale}/api/`;
};

const setAxios = () => {
  const api = axios.create({
    baseURL: setAxiosUrl(),
    headers: {
      Authorization: import.meta.env.VITE_TAILOR_PT_API_TOKEN,
    },
  }) as AxiosInstance;

  return api;
};
export const API = setAxios();
const clientApi = setClientApi(API);

export { clientApi };
