import { mapKeys, camelCase, snakeCase, Dictionary } from "lodash";

type GenericObjInput = Dictionary<unknown>;
type GenericObjOutput<T> = T extends null ? Dictionary<unknown> : T;

const transformToCamelcase = <T = null>(
  response: GenericObjInput | GenericObjInput[],
) => {
  if (Array.isArray(response)) {
    return response.map((obj) => {
      return mapKeys(obj, (_, k: string) => camelCase(k));
    }) as GenericObjOutput<T>[];
  }
  return mapKeys(response, (_, k: string) =>
    camelCase(k),
  ) as GenericObjOutput<T>;
};

const transformToSnakeCase = <T = null>(payload: GenericObjInput) => {
  return {
    ...mapKeys(payload, (_, k: string) => snakeCase(k)),
  } as GenericObjOutput<T>;
};

export { transformToCamelcase, transformToSnakeCase };
