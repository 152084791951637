<script setup lang="ts">
import { computed, reactive, ref } from "vue";

import BaseMultiselect from "@/components/BaseMultiselect.vue";
import BaseDate from "@/components/BaseDate.vue";

const token = ref(
  document.getElementsByName("csrf-token")[0].getAttribute("content"),
);

const bookerTypes = window.gon?.booker_types
  ? ref(window.gon?.booker_types)
  : ref([]);
const destinations = window.gon?.destinations
  ? ref(window.gon?.destinations)
  : ref([]);
const tailors = window.gon?.tailors ? ref(window.gon?.tailors) : ref([]);
const contract = window.gon?.contracts ? ref(window.gon?.contracts) : ref([]);
const languages = window.gon?.languages ? ref(window.gon?.languages) : ref([]);
const model = ref("contract");
const errors = ref(window.gon?.errors);

const emits = defineEmits(["update:checkin-date", "update:checkout-date"]);

type Destinations = {
  id: string;
  name: string;
};
type Tailors = {
  id: string;
  name: string;
};
type Languages = {
  id: string;
  name: string;
};

interface InitialData {
  destinations: Destinations;
  tailors: Tailors;
  languages: Languages;
  clientName: string;
  houseName: string;
  checkInDate: string;
  checkOutDate: string;
}
const data = reactive<InitialData>({
  destinations: destinations.value,
  clientName: contract.value?.client_name,
  houseName: contract.value?.house_name,
  checkInDate: contract.value?.check_in_date,
  checkOutDate: contract.value?.check_out_date,
  tailors: tailors.value,
  language: contract.value?.client_language,
  bookerType: contract.value?.booker_type,
});

const formatBookerType = computed(() => {
  return bookerTypes.value.map((booker_type) => {
    return booker_type;
  });
});
const formatDestinations = computed(() => {
  return destinations.value.map((desti: Destinations) => {
    return {
      value: desti.id,
      label: desti.name["en"],
    };
  });
});
const formatTailors = computed(() => {
  return tailors.value.map((tailor: Tailors) => {
    return {
      value: tailor.id,
      label: tailor.name,
    };
  });
});
const formatLanguages = computed(() => {
  return languages.value.map((language: Languages) => {
    return language;
  });
});

const changeStartDate = (value: string) => {
  emits("update:checkin-date", value);
  data.checkInDate = value;
};
const changeEndDate = (value: string) => {
  emits("update:checkout-date", value);
  data.checkOutDate = value;
};

const disabledButton = computed(() => {
  if (
    data.clientName &&
    data.houseName &&
    data.checkInDate &&
    data.checkOutDate &&
    data.languages != undefined &&
    destinations.value.length &&
    tailors.value.length &&
    data.bookerType
  ) {
    return false;
  }

  return true;
});
</script>

<template>
  <form
    class="mt-8"
    novalidate="novalidate"
    enctype="multipart/form-data"
    :action="'/contracts'"
    accept-charset="UTF-8"
    method="post"
  >
    <input
      type="hidden"
      name="authenticity_token"
      :value="token"
      autocomplete="off"
    />
    <div class="mb-4 select required contract_subsidiary">
      <label
        class="block select required text-sm font-medium text-gray-600"
        for="contract_booker_type"
        >Client type <abbr title="required">*</abbr></label
      >
      <BaseMultiselect
        v-model="data.bookerType"
        :options="formatBookerType"
        attribute="booker_type"
        model="contract"
      />
    </div>
    <div class="mb-4 select required contract_tailor">
      <label
        class="block select required text-sm font-medium text-gray-600"
        for="contract_tailor_id"
        >Tailors <abbr title="required">*</abbr></label
      >
      <BaseMultiselect
        v-model="data.tailors.id"
        :options="formatTailors"
        :searchable="true"
        attribute="tailor_id"
        model="contract"
      />
    </div>
    <div class="mb-4 string required contract_client_name">
      <label
        class="block string required text-sm font-medium text-gray-600"
        for="contract_client_name"
        >Client name <abbr title="required">*</abbr></label
      >
      <input
        id="contract_client_name"
        v-model="data.clientName"
        class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string required"
        type="text"
        name="contract[client_name]"
      />
    </div>
    <div class="my-4 date required contract_check_in_date">
      <div class="inline-flex space-x-1">
        <BaseDate
          :date="data.checkInDate"
          :disabled="false"
          :as-schedules="false"
          :model="model"
          attribute="check_in_date"
          min="2018-01-01"
          label="Check in date"
          @update:date="changeStartDate"
        />
      </div>
    </div>
    <div class="my-4 date required contract_check_out_date">
      <div class="inline-flex space-x-1">
        <BaseDate
          :date="data.checkOutDate"
          :disabled="false"
          :as-schedules="false"
          :model="model"
          attribute="check_out_date"
          min="2018-01-01"
          label="Check out date"
          @update:date="changeEndDate"
        />
      </div>
      <p
        v-if="
          errors && errors.check_out_date && errors && errors.check_out_date[0]
        "
        class="text-red-400 text-sm mt-2"
      >
        Checkout date {{ errors.check_out_date[0] }}
      </p>
    </div>
    <div class="mb-4 select required contract_destination">
      <label
        class="block select required text-sm font-medium text-gray-600"
        for="contract_destination_id"
        >Destination name<abbr title="required">*</abbr></label
      >
      <BaseMultiselect
        v-model="data.destinations.id"
        :options="formatDestinations"
        :searchable="true"
        attribute="destination_id"
        model="contract"
      />
    </div>
    <div class="mb-4 select required contract_client_language">
      <label
        class="block select required text-sm font-medium text-gray-600"
        for="contract_client_language"
        >Client language<abbr title="required">*</abbr></label
      >
      <BaseMultiselect
        v-model="data.languages"
        :options="formatLanguages"
        attribute="client_language"
        model="contract"
      />
    </div>
    <div class="mb-4 string required contract_house_name">
      <label
        class="block string required text-sm font-medium text-gray-600"
        for="contract_house_name"
        >House name <abbr title="required">*</abbr></label
      >
      <input
        id="contract_house_name"
        v-model="data.houseName"
        class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string required"
        type="text"
        name="contract[house_name]"
      />
    </div>
    <button
      type="submit"
      :disabled="disabledButton"
      class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
    >
      Create Contact
    </button>
  </form>
</template>
