<script setup lang="ts">
import { watch } from "vue";

type Props = { visible: boolean; message: string; delay: number };
const props = withDefaults(defineProps<Props>(), {
  delay: 3000,
});

const emit = defineEmits(["update:visible"]);

watch(
  () => props.visible,
  (newVal) => {
    if (newVal) {
      setTimeout(() => {
        emit("update:visible", false);
      }, props.delay);
    }
  },
);
</script>

<template>
  <div
    v-if="visible"
    class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-10"
    aria-live="assertive"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <div
        class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
      >
        <div class="p-4">
          <div class="w-full text-sm font-medium text-gray-900">
            {{ message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
