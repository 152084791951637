<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M13 6.5L19 12.5L13 18.5"
      stroke="#202020"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 6.5L11 12.5L5 18.5"
      stroke="#202020"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
