<script setup lang="ts">
import { ref } from "vue";
import { truncateString } from "@/helpers/string";

import ClientItineraryArrivalDepartureModal from "@/views/Contracts/ClientItinerary/components/ClientItineraryArrivalDepartureModal.vue";
import { format } from "@/helpers/dates";

import type { Contract } from "@/types";
export type ClientItineraryType = "arrival" | "departure";

type ClientItinerary = {
  id: number;
  contract_id: number;
  created_at: string;
  updated_at: string;
  check_in_date: string;
  check_in_time: string | null;
  check_out_date: string;
  check_out_time: string | null;
  arrival_information: string | null;
  departure_information: string;
};

const contract = ref<Contract>(window.gon.contract);
const clientItinerary = ref<ClientItinerary>(window.gon?.client_itinerary);
const clientItineraryChanges = ref(window.gon?.audited_changes);
const selectedItinerary = ref<{
  type: ClientItineraryType | "";
  information: string;
}>();

const showModal = ref(false);
const openModal = (itineraryType: ClientItineraryType) => {
  selectedItinerary.value = {
    type: itineraryType,
    information: clientItinerary.value[`${itineraryType}_information`] || "",
  };
  showModal.value = true;
};
const updateModal = () => {
  clientItinerary.value = { ...window.gon?.client_itinerary };
  clientItineraryChanges.value = { ...window.gon?.audited_changes };

  showModal.value = false;
};
</script>

<template>
  <h2
    class="text-xl font-bold text-gray-900 sm:text-xl sm:truncate mt-10 px-2 pb-2"
  >
    Arrival & Departure information
  </h2>
  <p
    v-if="clientItineraryChanges?.check_information?.date"
    class="font-medium leading-5 pb-4 px-2 rounded-md text-sm text-gray-500"
  >
    Last edit:
    {{ clientItineraryChanges.check_information.date }}
    at
    {{ clientItineraryChanges.check_information.time }}
    by
    {{ clientItineraryChanges.check_information.user_name }}
  </p>
  <div
    class="relative rounded-xl overflow-auto border border-solid divide-stone-200 shadow-lg"
  >
    <table class="table-auto max-w-[1432px] w-full">
      <thead class="text-sm text-gray-500 text-left sm:not-sr-only bg-gray-50">
        <tr>
          <th class="py-4 px-6 font-bold text-left" scope="col">Category</th>
          <th class="py-4 px-6 font-bold text-left" scope="col">
            Local date &amp; time
          </th>
          <th class="py-4 px-6 font-bold sm:table-cell w-3/5" scope="col">
            Description
          </th>
          <th class="py-4 px-6 font-bold text-center sm:table-cell" scope="col">
            Action
          </th>
        </tr>
      </thead>
      <tbody
        class="border-b border-gray-200 divide-y divide-gray-200 text-gray-900 sm:border-t"
      >
        <tr>
          <td class="py-3 px-6 font-medium">Arrival information</td>
          <td class="py-3 px-6 font-medium">
            <span v-if="clientItinerary?.check_in_date">
              {{ `${format(clientItinerary?.check_in_date, "YYYY-MM-DD")}` }}
            </span>
            <span class="ml-2" v-if="clientItinerary?.check_in_time">
              {{ clientItinerary?.check_in_time }}
            </span>
          </td>
          <td class="py-3 px-6 font-medium">
            <div
              v-html="truncateString(clientItinerary?.arrival_information, 100)"
            />
          </td>
          <td class="py-3 px-6 font-medium">
            <button
              class="edit-link"
              data-modal-id="client_itinerary_79"
              type="button"
              @click="openModal('arrival')"
            >
              <BaseIcon name="edit" />
            </button>
          </td>
        </tr>
        <tr>
          <td class="py-3 px-6 font-medium">Departure information</td>
          <td class="py-3 px-6 font-medium">
            <span v-if="clientItinerary?.check_out_date">
              {{ `${format(clientItinerary?.check_out_date, "YYYY-MM-DD")}` }}
            </span>
            <span class="ml-2" v-if="clientItinerary?.check_out_time">
              {{ clientItinerary?.check_out_time }}
            </span>
          </td>
          <td class="py-3 px-6 font-medium">
            <div
              v-html="
                truncateString(clientItinerary.departure_information, 100)
              "
            />
          </td>
          <td class="py-3 px-6 font-medium">
            <button
              class="edit-link"
              data-modal-id="client_itinerary_79"
              type="button"
              @click="openModal('departure')"
            >
              <BaseIcon name="edit" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ClientItineraryArrivalDepartureModal
    v-model="showModal"
    :clientItineraryId="clientItinerary.id"
    :clientLanguage="contract.client_language"
    :contractId="contract.id"
    :selectedItinerary="selectedItinerary"
    @update-modal="updateModal"
  />
</template>
