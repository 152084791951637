<script setup lang="ts">
import { ref, computed } from "vue";

import { truncateString } from "@/helpers/string";

import ClientItineraryServiceModalPaid from "@/views/Contracts/ClientItinerary/components/ClientItineraryServiceModalPaid.vue";
import ClientItineraryServiceModalUnpaid from "@/views/Contracts/ClientItinerary/components/ClientItineraryServiceModalUnpaid.vue";
import ClientItineraryAddServiceModalUnpaid from "@/views/Contracts/ClientItinerary/components/ClientItineraryAddServiceModalUnpaid.vue";
import ClientItineraryServiceModalDelete from "@/views/Contracts/ClientItinerary/components/ClientItineraryServiceModalDelete.vue";

import { formatWithUTC } from "@/helpers/dates";
import { Appointments } from "@/types";

type ClientItineraryChanges = {
  service_and_reservation: {
    date: string;
    time: string;
    user_name: string;
  };
};

const appointments = ref<Appointments[]>(window.gon?.appointments || []);

const sortedAppointments = computed(() => {
  const sorted = [...appointments.value];
  sorted.sort((a, b) => {
    const dateA = a.start_at ? new Date(a.start_at) : null;
    const dateB = b.start_at ? new Date(b.start_at) : null;

    return dateB && dateA ? dateB - dateA : 0;
  });
  return sorted;
});

const selectedClientItinerary = ref<Appointments | null>(null);
const clientItineraryChanges = ref<ClientItineraryChanges>(
  window.gon?.audited_changes,
);

const showPaidModal = ref(false);
const showUnpaidModal = ref(false);
const showAddModal = ref(false);
const showModalDelete = ref(false);
const selectedId = ref<number | null>(null);

const openModal = (clientItinerary: Appointments) => {
  // Show open paid modal
  if (clientItinerary.booking_service_id) {
    showPaidModal.value = true;
  } else {
    // Show open unpaid modal
    showUnpaidModal.value = true;
  }

  selectedClientItinerary.value = clientItinerary;
};

const openAddModal = () => {
  showAddModal.value = true;
};
const openDeleteModal = (id: number) => {
  showModalDelete.value = true;
  selectedId.value = id;
};

const handleCloseModal = () => {
  showPaidModal.value = false;
  showUnpaidModal.value = false;
  showAddModal.value = false;
  showModalDelete.value = false;
};

const handlePublishedUpdate = (newStatus: 0 | 1) => {
  if (selectedClientItinerary.value) {
    selectedClientItinerary.value.published = newStatus;
  }
};

const updateModal = () => {
  clientItineraryChanges.value = { ...window.gon?.audited_changes };
  appointments.value = [...(window.gon?.appointments ?? [])];
  showAddModal.value = false;
  showPaidModal.value = false;
  showUnpaidModal.value = false;
  showModalDelete.value = false;
};
</script>

<template>
  <div class="flex items-center justify-between">
    <div class="mt-10 flex-1">
      <h2
        class="text-xl font-bold leading-3 text-gray-900 sm:text-xl sm:truncate px-2 pb-2"
      >
        Services & Reservations booked
      </h2>
      <p
        v-if="clientItineraryChanges?.service_and_reservation?.date"
        class="font-medium leading-5 pb-4 px-2 rounded-md text-sm text-gray-500"
      >
        Last edit:
        {{ clientItineraryChanges.service_and_reservation.date }}
        at
        {{ clientItineraryChanges.service_and_reservation.time }}
        by
        {{ clientItineraryChanges.service_and_reservation.user_name }}
      </p>
    </div>
    <button
      class="ml-3 inline-flex items-center px-4 py-2 cursor-pointer border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
      @click="openAddModal"
    >
      + Add reservation
    </button>
  </div>
  <div
    class="relative rounded-xl overflow-auto border border-solid divide-stone-200 shadow-lg"
  >
    <table class="table-auto max-w-[1432px] w-full">
      <thead class="text-gray-500 text-left sm:not-sr-only bg-gray-50">
        <tr>
          <th class="py-4 px-6 font-bold" scope="col">Category</th>
          <th class="py-4 px-6 font-bold" scope="col">Name</th>
          <th class="hidden py-4 px-6 font-bold sm:table-cell" scope="col">
            Local date &amp; time
          </th>
          <th class="hidden py-4 px-6 font-bold sm:table-cell" scope="col">
            Status
          </th>
          <th class="py-4 px-6 font-bold" scope="col">Description</th>
          <th class="py-4 px-6 font-bold" scope="col">Action</th>
        </tr>
      </thead>
      <tbody
        class="border-b border-gray-200 divide-y divide-gray-200 sm:border-t"
      >
        <tr v-for="(client, index) in sortedAppointments" :key="index">
          <td class="py-3 px-6 font-medium text-gray-900">
            {{ client.category_name }}
          </td>
          <td class="py-3 px-6 font-medium text-gray-900">
            {{ truncateString(client.name, 30) }}
          </td>
          <td class="py-3 px-6 font-medium text-gray-900">
            <span class="block" v-if="client?.start_at">
              {{ `${formatWithUTC(client?.start_at, "YYYY-MM-DD")} ` }}
              <span v-if="client?.start_at_time">
                {{ `(${formatWithUTC(client?.start_at_time, "HH:mm")})` }}
              </span>
            </span>
            <span class="block" v-if="client?.end_at">
              {{ `${formatWithUTC(client?.end_at, "YYYY-MM-DD")}` }}
              <span v-if="client?.end_at_time">
                {{ `(${formatWithUTC(client.end_at_time, "HH:mm")})` }}
              </span>
            </span>
          </td>
          <td class="py-3 px-6 text-gray-500">
            <span
              :class="{
                'items-center px-2.5 py-1 inline-flex text-xs font-medium rounded-full bg-green-100 text-green-800 focus:border-green-300 border-green-300':
                  client.published,
                'items-center px-2.5 py-1 inline-flex text-xs font-medium rounded-full bg-[#FFEDD5] text-[#C2410C]':
                  !client.published,
              }"
              >{{ client.published ? "Published" : "Not published" }}</span
            >
          </td>
          <td class="py-3 px-6 text-gray-500">
            <div v-html="truncateString(client.description, 60)" />
          </td>
          <td class="py-3 px-6 text-gray-500">
            <button
              class="edit-link"
              data-modal-id="client_itinerary_79"
              type="button"
              @click="openModal(client)"
            >
              <BaseIcon name="edit" />
            </button>

            <button
              type="button"
              class="ml-2"
              @click="openDeleteModal(client.id)"
              v-if="client.booking_service_id === null"
            >
              <BaseIcon name="trash" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ClientItineraryServiceModalPaid
    v-if="showPaidModal"
    v-model="showPaidModal"
    :selected-client-itinerary="selectedClientItinerary"
    @close-modal="handleCloseModal"
    @update-service="updateModal"
    @update-published="handlePublishedUpdate"
  />
  <ClientItineraryServiceModalUnpaid
    v-if="showUnpaidModal"
    v-model="showUnpaidModal"
    :selected-client-itinerary="selectedClientItinerary"
    @close-modal="handleCloseModal"
    @update-service="updateModal"
    @update-published="handlePublishedUpdate"
  />
  <ClientItineraryAddServiceModalUnpaid
    v-model="showAddModal"
    :selected-client-itinerary="selectedClientItinerary"
    @close-modal="handleCloseModal"
    @update-service="updateModal"
    @update-published="handlePublishedUpdate"
  />
  <ClientItineraryServiceModalDelete
    v-model="showModalDelete"
    :selectedId="selectedId || null"
    @close-modal="handleCloseModal"
    @update-modal="updateModal"
  />
</template>
