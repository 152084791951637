<script setup lang="ts">
import { ref } from "vue";
import BaseModal from "@/components/BaseModal.vue";
import BaseForm from "@/components/BaseForm.vue";
import BaseWysiwyg from "@/components/BaseWysiwyg.vue";

type SelectedItinerary = {
  includedServices: string;
};

type Props = {
  contractId: number | null;
  clientItineraryId: number | null;
  selectedItinerary: SelectedItinerary;
  modelValue: boolean;
};

const emits = defineEmits(["update-modal", "update:modelValue"]);
const props = withDefaults(defineProps<Props>(), {
  clientItineraryId: null,
  contractId: null,
  modelValue: false,
  selectedItinerary: () => ({ includedServices: "" }),
});

const errors = ref();
const successRequest = () => {
  emits("update-modal");
  errors.value = null;
};
const errorRequest = (err: { [key: string]: string }) => {
  errors.value = err;
};
</script>

<template>
  <BaseModal
    :model-value="props.modelValue"
    :title="`Edit Included Services`"
    has-footer
    hasHeader
    @close-modal="$emit('update:modelValue', false)"
  >
    <template #content>
      <BaseForm
        id="client_itinerary_included_services"
        :action="`/contracts/${contractId}/client_itineraries`"
        patch
        :on-success="successRequest"
        :on-error="errorRequest"
      >
        <BaseWysiwyg
          :name="`client_itinerary[included_services]`"
          :modelValue="selectedItinerary.includedServices"
          :height="300"
        />
        <div class="flex justify-end mt-3">
          <button
            type="button"
            class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-primary-700 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
            @click="$emit('update:modelValue', false)"
          >
            Cancel
          </button>

          <button
            type="submit"
            class="inline-flex items-center px-3 py-2 ml-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
          >
            Save
          </button>
        </div>
      </BaseForm>
    </template>
  </BaseModal>
</template>
