<script setup lang="ts">
import type { PropType } from "vue";
import type { ClientItineraryData } from "../composables/useFormData";

defineProps({
  selectedPlace: {
    type: Object as PropType<ClientItineraryData | null>,
    default: null,
  },
});

const toCamelCase = (str?: string) => {
  return str ? str.substring(0, 1).toUpperCase() + str.substring(1) : "";
};
</script>

<template>
  <ul class="mt-4">
    <li class="text-sm font-medium text-gray-600 pb-2">
      <span class="text-[#6B7280]">Category:</span>&nbsp;
      <span class="text-slate-950">
        {{ toCamelCase(selectedPlace?.category_name) || "/" }}
      </span>
      <input
        name="appointment[category_name]"
        :value="selectedPlace?.category_name"
        type="hidden"
      />
    </li>
    <li class="text-sm font-medium text-gray-600 pb-2">
      <span class="text-[#6B7280]">Address:</span>&nbsp;
      <template v-if="selectedPlace">
        <span class="text-slate-950">
          {{ selectedPlace?.address }}, {{ selectedPlace?.zip_code }},
          {{ selectedPlace?.city }}
        </span>
        <input
          name="appointment[address]"
          :value="selectedPlace.address"
          type="hidden"
        />
        <input
          name="appointment[zip_code]"
          :value="selectedPlace.zip_code"
          type="hidden"
        />
        <input
          name="appointment[city]"
          :value="selectedPlace.city"
          type="hidden"
        />
      </template>
      <span v-else>/</span>
    </li>
    <li class="text-sm font-medium text-gray-600 pb-2">
      <span class="text-[#6B7280]">Coordinates:</span>&nbsp;
      <span class="text-slate-950">
        {{ selectedPlace?.gpslatitude || "/" }} /
        {{ selectedPlace?.gpslongitude || "/" }}
      </span>
      <input
        name="appointment[gpslatitude]"
        :value="selectedPlace?.gpslatitude"
        type="hidden"
      />
      <input
        name="appointment[gpslongitude]"
        :value="selectedPlace?.gpslongitude"
        type="hidden"
      />
    </li>
    <li class="text-sm font-medium pb-2">
      <span class="text-[#6B7280]">Website link:</span>&nbsp;
      <span class="text-slate-950" v-if="selectedPlace?.website">
        <a :href="selectedPlace.website" target="_blank">
          {{ selectedPlace.website }}
        </a>
        <input
          name="appointment[website]"
          :value="selectedPlace?.website"
          type="hidden"
        />
      </span>
    </li>
    <li class="text-sm font-medium text-gray-600">
      <span class="text-[#6B7280]">Phone number:</span>&nbsp;
      <span class="text-slate-950">{{ selectedPlace?.phone || "/" }}</span>
      <input
        name="appointment[phone]"
        :value="selectedPlace?.phone"
        type="hidden"
      />
    </li>
  </ul>
</template>
