<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import { months } from "@/helpers/dates";

import BaseMultiselect from "@/components/BaseMultiselect.vue";

import type {
  Contract,
  MainCategory,
  SubCategory,
  ThirdParty,
  ThirdPartyAsset,
  PaymentError,
} from "@/types";

type DataToSave = {
  amount: number | null;
  checkInInvoiceDate: Date | null;
  checkInPaidOn: Date | null;
  comment: string;
  contractDescription: string;
  files: FileList | null;
  foreignCurrency: boolean;
  invoiceReference: string;
  selectedCategory: number | null;
  selectedSubCategory: number | null;
  selectedThirdParty: number | null;
  selectPaymentMethod: string;
  selectBank: string;
};

const token = ref<string | null>(
  document.getElementsByName("csrf-token")[0].getAttribute("content"),
);

const contract = ref<Contract>(window.gon.contract);
const mainCategories = ref<MainCategory[]>(window.gon.mainCategories);
const subCategories = ref<SubCategory[]>(window.gon.subCategories);
const contractDescription = ref<string>(window.gon.contractDescription);
const thirdParties = ref<ThirdParty[]>(window.gon.thirdParties);
const thirdPartieAssets = ref<ThirdPartyAsset[]>(window.gon.thirdPartieAssets);
const errors = ref<PaymentError | undefined>(window.gon?.errors);
const isSubmissive = ref<boolean>(true);
const paymentMethods = ref<string[]>(window.gon.paymentMethods);
const banks = ref<string[]>(window.gon.banks);
const manualPayments = ref<string[]>(window.gon.manualPayments);

const savedData = computed(() => {
  const payOutString = localStorage.getItem("payOut");

  if (errors.value && Object.values(errors.value).length > 0 && payOutString) {
    return JSON.parse(payOutString);
  }

  return {};
});
const data = reactive<DataToSave>({
  amount: savedData.value?.amount || "",
  checkInInvoiceDate: savedData.value?.checkInInvoiceDate || null,
  checkInPaidOn: savedData.value?.checkInPaidOn || null,
  comment: savedData.value?.comment || "",
  contractDescription:
    contractDescription.value || savedData.value?.contractDescription,
  files: null,
  foreignCurrency: savedData.value?.foreignCurrency || false,
  invoiceReference: savedData.value?.invoiceReference || "",
  selectedCategory: savedData.value?.selectedCategory || null,
  selectedSubCategory: savedData.value?.selectedSubCategory || null,
  selectedThirdParty: savedData.value?.selectedThirdParty || null,
  selectPaymentMethod: savedData.value?.selectPaymentMethod || "Wire transfer",
});

const years = computed(() => {
  return Array.from({ length: 6 }, (_, i) => {
    const year = new Date().getFullYear() - i;

    return {
      label: year,
      value: year,
    };
  });
});

const formatMainCategories = computed(() => {
  return mainCategories.value
    .filter((category: MainCategory) => !category.parent_category_id)
    .map((category: MainCategory) => {
      return {
        label: category.name,
        value: category.id,
      };
    });
});

const formatSubCategories = computed(() => {
  return subCategories.value
    .filter(
      (category: SubCategory) =>
        category.parent_category_id === data.selectedCategory,
    )
    .map((category: SubCategory) => {
      return {
        label: category.name,
        value: category.id,
      };
    });
});

const thirdPartiesOptions = computed(() => {
  return thirdParties.value.map((opt: ThirdParty) => {
    return {
      value: opt.id,
      label: opt.name,
    };
  });
});

const selectThirParty = computed(() => {
  const thirdParty = thirdParties.value.find(
    (tp: ThirdParty) => tp.id === data.selectedThirdParty,
  );
  const thirdPartyAssets = thirdPartieAssets.value.find(
    (tp: ThirdPartyAsset) => tp.id === data.selectedThirdParty,
  );

  return {
    ...thirdParty,
    ...thirdPartyAssets,
  };
});

const disabledButton = computed(() => {
  return !(
    Boolean(
      data.amount &&
        data.checkInInvoiceDate &&
        data.files &&
        data.invoiceReference &&
        data.selectedCategory &&
        data.selectedThirdParty &&
        selectThirParty.value.iban &&
        selectThirParty.value.account_owner &&
        selectThirParty.value.bank_details_file,
    ) && isSubmissive.value
  );
});

const errorMessageIncompleteThirdParty = computed(() => {
  const { iban, account_owner, bank_details_file, status } =
    selectThirParty.value;
  if (status != "confirmed") {
    return "This third party has not been approved by the finance team yet. Please ask them to approve it before proceeding to the pay-out.";
  }

  if (!iban || !account_owner || !bank_details_file) {
    return "The account number and the IBAN must be filled and the banking information of the third party must be updated on the Third Party profile to proceed with the pay-out.";
  }

  return "";
});

const changeCategory = () => {
  data.selectedSubCategory = null;
};

const onFileChange = (e: Event) => {
  const target = e.target as HTMLInputElement;

  const files = e instanceof DragEvent ? e.dataTransfer?.files : target.files;
  if (!files?.length) return;

  data.files = files;
};

const saveData = () => {
  localStorage.setItem("payOut", JSON.stringify(data));
};

const disableSubmit = () => {
  isSubmissive.value = false;
};
</script>

<template>
  <div class="md:flex md:items-center md:justify-between mt-8">
    <div class="flex-1 min-w-0">
      <h2
        class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
      >
        New pay out request for {{ contractDescription }}
      </h2>
    </div>
  </div>

  <div class="my-8">
    <form
      class="simple_form new_pay_out"
      id="new_pay_out"
      novalidate
      enctype="multipart/form-data"
      :action="`/contracts/${contract.id}/pay_outs`"
      accept-charset="UTF-8"
      method="post"
      @submit="disableSubmit"
    >
      <input
        type="hidden"
        name="authenticity_token"
        autocomplete="off"
        :value="token"
      />
      <div class="mb-4 hidden pay_out_contract_id">
        <input
          class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out border-green-400 hidden"
          autocomplete="off"
          type="hidden"
          name="pay_out[contract_id]"
          id="pay_out_contract_id"
          :value="contract.id"
        />
      </div>

      <div class="mb-4 select required pay_out_third_party">
        <label
          class="block select required text-sm font-medium text-gray-600"
          for="pay_out_third_party_id"
        >
          Third party <abbr title="required">*</abbr>
        </label>

        <BaseMultiselect
          v-model="data.selectedThirdParty"
          :options="thirdPartiesOptions"
          :searchable="true"
          attribute="third_party_id"
          model="pay_out"
        />
        <p
          v-if="errors && errors.third_party_id && errors.third_party_id[0]"
          class="text-red-400 text-sm mt-2"
        >
          {{ errors.third_party_id[0] }}
        </p>
      </div>

      <div class="mb-4 select optional pay_out_main_category">
        <label
          class="block select optional text-sm font-medium text-gray-600"
          for="pay_out_main_category"
        >
          Main category
          <abbr title="required">*</abbr>
        </label>

        <BaseMultiselect
          v-model="data.selectedCategory"
          :options="formatMainCategories"
          :searchable="true"
          attribute="main_category"
          model="pay_out"
          @change="changeCategory"
        />

        <p
          v-if="
            errors && errors.main_category && errors && errors.main_category[0]
          "
          class="text-red-400 text-sm mt-2"
        >
          {{ errors.main_category[0] }}
        </p>
      </div>

      <div
        id="children_categories"
        v-if="data.selectedCategory && formatSubCategories.length"
      >
        <div class="mb-4 select optional pay_out_category">
          <label
            class="block select optional text-sm font-medium text-gray-600"
            for="pay_out_category"
          >
            Sub-category
            <abbr title="required">*</abbr>
          </label>

          <BaseMultiselect
            v-model="data.selectedSubCategory"
            :options="formatSubCategories"
            :searchable="true"
            attribute="category_id"
            model="pay_out"
          />

          <p
            v-if="
              errors && errors.category_id && errors && errors.category_id[0]
            "
            class="text-red-400 text-sm mt-2"
          >
            {{ errors.category_id[0] }}
          </p>
        </div>
      </div>

      <div class="mb-4 float required pay_out_amount">
        <label
          class="block float required text-sm font-medium text-gray-600"
          for="pay_out_amount"
        >
          Amount
          <abbr title="required">*</abbr>
        </label>
        <input
          v-model="data.amount"
          class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out numeric float required"
          type="number"
          step="any"
          name="pay_out[amount]"
          id="pay_out_amount"
        />
        <p
          v-if="errors && errors.amount && errors && errors.amount[0]"
          class="text-red-400 text-sm mt-2"
        >
          {{ errors.amount[0] }}
        </p>
      </div>

      <div class="mb-4 select required pay_out_mode">
        <label
          class="block select required text-sm font-medium text-gray-600"
          for="pay_out_mode"
        >
          Mode <abbr title="required">*</abbr>
        </label>
        <select
          v-model="data.selectPaymentMethod"
          class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out select required"
          name="pay_out[mode]"
          id="pay_out_mode"
        >
          <option value=""></option>
          <option
            v-for="(label, value) in paymentMethods"
            :key="value"
            :value="value"
          >
            {{ label }}
          </option>
        </select>
        <p
          v-if="errors && errors.mode && errors && errors.mode[0]"
          class="text-red-400 text-sm mt-2"
        >
          {{ errors.mode[0] }}
        </p>
      </div>

      <div
        v-if="manualPayments.includes(data.selectPaymentMethod)"
        class="mb-4"
      >
        <div class="mb-4 select optional pay_out_bank">
          <label
            class="block select optional text-sm font-medium text-gray-600"
            for="pay_out_bank"
          >
            Bank
          </label>
          <select
            v-model="data.selectBank"
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out select optional"
            name="pay_out[bank]"
            id="pay_out_bank"
          >
            <option value=""></option>
            <option v-for="(label, value) in banks" :key="value" :value="value">
              {{ label }}
            </option>
          </select>
        </div>
        <div class="my-4 date optional pay_out_paid_on">
          <div class="my-4 date optional pay_out_paid_on">
            <legend class="text-sm font-medium text-gray-600">Paid on</legend>
            <div class="inline-flex space-x-1">
              <select
                id="pay_out_paid_on_1i"
                name="pay_out[paid_on(1i)]"
                class="flex shadow appearance-none border border-gray-300 rounded w-full p-2 bg-white focus:outline-none focus:border-blue-500 text-gray-400 leading-4 transition-colors duration-200 ease-in-out date optional"
                data-form-type="date,month,year"
              >
                <option
                  v-for="year in years"
                  :key="year.value"
                  :value="year.value"
                >
                  {{ year.label }}
                </option>
              </select>
              <select
                id="pay_out_paid_on_2i"
                name="pay_out[paid_on(2i)]"
                class="flex shadow appearance-none border border-gray-300 rounded w-full p-2 bg-white focus:outline-none focus:border-blue-500 text-gray-400 leading-4 transition-colors duration-200 ease-in-out date optional"
                data-form-type="date,month,year"
              >
                <option
                  v-for="month in months"
                  :key="month.label"
                  :value="month.value"
                >
                  {{ month.label }}
                </option>
              </select>
              <select
                id="pay_out_paid_on_3i"
                name="pay_out[paid_on(3i)]"
                class="flex shadow appearance-none border border-gray-300 rounded w-full p-2 bg-white focus:outline-none focus:border-blue-500 text-gray-400 leading-4 transition-colors duration-200 ease-in-out date optional"
                data-form-type="date,month,year,day"
              >
                <option v-for="i in 31" :key="`day-${i}`" :value="i">
                  {{ i }}
                </option>
              </select>
              <p
                v-if="errors && errors.paid_on && errors && errors.paid_on[0]"
                class="text-red-400 text-sm mt-2"
              >
                {{ errors.paid_on[0] }}
              </p>
            </div>
          </div>
        </div>
        <div class="mb-4 string optional pay_out_credit_card_holder_name">
          <label
            class="block string optional text-sm font-medium text-gray-600"
            for="pay_out_credit_card_holder_name"
          >
            Credit card holder name
          </label>
          <input
            class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string optional"
            type="text"
            name="pay_out[credit_card_holder_name]"
            id="pay_out_credit_card_holder_name"
          />
          <p
            v-if="
              errors &&
              errors.credit_card_holder_name &&
              errors &&
              errors.credit_card_holder_name[0]
            "
            class="text-red-400 text-sm mt-2"
          >
            {{ errors.credit_card_holder_name[0] }}
          </p>
        </div>
      </div>

      <div
        class="mb-4 flex items-start boolean optional pay_out_foreign_currency"
      >
        <div class="flex items-center h-5">
          <input
            name="pay_out[foreign_currency]"
            type="hidden"
            autocomplete="off"
            value="0"
          />
          <input
            v-model="data.foreignCurrency"
            class="focus:ring-2 focus:ring-primary-500:focus ring-offset-2 h-4 w-4 text-primary-600 border-gray-300 rounded boolean optional"
            type="checkbox"
            name="pay_out[foreign_currency]"
            id="pay_out_foreign_currency"
            value="1"
          />
          <p
            v-if="
              errors &&
              errors.foreign_currency &&
              errors &&
              errors.foreign_currency[0]
            "
            class="text-red-400 text-sm mt-2"
          >
            {{ errors.foreign_currency[0] }}
          </p>
        </div>
        <div class="ml-3 text-sm">
          <label
            class="block boolean optional text-sm font-medium text-gray-600"
            for="pay_out_foreign_currency"
          >
            Foreign currency
          </label>
        </div>
      </div>

      <div class="mb-4 file required pay_out_invoices">
        <label
          class="text-sm font-medium text-gray-600 block file required text-sm font-medium text-gray-600"
          for="pay_out_invoices"
        >
          Third party invoices <abbr title="required">*</abbr>
        </label>
        <input name="pay_out[invoices][]" type="hidden" autocomplete="off" />
        <input
          class="w-full text-gray-500 px-3 py-2 border rounded file required"
          multiple
          type="file"
          name="pay_out[invoices][]"
          id="pay_out_invoices"
          @change="onFileChange"
        />
        <p
          v-if="errors && errors.invoices && errors && errors.invoices[0]"
          class="text-red-400 text-sm mt-2"
        >
          {{ errors.invoices[0] }}
        </p>
      </div>

      <div class="mb-4 string required pay_out_invoice_reference">
        <label
          class="block string required text-sm font-medium text-gray-600"
          for="pay_out_invoice_reference"
        >
          Third party invoice reference <abbr title="required">*</abbr>
        </label>
        <input
          v-model="data.invoiceReference"
          class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string required"
          required
          aria-required="true"
          type="text"
          name="pay_out[invoice_reference]"
          id="pay_out_invoice_reference"
        />
        <p
          v-if="
            errors &&
            errors.invoice_reference &&
            errors &&
            errors.invoice_reference[0]
          "
          class="text-red-400 text-sm mt-2"
        >
          {{ errors.invoice_reference[0] }}
        </p>
      </div>

      <div class="mb-4 date required pay_out_invoice_date">
        <legend class="text-sm font-medium text-gray-600">
          Third party invoice date <abbr title="required">*</abbr>
        </legend>
        <div class="w-full">
          <Calendar
            :placeholder="{ checkIn: 'Select date' }"
            single-calendar
            :disabled-days-before-day-date="false"
            v-model:check-in="data.checkInInvoiceDate"
          />
          <input
            class="hidden"
            id="pay_out_invoice_date"
            name="pay_out[invoice_date]"
            :value="$day(data.checkInInvoiceDate).format('YYYY-MM-DD')"
          />
          <p
            v-if="
              errors && errors.invoice_date && errors && errors.invoice_date[0]
            "
            class="text-red-400 text-sm mt-2"
          >
            {{ errors.invoice_date[0] }}
          </p>
        </div>
      </div>

      <div class="mb-4 text optional pay_out_comment">
        <label
          class="block text optional text-sm font-medium text-gray-600"
          for="pay_out_comment"
        >
          Comment
        </label>
        <textarea
          v-model="data.comment"
          class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out text optional"
          name="pay_out[comment]"
          id="pay_out_comment"
        />
        <p
          v-if="errors && errors.comment && errors && errors.comment[0]"
          class="text-red-400 text-sm mt-2"
        >
          {{ errors.comment[0] }}
        </p>
      </div>

      <div
        id="bank_info_visualizer"
        v-if="data.selectedThirdParty && selectThirParty"
      >
        <ul class="bg-gray-100 p-4 mb-4">
          <li>
            <strong>{{ selectThirParty.name }}</strong>
          </li>
          <li>
            IBAN:
            {{ selectThirParty.iban ? selectThirParty.iban : "Not provided" }}
          </li>
          <li>
            BIC:
            {{ selectThirParty.bic ? selectThirParty.bic : "Not provided" }}
          </li>

          <li v-if="selectThirParty.bank_details_file">
            <a
              :href="selectThirParty.bank_details_file"
              class="text-blue-500 underline"
              target="_blank"
              >View file</a
            >
          </li>
        </ul>
        <p v-if="errorMessageIncompleteThirdParty" class="text-red-500">
          {{ errorMessageIncompleteThirdParty }}
        </p>
      </div>
      <div class="w-full flex justify-end">
        <button
          name="commit"
          :disabled="disabledButton"
          class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
          @click="saveData"
        >
          Create Pay out
        </button>
      </div>
    </form>
  </div>
</template>
