<script setup lang="ts">
import { computed, reactive, ref, watch } from "vue";
import axios from "axios";

import BaseMultiselect from "@/components/BaseMultiselect.vue";

import type { Contract, ThirdParty, PayIn, Invoice } from "@/types";

type dataEdit = {
  selectContractId: string;
  selectThirdPartyId: string;
  comment: string;
  selectedType: string;
  selectInvoiceId: string;
};

const token = ref<string | null>(
  document.getElementsByName("csrf-token")[0].getAttribute("content"),
);

const contracts = ref<Contract[]>(window.gon.contracts);
const payIn = ref<PayIn>(window.gon.payIn);
const thirdParties = ref<ThirdParty[]>(window.gon.thirdParties);
const flipperPayInThirdParty = ref<boolean>(window.gon.flipperPayInThirdParty);

const formatContracts = computed(() => {
  return contracts.value.map((contract: Contract) => {
    return {
      label: contract.desc,
      value: contract.id,
    };
  });
});

const formatThirdParties = computed(() => {
  return thirdParties.value.map((thirdParty: ThirdParty) => {
    return {
      label: thirdParty.name,
      value: thirdParty.id,
    };
  });
});

const data = reactive<dataEdit>({
  selectContractId: "",
  selectThirdPartyId: "",
  comment: "",
  selectedType: "contract",
  selectInvoiceId: "",
});

const selectedInvoices = ref<Invoice[]>([]);

watch(
  () => data.selectThirdPartyId,
  async (newValue) => {
    if (data.selectedType === "thirdParty") {
      try {
        const params = {
          status: "unpaid",
          thirdPartyId: newValue,
        };
        const response = await axios.get(
          `/third_parties/${newValue}/invoices`,
          { params },
        );
        selectedInvoices.value = response.data;
      } catch (error) {
        console.error("Erreur lors de la récupération des invoices :", error);
      }
    } else {
      selectedInvoices.value = [];
    }
  },
);
</script>

<template>
  <form
    :id="`edit_pay_in_${payIn.id}`"
    :action="`/pay_ins/${payIn.id}`"
    class="simple_form edit_pay_in mt-4"
    novalidate
    accept-charset="UTF-8"
    method="post"
  >
    <input type="hidden" name="_method" autocomplete="off" value="patch" />
    <input
      :value="token"
      type="hidden"
      name="authenticity_token"
      autocomplete="off"
    />

    <div class="mb-4 text optional pay_in_type">
      <label
        class="block text optional text-sm font-medium text-gray-600"
        for="pay_in_type"
      >
        Assign to
      </label>
      <select
        v-model="data.selectedType"
        class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out text optional"
        name="pay_in[type]"
        id="pay_in_type"
      >
        <option value="contract">Contract</option>
        <option v-if="flipperPayInThirdParty" value="thirdParty">
          Third Party
        </option>
      </select>
    </div>

    <div
      v-if="data.selectedType === 'contract'"
      class="mb-4 select optional pay_in_contract"
    >
      <label
        class="block select optional text-sm font-medium text-gray-600"
        for="pay_in_contract_id"
      >
        Contract
      </label>

      <BaseMultiselect
        v-model="data.selectContractId"
        :options="formatContracts"
        :searchable="true"
        attribute="contract_id"
        model="pay_in"
      />
    </div>

    <div
      v-if="data.selectedType === 'thirdParty'"
      class="mb-4 select optional pay_in_contract"
    >
      <label
        class="block select optional text-sm font-medium text-gray-600"
        for="pay_in_contract_id"
      >
        Third party name
      </label>

      <BaseMultiselect
        v-model="data.selectThirdPartyId"
        :options="formatThirdParties"
        :searchable="true"
        attribute="third_party_id"
        model="pay_in"
      />

      <label
        class="block select optional text-sm font-medium text-gray-600"
        for="pay_in_invoice_id"
      >
        Invoice
      </label>

      <select
        v-model="data.selectInvoiceId"
        class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out text optional"
        name="pay_in[invoice_id]"
        id="pay_in_invoice_id"
      >
        <option value="">Select an Invoice</option>
        <option
          v-for="invoice in selectedInvoices"
          :value="invoice.id"
          :key="invoice.id"
        >
          {{ invoice.external_id }} - {{ invoice.amount }}€
        </option>
      </select>
    </div>

    <div class="mb-4 text optional pay_in_comment">
      <label
        class="block text optional text-sm font-medium text-gray-600"
        for="pay_in_comment"
      >
        Comment
      </label>
      <textarea
        v-model="data.comment"
        class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out text optional"
        name="pay_in[comment]"
        id="pay_in_comment"
      />
    </div>

    <div class="w-full flex justify-end">
      <button
        name="commit"
        class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md btn-action-green text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
      >
        Update Pay in
      </button>
    </div>
  </form>
</template>
