<script setup lang="ts">
import { computed } from "vue";

type Props = {
  isSelected: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  isSelected: false,
});

const colors = computed(() => ({
  rect: props.isSelected ? "#033535" : "white",
  path: props.isSelected ? "white" : "#757575",
}));
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <rect width="40" height="40" rx="4" :fill="colors.rect" />
    <path
      d="M12 29.4V10.6C12 10.2686 12.2686 10 12.6 10H24.2515C24.4106 10 24.5632 10.0632 24.6757 10.1757L27.8243 13.3243C27.9368 13.4368 28 13.5894 28 13.7485V29.4C28 29.7314 27.7314 30 27.4 30H12.6C12.2686 30 12 29.7314 12 29.4Z"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 18L24 18"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 26L24 26"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 22L20 22"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24 10V13.4C24 13.7314 24.2686 14 24.6 14H28"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
