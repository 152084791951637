<script setup lang="ts">
import { computed } from "vue";

type Props = {
  isSelected: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  isSelected: false,
});

const colors = computed(() => ({
  rect: props.isSelected ? "#033535" : "white",
  path: props.isSelected ? "white" : "#757575",
}));
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <rect width="40" height="40" rx="4" :fill="colors.rect" />
    <path
      d="M15 26V25C15 22.2386 17.2386 20 20 20V20C22.7614 20 25 22.2386 25 25V26"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 26V25C9 23.3431 10.3431 22 12 22V22"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31 26V25C31 23.3431 29.6569 22 28 22V22"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20 20C21.6569 20 23 18.6569 23 17C23 15.3431 21.6569 14 20 14C18.3431 14 17 15.3431 17 17C17 18.6569 18.3431 20 20 20Z"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 22C13.1046 22 14 21.1046 14 20C14 18.8954 13.1046 18 12 18C10.8954 18 10 18.8954 10 20C10 21.1046 10.8954 22 12 22Z"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28 22C29.1046 22 30 21.1046 30 20C30 18.8954 29.1046 18 28 18C26.8954 18 26 18.8954 26 20C26 21.1046 26.8954 22 28 22Z"
      :stroke="colors.path"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
