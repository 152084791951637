<script setup lang="ts">
import BaseIcon from "@/components/BaseIcon.vue";

const emits = defineEmits(["update:count"]);

interface Props {
  attribute?: string;
  count: number;
  index?: number;
  label: string;
  model?: string;
}
const props = withDefaults(defineProps<Props>(), {
  attribute: "",
  count: 0,
  index: 0,
  label: "",
  model: "",
});

const increment = (operator: string) => {
  if (operator === "-" && props.count > 0) {
    emits("update:count", props.count - 1);
  } else if (operator === "+") {
    emits("update:count", props.count + 1);
  }
};
</script>

<template>
  <div>
    <legend class="text-sm font-medium text-gray-600 mb-2">
      {{ label }} <abbr title="required">*</abbr>
    </legend>

    <div class="flex items-center">
      <button
        type="button"
        @click="increment('-')"
        :disabled="count === 1"
        class="w-10 h-10 hover:bg-gray-100 duration-300 flex items-center justify-center rounded-full disabled:opacity-50 disabled:pointer-events-none disabled:hover:bg-white"
      >
        <base-icon name="substract" class="w-6 h-6" />
      </button>

      <div class="relative mx-2">
        <input
          class="flex shadow appearance-none border border-gray-300 rounded py-[9px] px-2 bg-white focus:outline-none focus:border-blue-500 text-gray-400 leading-4 transition-colors duration-200 ease-in-out date required pointer-events-none w-[80px]"
          type="number"
          :value="count"
        />
        <span
          class="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400"
        >
          <slot name="after" />
        </span>
      </div>

      <button
        type="button"
        @click="increment('+')"
        class="w-10 h-10 hover:bg-gray-100 duration-300 flex items-center justify-center rounded-full"
      >
        <base-icon name="add" class="w-6 h-6" />
      </button>

      <input
        v-if="index >= 0"
        type="hidden"
        :id="`${model}_schedules_attributes_${index}_${attribute}`"
        :name="`${model}[schedules_attributes][${index}][${attribute}]`"
        autocomplete="off"
        :value="count"
      />
      <input
        v-else
        type="hidden"
        :id="`${model}_schedules_attributes_${attribute}`"
        :name="`${model}[schedules_attributes][${attribute}]`"
        autocomplete="off"
        :value="count"
      />
    </div>
  </div>
</template>
