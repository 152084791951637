import { computed } from "vue";
import { default as dayjs } from "dayjs";
import { default as isBetween } from "dayjs/plugin/isBetween";
import { default as timezone } from "dayjs/plugin/timezone";
import { default as duration } from "dayjs/plugin/duration";
import { default as utc } from "dayjs/plugin/utc";

import type { UnitType, ManipulateType } from "dayjs";

dayjs.extend(isBetween);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(utc);

type Format = "DD-MM-YYYY" | "YYYY-MM-DD" | "YYYY-MM-DD-HH:mm" | "HH:MM" | "HH:mm";

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const format = (date: Date | string, format: Format): string => {
  const d = dayjs(date);

  return d.format(format);
};

export const formatWithLocalTime = (date: Date | string, format: Format): string => {
  const d = dayjs(date).tz(userTimeZone);
  return d.format(format);
};

export const formatWithUTC = (date: Date | string, format: Format): string => {
  const d = dayjs(date);

  return d.utc().format(format);
};

export const addDate = (
  date: Date | string,
  quantity: number,
  type: ManipulateType | undefined,
): Date => {
  const d = dayjs(date);

  return d.add(quantity, type).toDate();
};

export const getDateDiff = (
  time1: string | Date,
  time2: string | Date,
  type: UnitType,
): number => {
  const d1 = dayjs(time1);
  const d2 = dayjs(time2).add(1, "day");

  return Math.abs(d2.diff(d1, type));
};

export const isBetweenDate = (
  fromDate: string,
  toDate: string,
  givenDate: string | Date,
  orEqual = false,
): boolean => {
  const d1 = dayjs(fromDate);
  const d2 = dayjs(toDate);
  const d3 = dayjs(givenDate);

  if (orEqual) {
    return dayjs(d3).isBetween(d1, d2, "day", "[)");
  }

  return dayjs(d3).isBetween(d1, d2, "day");
};

export const years = computed(() => {
  const prevDate = new Date(new Date().getFullYear() - 5, 11, 1);
  const nextDate = new Date(new Date().getFullYear() + 5, 11, 1);
  const years = Array.from(
    { length: nextDate.getFullYear() - prevDate.getFullYear() + 1 },
    (_, i) => prevDate.getFullYear() + i,
  );

  return years.map((year) => {
    return {
      label: year,
      value: year,
    };
  });
});

export const months = computed(() => {
  return Array.from({ length: 12 }, (_, i) => {
    const index = i + 1;
    const month = new Date(null, index, null).toLocaleDateString("en", {
      month: "short",
    });

    return {
      label: month,
      value: index,
    };
  });
});

export const cutDates = (date: string) => {
  const [year, month, day] = date.split("-");

  return {
    year,
    month: Number(month),
    day: Number(day),
  };
};

export { dayjs };
