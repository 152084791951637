<script setup lang="ts">
import { ref } from "vue";

import BaseButton from "@/components/BaseButton.vue";
import BaseDate from "@/components/BaseDate.vue";
import BaseForm from "@/components/BaseForm.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseModal from "@/components/BaseModal.vue";
import BaseTime from "@/components/BaseTime.vue";
import BaseWysiwyg from "@/components/BaseWysiwyg.vue";

import ClientItineraryLabel from "./ClientItineraryLabel.vue";
import ClientItineraryAddServiceModalUpload from "./ClientItineraryAddServiceModalUpload.vue";
import ClientItineraryAddServiceModalUnpaidToggle from "./ClientItineraryAddServiceModalUnpaidToggle.vue";

import { useFormData } from "../composables/useFormData";

import type { Appointments, Contract } from "@/types";
import type { ClientItineraryData } from "../composables/useFormData";

type Props = {
  modelValue: boolean;
  selectedClientItinerary: Appointments | null;
};
const emits = defineEmits([
  "update:modelValue",
  "update:datetime",
  "update:name",
  "update:input",
  "update-published",
  "update-service",
]);

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  selectedClientItinerary: null,
});

const contract = ref<Contract>(window.gon.contract);
const errors = ref(null);
const showMenu = ref(false);
const contractId = ref<number>(contract.value?.id);
const isPhoneInvalid = ref(false);

const { formData, displayToggleData, setFormData } = useFormData(
  props.selectedClientItinerary,
);

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};

const togglePublished = () => {
  const newValue = 1 - displayToggleData.value.value;

  displayToggleData.value = {
    value: newValue,
    status: newValue === 1 ? "Published" : "Not published",
    text: newValue === 1 ? "Not published" : "Published",
  };
};

const updatePhoneValidity = (containsLetters: boolean) => {
  isPhoneInvalid.value = containsLetters;
};

const closeModal = () => {
  emits("update:modelValue", false);
  errors.value = null;
};

const changeDateTime = (value: string, type: keyof InitialData) => {
  formData.value[type] = value;
};
const updateErrors = (type: keyof ClientItineraryData) => {
  if (errors.value) delete errors.value[type];
};

const successRequest = () => {
  emits("update-service");
  errors.value = null;
};
const errorRequest = async (err: { [key: string]: string }) => {
  errors.value = err;
};

const updatePlace = (place: ClientItineraryData) => {
  setFormData({ ...place, at_the_propery: false });
};
</script>

<template>
  <div>
    <BaseModal
      class="h-full md:w-[500px]"
      :model-value="modelValue"
      :title="`Edit ${selectedClientItinerary?.name}`"
      has-footer
      hasHeader
      @close-modal="closeModal"
    >
      <template #content>
        <p class="text-sm pb-2 text-gray-500">
          The information below is taken from the invoice. You can customise it
          before publishing it to the app.
        </p>

        <BaseForm
          id="appointments"
          :action="`/contracts/${contractId}/client_itineraries/appointments/${selectedClientItinerary?.id}`"
          patch
          :on-success="successRequest"
          :on-error="errorRequest"
        >
          <div>
            <ClientItineraryLabel
              @click="toggleMenu"
              :label="displayToggleData.status"
            />

            <div
              v-show="showMenu"
              @click="toggleMenu"
              class="cursor-pointer mt-1 rounded-b-lg shadow-md text-center w-28"
            >
              <div class="p-3" @click="togglePublished">
                <span class="text-xs">
                  {{ displayToggleData.text }}
                </span>
                <input
                  :value="displayToggleData.value"
                  type="hidden"
                  name="appointment[published]"
                  id="appointment_published"
                />
              </div>
            </div>
          </div>

          <div class="mt-6">
            <fieldset class="font-bold mb-0 text-[14px]">
              Reservation name
              <strong>*</strong>
            </fieldset>
            <BaseInput
              :errors="
                errors?.name && Array.isArray(errors.name)
                  ? errors.name[0].error
                  : null
              "
              id="appointment_name"
              :modelValue="formData.name"
              name="appointment[name]"
              type="text"
            />
          </div>

          <div class="mt-6">
            <fieldset class="font-bold mb-0 text-[14px]">
              Local date & time <strong>*</strong>
            </fieldset>

            <div class="mt-2">
              <div class="flex w-full">
                <BaseDate
                  :as-schedules="false"
                  :date="formData.startAt"
                  :disabled="false"
                  :index="0"
                  @update:date="changeDateTime($event, 'startAt')"
                  attribute="start_at"
                  class="w-2/4"
                  customClassInput="w-full"
                  label="Start date"
                  min="2018-01-01"
                  model="appointment"
                />
                <BaseTime
                  :as-schedules="false"
                  attribute="start_at_time"
                  :index="0"
                  model="appointment"
                  :time="formData.startAtTime"
                  @update:time="changeDateTime($event, 'startAtTime')"
                  class="ml-2 w-2/4"
                />
              </div>
              <div class="flex mt-2 w-full">
                <BaseDate
                  :as-schedules="false"
                  :date="formData.endAt"
                  :disabled="false"
                  :index="1"
                  model="appointment"
                  @update:date="changeDateTime($event, 'endAt')"
                  attribute="end_at"
                  label="End date"
                  class="w-2/4"
                  min="2018-01-01"
                  customClassInput="w-full"
                />
                <BaseTime
                  :as-schedules="false"
                  attribute="end_at_time"
                  :index="1"
                  model="appointment"
                  :time="formData.endAtTime"
                  @update:time="changeDateTime($event, 'endAtTime')"
                  class="ml-2 w-2/4"
                />
              </div>
              <p
                v-if="Array.isArray(errors?.end_at) && errors?.end_at[0]?.error"
                class="text-red-400 text-sm mt-2"
              >
                Departure date is
                {{
                  (Array.isArray(errors?.end_at) && errors?.end_at[0]?.error) ||
                  ""
                }}
                arrival date
              </p>
            </div>
          </div>

          <ClientItineraryAddServiceModalUnpaidToggle
            class="mt-6"
            :form-data="formData"
            :errors="errors"
            @update-errors="updateErrors"
            @update-place="updatePlace"
            @update-phone="updatePhoneValidity"
          />

          <div class="mt-6">
            <fieldset class="font-semibold block mt-6 text-[14px]">
              Service Details
            </fieldset>

            <div class="pt-4 w-full">
              <label
                class="block text optional text-sm font-medium text-gray-600"
                for="appointment_description"
              >
                Description for the client
              </label>
              <BaseWysiwyg
                id="appointment_description"
                :name="`appointment[description]`"
                :modelValue="formData.description"
                :height="200"
              />
            </div>

            <BaseInput
              id="appointment_confirmation"
              label="Booking confirmation"
              name="appointment[confirmation]"
              type="file"
            />

            <ClientItineraryAddServiceModalUpload
              :photo-url="formData.photoUrl"
              :errors="
                errors?.image && Array.isArray(errors.image)
                  ? errors.image[0].error
                  : null
              "
            />
          </div>

          <div class="flex justify-end mt-4">
            <BaseButton
              type="button"
              color="secondary"
              text="Cancel"
              class="mr-4"
              @click="closeModal"
            />
            <BaseButton :disabled="isPhoneInvalid" type="submit" color="primary" text="Save" />
          </div>
        </BaseForm>
      </template>
    </BaseModal>
  </div>
</template>

<style scoped>
img {
  max-width: 100%;
  height: fit-content;
}
</style>
