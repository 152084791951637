<script setup lang="ts">
import { iconList } from "./icons/iconList";

type IconPath = keyof typeof iconList;
const paths = { ...iconList };

type Props = {
  fillColor?: string;
  name: IconPath;
  strokeColor?: string;
  className?: string;
};

withDefaults(defineProps<Props>(), {
  className: "w-6 h-6",
});
</script>

<template>
  <div>
    <svg
      v-if="Array.isArray(paths)"
      :fill="fillColor ? fillColor : 'none'"
      :stroke="strokeColor ? strokeColor : 'currentColor'"
      :class="className"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path v-for="p in paths" :key="p" :d="p" />
    </svg>

    <svg
      v-else
      :class="className"
      :fill="fillColor ? fillColor : 'none'"
      :stroke="strokeColor ? strokeColor : 'currentColor'"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path :d="paths[name]" />
    </svg>
  </div>
</template>
