<script setup lang="ts">
import { ref, computed } from "vue";

import BaseModal from "@/components/BaseModal.vue";
import BaseForm from "@/components/BaseForm.vue";
import BaseInput from "@/components/BaseInput.vue";

type Props = {
  contractId: number | null;
  modelValue: boolean;
  kind: string;
};

const emits = defineEmits([
  "update-modal",
  "update:input",
  "update:modelValue",
]);
const props = withDefaults(defineProps<Props>(), {
  contractId: null,
  modelValue: false,
  kind: "",
});

const errors = ref();

const capitalizedKind = computed(
  () => props.kind.charAt(0).toUpperCase() + props.kind.slice(1),
);

const changeInput = (value: string | [string, string], type: string) => {
  emits("update:input", Array.isArray(value) ? value[0] || value[1] : value);

  if (errors.value && Array.isArray(errors.value[type])) {
    errors.value[type] = errors.value[type].filter((error) => !error.error);
  }
};

const successRequest = () => {
  emits("update-modal");
  errors.value = null;
};
const errorRequest = (err: { [key: string]: string }) => {
  errors.value = err;
};
</script>

<template>
  <div>
    <BaseModal
      :model-value="props.modelValue"
      :title="`Add a new ${capitalizedKind}`"
      hasFooter
      hasHeader
      @close-modal="$emit('update:modelValue', false)"
    >
      <template #content>
        <p class="text-sm text-slate-600">
          This is the default information displayed, you can rewrite it once the
          information is ready.
        </p>
        <BaseForm
          id="client_itinerary_client_ressources"
          :action="`/contracts/${contractId}/client_itineraries/${kind}s`"
          :on-success="successRequest"
          :on-error="errorRequest"
        >
          <BaseInput
            :errors="Array.isArray(errors?.name) && errors?.name[0]?.error"
            type="text"
            :name="`${kind}[name]`"
            label="Name"
            id="client_resources_name"
            @update:model-value="changeInput($event, 'name')"
          />
          <template v-if="kind === 'link'">
            <BaseInput
              :errors="
                (Array.isArray(errors?.url) && errors?.url[0]?.error) ||
                errors?.url[1]?.error
              "
              type="text"
              :name="`${kind}[url]`"
              :label="kind"
              id="client_resources_url"
              @update:model-value="changeInput($event, 'url')"
            />
          </template>
          <template v-else-if="kind === 'document'">
            <BaseInput
              accept="application/pdf, image/jpeg, image/png"
              type="file"
              name="document[file]"
              id="document_file"
            />
          </template>
          <div class="flex justify-end mt-3">
            <button
              type="button"
              class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-primary-700 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
              @click="$emit('update:modelValue', false)"
            >
              Cancel
            </button>

            <button
              type="submit"
              class="inline-flex items-center px-3 py-2 ml-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
            >
              Save
            </button>
          </div>
        </BaseForm>
      </template>
    </BaseModal>
  </div>
</template>
