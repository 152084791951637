const getIsoLocale = (locale: string) => {
  return locale === "fr" ? "fr-FR" : "en-EN";
};

export const formatPrice = ({
  price,
  currency,
  minimumFractionDigits = 0,
  locale = "fr",
}) => {
  let options = {};

  if (currency) {
    options = {
      style: "currency",
      currency: currency,
    };
  }

  const formattedPrice = Number(price).toLocaleString(getIsoLocale(locale), {
    ...options,
    minimumFractionDigits,
  });

  return formattedPrice;
};

export const transformVAT = (vat: number) => {
  return 1 + Number(vat) / 100;
};
